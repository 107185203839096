"use strict";
/**
 * @name app.common.model.domain.ManagedAccounts
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 * Creates a defined object structure for the ManagedAccounts object type.
 */
class ManagedAccounts {
    constructor(config) {
        this.adviceUrl = null;
        this.balance = null;
        this.menuOptions = null;
        this.groupServiceRules = null;
        this.advisoryServiceProviderCode = null;
        this.managedAccountsProgramName = null;
        this.onlineAdviceProgramName = null;
        this.onlineGuidanceProgramName = null;
        this.advisoryServicesProgramName = null;
        this.eligibleForManagedAccounts = null;
        this.eligibleForAdvice = null;
        this.enrolledInFullManagedAccounts = null;
        this.enrolledInAdvice = null;
        this.enrolledInGuidance = null;
        this.privacyUrl = null;
        this.disclosureUrl = null;
        this.disclosureText = null;
        this.termsOfServiceUrl = null;
        this.retailContextEnrolledInMTR = null;

        if (config) {
            this.adviceUrl = config.adviceUrl;
            this.menuOptions = config.menuOptions;

            this.groupServiceRules = config.groupServiceRules;
            this.advisoryServiceProviderCode = config.advisoryServiceProviderCode;

            this.managedAccountsProgramName = config.managedAccountsProgramName;
            this.onlineAdviceProgramName = config.onlineAdviceProgramName;
            this.onlineGuidanceProgramName = config.onlineGuidanceProgramName;
            this.advisoryServicesProgramName = config.advisoryServicesProgramName;
            this.eligibleForManagedAccounts = config.eligibleForManagedAccounts;
            this.eligibleForAdvice = config.eligibleForAdvice;
            this.enrolledInFullManagedAccounts = config.enrolledInFullManagedAccounts;
            this.enrolledInAdvice = config.enrolledInAdvice;
            this.enrolledInGuidance = config.enrolledInGuidance;
            this.privacyUrl = config.privacyUrl;
            this.disclosureUrl = config.disclosureUrl;
            this.disclosureText = config.disclosureText;
            this.termsOfServiceUrl = config.termsOfServiceUrl;
            this.retailContextEnrolledInMTR = config.retailContextEnrolledInMTR;
        }
    }
}

export default ManagedAccounts;
