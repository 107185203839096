import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import RequestFactory from "../factories/RequestFactory";
import { executeGet, pcapServiceCall } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("initDataService");

export const getInitData = async () => {
    const url = getUrl("getInitData");

    const response = await executeGet(url, "initializeDataFault");
    return response.data;
};

export const getFunnelAttributesData = () => {
    const url = getUrl("getFunnelAttributes");
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const method = RequestFactory.HTTP_METHOD.POST;
    const faultLabel = "getFunnelAttributesFault";

    return pcapServiceCall({ method, url, contentType, faultLabel })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            logger.error(method + "getFunnelAttributes Service Error: {0}", [err.message]);
            return err;
        });
};
