import { INavigationMenu } from "../../../../types/common";

/**
 * Checks if a sub-menu exists with a given transaction code in the navigation menu.
 * To determine whether a link should be shown in the "I want to" dropdown, we make a call to get the menu
 * items that the left hand navigation uses in the rest of the site.   If the link is present in the left nav
 * outside LIAT, we also make it available in the "I want to" dropdown within LIAT
 * @param menu - The menu object to search.
 * @param txnCode - The transaction code to search for.
 * @returns Returns true if a sub-menu with the given transaction code exists, otherwise returns false.
 */

const searchTransactionCode = (menu: any, txnCode: string): boolean => {
    // Check the current menu item
    if (menu.transactionCode === txnCode) {
        return true;
    }
    // Recursively check submenus if they exist
    if (menu.subMenu) {
        return menu.subMenu.some((subMenu: any) => searchTransactionCode(subMenu, txnCode));
    }
    return false;
};

const subMenuExistsWithTransactionCode = (
    navigationMenu: INavigationMenu | null | undefined,
    txnCode: string
): boolean => {
    if (navigationMenu === null || navigationMenu === undefined) {
        return false;
    }
    // Check if navigationMenu is loaded and has menus
    if (navigationMenu?.menus) {
        // Iterate over each menu and use the helper function to search
        return navigationMenu.menus.some((menu) => searchTransactionCode(menu, txnCode));
    }
    return false;
};

export default subMenuExistsWithTransactionCode;
