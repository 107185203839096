import React, { useState, useEffect, useCallback } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import StringUtil from "core-ui/client/src/app/StringUtil";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import { NumberInput } from "../numberinput/NumberInput.jsx";
import WarningMessage from "../warningMessage/warningMessage";

import AgeSelect from "./AgeSelect";
import PortionForm from "./PortionForm";
import Tooltip from "./Tooltip";

const SpouseBody = (props) => {
    const modalFlex = "modal-well-flex";
    const spouseDelete = props.deleteSpouse ? "spouse-body-delete" : "";
    const deleteSpouseClass = props.deleteSpouse ? "deleted" : "";
    const goalValue = props.deleteSpouse ? 0 : props.spouseGoal;
    const portionText = props.labelTranslations.portionForRet;

    const incomeText = !props.isJPM()
        ? props.tooltipTranslations.currentIncomeText
        : `${props.labelTranslations.current} ${props.labelTranslations.base} ${props.labelTranslations.salary}`;
    const addSpouseText =
        !props.spouseSaved && !props.deleteSpouse ? (
            <p>{props.labelTranslations.addSpousePrompt}</p>
        ) : null;
    const spouseName = props.deleteSpouse
        ? props.labelTranslations.deletedSpouseGoalName
        : props.spouseName;
    const individualGoal = StringUtil.supplant(props.labelTranslations.individualGoal, {
        spouse: spouseName,
        period: props.period
    });

    const goalValueRound = `${Math.round(goalValue)}`;
    const spouseSalary = props.spouseSalary;
    const canSave = props.canSave || false;
    const [ageOptions, setAgeOptions] = useState({});
    const [dob, setDob] = useState(props.spouseDob);

    const changeAge = useCallback(
        (val) => {
            if (typeof val === "string") {
                val = Number(val.split(" ")[0]);
            }
            setAgeOptions((options) => ({ ...options, defaultAge: val }));
            props.handleAgeChange(val);
            props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_RET_AGE, this, {
                age: val
            });
            props.eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
                event_properties: {
                    selection: props.mtrGoalModalEvents.UPDATE_RET_AGE,
                    age: val
                }
            });
        },
        [props]
    );

    const getSpouseDefaultAge = useCallback(
        (retirementAge, useDefault, birthYear, age, min, max) => {
            let defaultAge;

            if (!useDefault) {
                if (defaultAge > max) {
                    defaultAge = max;
                } else if (defaultAge < min) {
                    defaultAge = min;
                } else {
                    defaultAge = retirementAge;
                }
            } else {
                if (birthYear < 1943) {
                    defaultAge = 65;
                } else if (birthYear < 1960) {
                    defaultAge = 66;
                } else if (birthYear > 1959) {
                    defaultAge = 67;
                }

                if (defaultAge <= age) {
                    defaultAge = age + 1;
                }

                props.handleAgeChange(defaultAge);
            }

            return defaultAge;
        },
        [props]
    );

    /**
     * Calculate the spouse/partner's birth year,
     * Default age to initially display,
     * Min, max, and current age to display.
     */
    const showAgeOptions = useCallback(
        (dob, getAge, useDefault, retAge) => {
            let defaultAge, minAge, maxAge, age, dobUTC;

            /**
             * Sanitize input value
             * IE 11 adds extra characters to value
             */
            const dobSan = dob.replace(/[^\d/]/g, "");
            const currentYear = new Date().getFullYear();

            if (dobSan) {
                const birthYear = Number(dobSan.split("/").pop());
                dobUTC = new Date(dobSan).getTime();
                age = getAge(dobUTC);

                if (age < 50) {
                    minAge = 50;
                } else if (age > 49) {
                    minAge = age + 1;
                }
                if (age < 80) {
                    maxAge = 85;
                } else if (age > 79) {
                    maxAge = age + 6;
                }

                defaultAge = getSpouseDefaultAge(
                    retAge,
                    useDefault,
                    birthYear,
                    age,
                    minAge,
                    maxAge
                );
            }

            return {
                defaultAge,
                minAge,
                maxAge,
                age,
                currentYear
            };
        },
        [getSpouseDefaultAge]
    );

    const updateDefaultAge = useCallback(
        (useDefault, props, init) => {
            const options = showAgeOptions(
                props.spouseDob,
                props.getAge,
                useDefault,
                props.desiredRetAge
            );

            if (init && useDefault && props.spouseDob) {
                changeAge(options.defaultAge);
            }

            setAgeOptions({ ...options });
        },
        [changeAge, showAgeOptions]
    );

    useEffect(() => {
        /** Use default retirement age logic if no age is present */
        const shouldUseDefault = props.desiredRetAge === 0;
        updateDefaultAge(shouldUseDefault, props, true);

        /** Highlight inputs with errors */
        props.setErrorHighlights("spouse");
    }, [props, updateDefaultAge]);

    useEffect(() => {
        /** Use default retirement age logic only if date of birth has changed */
        if (props.spouseDob !== dob) {
            updateDefaultAge(true, props, false);
            setDob(props.spouseDob);
        }
    }, [dob, props, props.spouseDob, updateDefaultAge]);

    /**
     * Passes salary value to dispatch function
     * Rejects if value is less than 0 or greater than 10 million
     * @param {string} val - Input value
     */
    const handleSpouseIncome = (val, id) => {
        const additional = 0;
        const value = Number(val);
        props.handleSpouseSalary(value);
        props.portionValidator(
            props.spousePortion,
            value,
            additional,
            props.isPercentSpouse,
            id,
            props.isSpouse
        );
    };

    /**
     * Only allow up to 20 characters
     * Prevent numbers and special characters from being entered
     */
    const validateName = (e) => {
        const val = e.target.value;

        /** Sanitize input value of all non-alphabetic characters */
        let cleanName = val.replace(/[^a-zA-Z\s]/g, "");
        const regex = /[a-zA-Z]|\s/;

        /**
         * Event.key is read as unidentified on some mobile browsers
         * If this is the case, use the keyup event to compare cleanName and e.target.value
         */
        if (e.type === "keydown") {
            if (e.key !== "Unidentified") {
                if ((!regex.test(e.key) || cleanName.length === 20) && e.key !== "Backspace") {
                    e.preventDefault();
                }
            }
        } else if (e.type === "keyup") {
            if (e.key === "Unidentified") {
                /** If cleanName does not equal e.target.value, assign spouseName prop */
                //  Reduced max length 20 to 18 due to spill over in tab display
                if (cleanName.length > 18 || val.length > 18 || e.target.value !== cleanName) {
                    cleanName = props.spouseName;
                    e.target.value = cleanName;
                } else {
                    props.handleNameChange(cleanName);
                }
            } else {
                props.handleNameChange(cleanName);
            }
        } else if (e.type === "blur") {
            const cleanNameArr = cleanName.split("");
            const spaceRegex = /\s/;
            const letterRegex = /[a-zA-Z]/;

            for (let i = 0; i < cleanNameArr.length; i++) {
                if (spaceRegex.test(cleanNameArr[i])) {
                    if (i === 0 || !letterRegex.test(cleanNameArr[i - 1])) {
                        cleanNameArr[i] = "";
                    }
                } else if (
                    spaceRegex.test(cleanNameArr[i + 1]) &&
                    !letterRegex.test(cleanNameArr[i])
                ) {
                    if (i + 1 !== cleanNameArr.length) {
                        cleanName[i + 1] = "";
                    }
                }
            }

            cleanName = cleanNameArr.join("");
            if (cleanName.lastIndexOf(" ") === cleanName.length - 1) {
                cleanName = cleanName.slice(0, cleanName.length - 1);
            }
            e.target.value = cleanName;
        } else if (e.type === "paste") {
            e.preventDefault();
        }
    };

    const handleIconClick = () => {
        const tooltip = document.getElementById("portionTooltipSpouse");
        tooltip.classList.toggle("messageHover");
    };

    const removeSpouse = () => {
        props.handleDeleteSpouse();
        props.eventBus.dispatch(props.mtrGoalModalEvents.DELETE_SPOUSE, this);
        props.eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: props.mtrGoalModalEvents.DELETE_SPOUSE
            }
        });
    };

    const handleUpdateGender = (e) => {
        const gender = e.target.value;
        props.handleGenderChange(gender);
        props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_SPOUSE_GENDER, this, {
            gender: gender
        });
        props.eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: props.mtrGoalModalEvents.UPDATE_SPOUSE_GENDER,
                gender: gender
            }
        });
    };

    const handleNameClick = () => {
        props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_SPOUSE_NAME, this, {
            name: props.spouseName
        });
        props.eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: props.mtrGoalModalEvents.UPDATE_SPOUSE_NAME,
                name: props.spouseName
            }
        });
    };

    const handleIncomeClick = () => {
        props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_SPOUSE_INCOME, this, {
            income: props.spouseSalary
        });
        props.eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: props.mtrGoalModalEvents.UPDATE_SPOUSE_INCOME,
                income: props.spouseSalary
            }
        });
    };

    const handleDobClick = () => {
        props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_SPOUSE_DOB, this, {
            dob: props.spouseDob
        });
        props.eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.CHANGE_FIELD,
            event_properties: {
                selection: props.mtrGoalModalEvents.UPDATE_SPOUSE_DOB,
                dob: props.spouseDob
            }
        });
    };

    const handleDisable = (e) => {
        if (props.isPAE && e.key !== "Tab") e.preventDefault();
    };

    return (
        <div
            className={`modal-well goal with-background spouse-flex ${spouseDelete}`}
            data-testid="spouse-body"
        >
            <div className={modalFlex}>
                <div className="individual-goal">
                    {props.spouseSaved ? (
                        <div>
                            <p>
                                {`${individualGoal}`}
                                <NumericFormat
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    fixedDecimalScale={false}
                                    allowNegative={false}
                                    displayType={"text"}
                                    prefix={"$"}
                                    value={goalValueRound}
                                    className="goal-text max-goal"
                                />
                            </p>
                        </div>
                    ) : (
                        addSpouseText
                    )}
                </div>
                <div className="form-container-flex-child">
                    {!props.deleteSpouse ? (
                        <div>
                            <div className="form-group name-container input-flex">
                                <label htmlFor="first-name" className="control-label">
                                    {props.tooltipTranslations.firstName}
                                </label>
                                <div onClick={handleNameClick}>
                                    <input
                                        type="text"
                                        id="first-name"
                                        className="form-control"
                                        defaultValue={props.spouseName}
                                        onKeyDown={validateName}
                                        onKeyUp={validateName}
                                        onPaste={validateName}
                                        onBlur={validateName}
                                        required
                                    />
                                </div>
                            </div>
                            <div
                                id="spouse-salary"
                                className="form-group salary-container input-flex"
                            >
                                <label
                                    htmlFor="current-before-tax-salary"
                                    className="control-label"
                                >
                                    {incomeText}
                                </label>
                                <div onClick={handleIncomeClick}>
                                    <NumberInput
                                        identifier="spouse-salary"
                                        spouse={true}
                                        handleSpouseIncome={handleSpouseIncome}
                                        handleError={props.handleError}
                                        type="text"
                                        value={spouseSalary}
                                        modal={true}
                                        canSave={canSave}
                                        id="current-before-tax-salary"
                                    />
                                </div>
                            </div>
                            <div className="form-group date-of-birth-container input-flex flex-container">
                                <div id="spouse-dob" className="input-date">
                                    <label htmlFor="date-of-birth" className="control-label">
                                        {props.labelTranslations.dateOfBirth}
                                    </label>
                                    <div onClick={handleDobClick}>
                                        <input
                                            onKeyDown={(e) =>
                                                props.handleDOBValidation(e, true, "spouse-dob")
                                            }
                                            onPaste={(e) =>
                                                props.handleDOBValidation(e, true, "spouse-dob")
                                            }
                                            onChange={(e) =>
                                                props.handleDOBValidation(e, true, "spouse-dob")
                                            }
                                            onBlur={props.handleDobBlur}
                                            type="text"
                                            className={`form-control ${
                                                props.isPAE ? "is-disabled" : ""
                                            }`}
                                            id="date-of-birth-spouse"
                                            name="dateOfBirth"
                                            data-testid="dateOfBirth"
                                            placeholder="mm/dd/yyyy"
                                            defaultValue={props.spouseDob}
                                            aria-disabled={props.isPAE}
                                            required
                                        />
                                        <p className="goal-modal-error">{props.dobError}</p>
                                    </div>
                                </div>
                                <div className="input-gender">
                                    <label htmlFor="gender" className="control-label">
                                        {props.tooltipTranslations.gender}
                                    </label>
                                    <div className="custom-select custom-select-medium form-control">
                                        <select
                                            className={`spouse-gender btn dropdown-toggle ${
                                                props.isPAE ? "is-disabled" : ""
                                            }`}
                                            onChange={handleUpdateGender}
                                            value={props.spouseGender}
                                            aria-disabled={props.isPAE}
                                            data-testid="gender"
                                            required
                                            onKeyDown={handleDisable}
                                        >
                                            <option value="U">
                                                {props.labelTranslations.gender.unspecified}
                                            </option>
                                            <option value="F">
                                                {props.labelTranslations.gender.female}
                                            </option>
                                            <option value="M">
                                                {props.labelTranslations.gender.male}
                                            </option>
                                            <option value="N">
                                                {props.labelTranslations.gender.nonBinary}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group desired-ret-container input-flex">
                                <label
                                    htmlFor="desired-retirement-age"
                                    className="control-label long-label"
                                >
                                    {props.tooltipTranslations.desiredRetirementAge}
                                </label>
                                <div className="custom-select retirement-age custom-select-medium form-control">
                                    <AgeSelect
                                        defaultAge={ageOptions.defaultAge}
                                        minAge={ageOptions.minAge}
                                        maxAge={ageOptions.maxAge}
                                        age={ageOptions.age}
                                        currentYear={ageOptions.currentYear}
                                        changeAge={changeAge}
                                        id="desired-retirement-age"
                                    />
                                </div>
                            </div>
                            <div
                                id="spouse-portion"
                                className="form-group portion-container input-flex"
                            >
                                <Tooltip
                                    message={StringUtil.supplant(
                                        props.labelTranslations.incomeReplacementTooltipText,
                                        { num: props.defaultIncomeGoal }
                                    )}
                                    id={"portionTooltipSpouse"}
                                />
                                <label
                                    htmlFor="income-replacement"
                                    className="control-label long-label label-left"
                                >
                                    {portionText}
                                    <span className="portion-suggestion">
                                        {" ("}
                                        {StringUtil.supplant(
                                            props.labelTranslations.incomeReplacementSubText,
                                            { num: props.defaultIncomeGoal }
                                        )}{" "}
                                        {")"}
                                        <i
                                            data-tooltip="portion"
                                            onMouseEnter={handleIconClick}
                                            onMouseLeave={handleIconClick}
                                            className="em-question-mark"
                                        />
                                    </span>
                                </label>
                                <PortionForm
                                    identifier="spouse-portion"
                                    handlePortion={props.handleSpousePortion}
                                    handleError={props.handleError}
                                    portionValidator={props.portionValidator}
                                    salary={props.spouseSalary}
                                    isSpouse={true}
                                    additional={0}
                                    portion={props.spousePortion}
                                    canSave={canSave}
                                    isPercent={props.isPercentSpouse}
                                    eventBus={props.eventBus}
                                    mtrGoalModalEvents={props.mtrGoalModalEvents}
                                />
                            </div>
                        </div>
                    ) : (
                        <WarningMessage
                            message={props.labelTranslations.delSpouseWarning}
                            classNames={["spouse-warning"]}
                        />
                    )}
                </div>
            </div>
            {props.spouseSaved ? (
                <div className="delete-spouse">
                    <div className={`delete-label ${deleteSpouseClass}`}>
                        <label>
                            <input
                                onChange={removeSpouse}
                                checked={props.deleteSpouse}
                                type="checkbox"
                                value=""
                            />
                            {props.labelTranslations.deleteSpouse}
                        </label>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

SpouseBody.propTypes = {
    handleNameChange: PropTypes.func.isRequired,
    handleSpouseSalary: PropTypes.func.isRequired,
    handleGenderChange: PropTypes.func.isRequired,
    handleAgeChange: PropTypes.func.isRequired,
    handleSpousePortion: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    handleDOBValidation: PropTypes.func.isRequired,
    portionValidator: PropTypes.func.isRequired,
    period: PropTypes.string.isRequired,
    spouseName: PropTypes.string.isRequired,
    spouseSaved: PropTypes.bool.isRequired,
    spouseGender: PropTypes.string.isRequired,
    spouseSalary: PropTypes.number.isRequired,
    spouseDob: PropTypes.string.isRequired,
    spouseAge: PropTypes.number.isRequired,
    desiredRetAge: PropTypes.number,
    spousePortion: PropTypes.number.isRequired,
    spouseGoal: PropTypes.number.isRequired,
    isSpouse: PropTypes.bool.isRequired,
    canSave: PropTypes.bool.isRequired,
    isPercentSpouse: PropTypes.bool.isRequired,
    getAge: PropTypes.func.isRequired,
    tooltipTranslations: PropTypes.object.isRequired,
    labelTranslations: PropTypes.object.isRequired,
    dobError: PropTypes.string.isRequired,
    getSpouseDefaultAge: PropTypes.func,
    removeLeadingZeroFromDate: PropTypes.func,
    handleDobBlur: PropTypes.func,
    isJPM: PropTypes.func,
    deleteSpouse: PropTypes.bool,
    eventBus: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    mtrGoalModalEvents: PropTypes.object,
    setErrorHighlights: PropTypes.func,
    handleDeleteSpouse: PropTypes.func,
    defaultIncomeGoal: PropTypes.number,
    isPAE: PropTypes.bool
};

export default SpouseBody;
