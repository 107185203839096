import ReferenceData from "../constants/ReferenceData";
import ManagedAccounts from "../models/ManagedAccounts";

const ManagedAccountsFactory = {
    create: (data) => {
        if (data) {
            const config = {
                code: data.advisoryServiceProviderCode,
                adviceUrl: ReferenceData.URL_ADVICE,
                menuOptions: data.menuOptions,
                groupServiceRules: data.groupServiceRules,
                advisoryServiceProviderCode: data.advisoryServiceProviderCode,
                managedAccountsProgramName: data.managedAccountsProgramName,
                onlineAdviceProgramName: data.onlineAdviceProgramName,
                onlineGuidanceProgramName: data.onlineGuidanceProgramName,
                advisoryServicesProgramName: data.advisoryServicesProgramName,
                eligibleForManagedAccounts: data.eligibleForManagedAccounts,
                eligibleForAdvice: data.eligibleForAdvice,
                enrolledInFullManagedAccounts: data.enrolledInFullManagedAccounts,
                enrolledInAdvice: data.enrolledInAdvice,
                enrolledInGuidance: data.enrolledInGuidance,
                privacyUrl: data.privacyUrl,
                disclosureUrl: data.disclosureUrl,
                disclosureText: data.disclosureText,
                termsOfServiceUrl: data.termsOfServiceUrl,
                retailContextEnrolledInMTR: data.retailContextEnrolledInMTR
            };
            return new ManagedAccounts(config);
        } else {
            return null;
        }
    }
};

export default ManagedAccountsFactory;
