import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";

const PreRetireeError = ({ toggleModal }) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("preRetiree");

    const openConsiderationsModal = () => {
        dispatch(dispatchEventBus(EventBusEvents.PRERETIREE.PLAN_TO_AGE_MODAL_TEXT_CLICK, true));
        toggleModal();
    };

    return (
        <div className="pre-retiree-error">
            <p>{translations.errorMessage.main}</p>
            <span>
                {translations.errorMessage.link1}
                <a className="error-link" onClick={openConsiderationsModal}>
                    {translations.errorMessage.link2}
                </a>
                {translations.errorMessage.link3}
            </span>
        </div>
    );
};
PreRetireeError.propTypes = {
    toggleModal: PropTypes.func
};

export default PreRetireeError;
