import {
    formatTimingPrefix,
    DiagnosticsConstants,
    determineLoginFlow,
    LoginFlowEvents
} from "./common";
import { getLiatAppStartTime } from "./getEvents";
import { sendMetricEventMFA, sendMetricEvent } from "./sendEvents";

const measurementName = "Paycheck";

const timingPrefix = formatTimingPrefix();

const sentryPaycheckRenderMetrics = () => {
    const renderPaycheck = new Date().getTime();

    const renderPaycheckStartTimeExists = sessionStorage.getItem(
        `${timingPrefix}${DiagnosticsConstants.RENDER_PAYCHECK}`
    );

    /**
     * With this logic we will check to see if we have set the paycheck metrics in the current session.
     * If we have not then proceed with getting and setting the data.
     */
    if (!renderPaycheckStartTimeExists) {
        const liatAppStartTime = getLiatAppStartTime();

        const renderPaycheckDelta = renderPaycheck - Number(liatAppStartTime);

        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_PAYCHECK,
            String(renderPaycheck)
        );
        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_PAYCHECK + "Delta",
            String(renderPaycheckDelta)
        );

        const { loginLastHash, flowName, startHash, endTime } = determineLoginFlow();
        // If true, we are in the MFA Sign in login flow
        if (flowName === LoginFlowEvents.MFA_LOGIN) {
            // Measure from when the user clicked mfa sign in to when paycheck renders
            const timeToLiatFromVerify = renderPaycheck - endTime;

            sendMetricEventMFA({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderPaycheck,
                name: DiagnosticsConstants.MFA.TIME_TO_LIAT,
                timeToLoad: timeToLiatFromVerify
            });
            // If true, we are in the DF login flow
        } else if (flowName === LoginFlowEvents.DF_LOGIN) {
            // Measure from when the user clicked login to when they can see the LIS score
            const timeToLiatFromClick = renderPaycheck - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderPaycheck,
                name: DiagnosticsConstants.DF.TIME_TO_LIAT,
                timeToLoad: timeToLiatFromClick
            });
            // If true, we are in the SSO login flow
        } else if (flowName === LoginFlowEvents.SSO_LOGIN) {
            const timeToStartLiatFromSSO = renderPaycheck - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderPaycheck,
                name: DiagnosticsConstants.SSO.TIME_TO_LIAT,
                timeToLoad: timeToStartLiatFromSSO
            });
        } // Collect the registration flow data points
        else if (loginLastHash && DiagnosticsConstants[loginLastHash]) {
            sendMetricEvent({
                name: `${DiagnosticsConstants.TIME_TO_LIAT}_${DiagnosticsConstants[loginLastHash]}`,
                timeToLoad: renderPaycheckDelta,
                measurementName,
                startTime: renderPaycheck,
                endTime: Number(liatAppStartTime),
                loginLastHash
            });
        }

        // We will keep this as a common metric independent of any login flow
        sendMetricEvent({
            name: DiagnosticsConstants.TIME_TO_LIAT,
            timeToLoad: renderPaycheckDelta,
            measurementName,
            startTime: renderPaycheck,
            endTime: Number(liatAppStartTime),
            loginLastHash
        });
    }
};

export default sentryPaycheckRenderMetrics;
