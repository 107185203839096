/**
 * @docType function
 * @ngDoc util
 * @name app.util.logger:Logger
 *
 */

import BrowserDetect from "core-ui/client/src/app/BrowserDetect";
import URLUtil from "core-ui/client/src/app/URLUtil";

const loggerLevel = {
    /**
     * @property {String} LEVEL_LOG A constant. Indicates the "all" logging level.
     * @static
     */
    LEVEL_ALL: 0, // Its not required here since we are logging globalLevel >= localLoglevel

    /**
     * @property {String} LEVEL_LOG A constant. Indicates the "log" logging level.
     * @static
     */
    LEVEL_LOG: 2,

    /**
     * @property {String} LEVEL_DEBUG A constant. Indicates the "debug" logging level.
     * @static
     */
    LEVEL_DEBUG: 4,

    /**
     * @property {String} LEVEL_INFO A constant. Indicates the "info" logging level.
     * @static
     */
    LEVEL_INFO: 6,

    /**
     * @property {String} LEVEL_WARN A constant. Indicates the "warn" logging level.
     * @static
     */
    LEVEL_WARN: 8,

    /**
     * @property {String} LEVEL_ERROR A constant. Indicates the "error" logging level.
     * @static
     */
    LEVEL_ERROR: 12
};

class Logger {
    constructor(contextInput) {
        this.logger = {
            context: contextInput,
            isEnabled: true,
            parentContext: "[JS]",
            filters: {},
            level: loggerLevel.LEVEL_ERROR
        };

        // --------------------------------------------------------------------
        // Debug support and Production logging
        // --------------------------------------------------------------------

        if (URLUtil.getURLParam("debug") === "true") {
            this.logger.level = loggerLevel.LEVEL_ALL;
        }
    }

    log = (...msg) => {
        this.internalLog(loggerLevel.LEVEL_LOG, msg);
    };

    info = (...msg) => {
        this.internalLog(loggerLevel.LEVEL_INFO, msg);
    };

    debug = (...msg) => {
        this.internalLog(loggerLevel.LEVEL_DEBUG, msg);
    };

    warn(...msg) {
        this.internalLog(loggerLevel.LEVEL_WARN, msg);
    }

    error(...msg) {
        this.internalLog(loggerLevel.LEVEL_ERROR, msg);
    }

    internalLog = (level, ...args) => {
        // do not log anything if logging is not enabled
        if (!this.logger.isEnabled) {
            return;
        }

        // determine if the level requested is greater or equal to the current log level
        if (level < this.logger.level) {
            return;
        }

        // get the console print friendly message
        const inputMsg = typeof args === "string" ? args : args[0];
        let msg = this.getPrintFriendlyLogStatement(level, inputMsg);

        // filter out the acceptable logging statements so it only shows contexts that exist in the filter list
        if (!this.isFilterEnabled(msg)) {
            return;
        }

        // determine if the message has parametrized tokens
        if (args && args[0].length >= 2) {
            msg = this.replaceTokens(args[0], msg);
        }

        // determine the log level and log to the console accordingly
        switch (level) {
            case loggerLevel.LEVEL_INFO:
                this.logToConsole("info", msg);
                break;
            case loggerLevel.LEVEL_WARN:
                this.logToConsole("warn", msg);
                break;
            case loggerLevel.LEVEL_ERROR:
                this.logToConsole("error", msg);
                break;
            default:
                this.logToConsole("debug", msg);
                break;
        }
    };

    getPrintFriendlyLogStatement = (level, msg) => {
        msg = msg === "undefined" || msg === null ? "" : msg;
        const parentContext =
            this.logger.parentContext !== "" ? this.logger.parentContext + " " : "";
        return (
            parentContext +
            this.getTimestamp() +
            " " +
            this.getPrintableLogMessage(level) +
            " " +
            this.logger.context +
            " - " +
            msg
        );
    };

    /**
     * Creates a print-friendly timestamp in the form of 16:11:45:956 for logging purposes.
     *
     * @return {String} A timestamp in the form of 16:11:45:956.
     */
    getTimestamp = function () {
        const date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let milliseconds = date.getMilliseconds();

        if (hours < 10) {
            hours = "0" + hours;
        }

        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        if (milliseconds < 10) {
            milliseconds = "00" + milliseconds;
        } else if (milliseconds < 100) {
            milliseconds = "0" + milliseconds;
        }

        return hours + ":" + minutes + ":" + seconds + ":" + milliseconds;
    };

    /**
     * A simple method that returns the log level as a printable string.
     *
     * @param {Number} level The log level.
     * @returns {String} The printable log level message.
     */
    getPrintableLogMessage = (level) => {
        switch (level) {
            case loggerLevel.LEVEL_DEBUG:
                return "[DEBUG]";
            case loggerLevel.LEVEL_INFO:
                return "[INFO] ";
            case loggerLevel.LEVEL_WARN:
                return "[WARN] ";
            case loggerLevel.LEVEL_ERROR:
                return "[ERROR]";
            default:
                return "[LOG]  ";
        }
    };

    /**
     * Determines if the log message contains a context that matches one of the acceptable log filters.
     *
     * @param {String} msg The entire log message to search for a matching filter.
     * @returns {Boolean} A flag indicating if the message contains an acceptable log filter.
     */
    isFilterEnabled = (msg) => {
        const filterRef = this.logger.filters;
        if (Object.keys(filterRef).length === 0) {
            filterRef["*"] = "*";
        }

        if (Object.prototype.hasOwnProperty.call(filterRef, "*")) {
            return true;
        }

        for (let filter in filterRef) {
            if (Object.prototype.hasOwnProperty.call(filterRef, filter)) {
                const lastChar = filter.charAt(filter.length - 1);
                if (lastChar === "*") {
                    filter = filter.slice(0, -1);
                }
                if (msg.indexOf(filter) !== -1) {
                    return true;
                }
            }
        }

        return false;
    };

    /**
     * Determines if the token value object (second parameter in the original log function) is an array or object
     * and attempts to perform token substitution based on the valuers in the array or JSON object. Tokens in the
     * message either looks like {0}, {1}, ... {n} for array substitution or {user.username}, {firstName} for
     * JSON substitution.
     *
     * @return {String} The final message with tokens replaced with values.
     */
    replaceTokens = (args, msg) => {
        const tokenValues = args[1];

        if (tokenValues) {
            let retVal = this.supplant(msg, tokenValues);
            const strRetVal = "," + tokenValues.toString();
            retVal = retVal.replace(strRetVal, "");
            return retVal;
        }

        // do substitution of tokens with the passed in array of values
        if (this.isArray(tokenValues)) {
            const len = tokenValues.length;
            for (let i = 0; i < len; i++) {
                msg = msg.replace(new RegExp("\\{" + i + "\\}", "g"), tokenValues[i]);
            }

            // do substitution of tokens using the passed in JSON object
        } else if (typeof tokenValues !== typeof undefined) {
            const tokens = msg.match(/\{(.*?)\}/g);
            if (this.isArray(tokens)) {
                let value = "";
                const tokensLen = tokens ? tokens.length : 0;

                // nested function to dig down into a JSON object and grab the actual value of the nested property
                // allows for the retrieval of a json object like foo.bar.count.
                const getNestedValue = (tokenValues, properties) => {
                    let property = "";
                    const len = properties.length;
                    for (let j = 0; j < len; j++) {
                        property = properties[j];
                        tokenValues = tokenValues[property];
                    }
                    return tokenValues;
                };

                // loop through all the tokens and repalace them with values from the JSON object
                for (let j = 0; j < tokensLen; j++) {
                    // replace the brackets for "{user.username}" becomes "user.username"
                    const token = tokens ? tokens[j].replace(/\{(.*?)\}/g, "$1") : "";
                    // create an array of all the tokens
                    const properties = token.split(".");

                    try {
                        value = getNestedValue(tokenValues, properties);
                    } catch (e) {
                        value = "";
                    }

                    msg = tokens ? msg.replace(new RegExp(tokens[j]), value) : "";
                }
            }
        }

        return msg;
    };

    /**
     * supplant() method from Crockfords `Remedial Javascript`
     * @param {object} template The template
     * @param {object} values The values
     * @param {object} [pattern] The pattern used to replace tokens.
     * @returns {object} promise
     * type {directive.replace|*|replace|string|ng.$location.replace}
     */
    supplant = (template, values) => {
        if (!template) {
            throw new Error("Undefined template provided");
        }

        const pattern = /\{([^{}]*)\}/g;

        return template.replace(pattern, (a, b) => {
            const p = b.split(".");
            let r = values;

            try {
                // In IE8 for(var s in p) iterative twice even p.length is 1 which leads messup with data, so that I
                // have followed with general for loop which supports all browsers.
                for (let s = 0; s < p.length; s++) {
                    r = r[p[s]];
                }
            } catch (e) {
                r = a;
            }

            return typeof r === "string" || typeof r === "number" ? r : typeof r;
        });
    };

    /**
     * Internal method that determines if the console logging method is available -- if so, print to the console.
     *
     * @param {Function} method The request console logging method.
     * @param {String} msg The message to log to the console.
     *
     * @private
     */
    logToConsole = (method, msg) => {
        try {
            let color = "black";
            switch (method) {
                case "warn":
                    color = "brown";
                    break;
                case "error":
                    color = "red";
                    break;
                default:
                    color = "black";
                    break;
            }
            const isChrome = BrowserDetect.browser === "Chrome";

            if (this.isConsoleMethodAvailable(method)) {
                /* eslint-disable no-console */
                if ((method === "warn" || method === "error") && isChrome) {
                    console.log("%c" + msg, "color:" + color + ";");
                } else {
                    console.log(msg);
                }
            }
        } catch (e) {
            /* eslint-disable no-console */
            console.warn(e);
        }
    };

    /**
     * Determines if the requested console logging method is available, since it is not with IE.
     * Crashes in IE8 without developer tools open
     *
     * @param {Function} method The request console logging method.
     * @returns {Boolean} Indicates if the console logging method is available.
     *
     * @private
     */
    isConsoleMethodAvailable = () => {
        // eslint-disable-next-line no-console
        return window.console && console.log && this.isFunction(console.log);
    };

    isFunction = function (fn) {
        return typeof fn === typeof Function;
    };
}

export default Logger;
