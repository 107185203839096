import React, { memo, useMemo, useState } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader, SliderWrapper } from "gw-shared-components";
import { noop, omit } from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { setAttainedAge } from "../../actions/healthcare";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import { ACCESSIBILITY } from "../../constants/AccessibilityPaycheck";
import ColorConstants from "../../constants/ColorConstants";
import EventBusEvents from "../../events/eventBusEvents";
import { useDispatch, useSelector } from "../../hooks/use-redux-actions";
import { useIsPrivileged } from "../../hooks/useEmulatorType";
import {
    getYearlyAmount,
    selectChartData,
    selectChartPercentColor,
    selectCurrentHealthConditions,
    selectCurrentProjection,
    selectCurrentRetirementState,
    selectMedicalExpensesPercentage,
    selectRemainingIncome,
    selectTotalHealthCareCost
} from "../../selectors/healthCareSelectors";
import { isTypeParticipant } from "../../selectors/participantSelectors";
import { reselectTermPeriod } from "../../selectors/reselectSelectors";
import PaycheckHeader from "../../shared/PaycheckHeader";
import HiddenA11yWrapper from "../accessibility/HiddenA11yWrapper";
import CurrencyFormatter from "../currencyFormatter/CurrencyFormatter";
import PaycheckDetails from "../paycheck/PaycheckDetails";

import HealthcareCostsChart from "./HealthcareCostsChart";
import HealthcareCostsDetails from "./HealthcareCostsDetails";
import { getChartPopoverMessage, getProjectedHsaCoverage } from "./helpers";
import Message from "./Message";
import PersonalizedModal from "./PersonalizedModal";
import useHealthcareData from "./use-healthcare-data";

const LOADER_PROPS = {
    fullscreen: false,
    messages: null,
    showMessages: false,
    useSvg: false
};

const HealthcareCosts = () => {
    const dispatch = useDispatch();

    const healthCareCosts = selectTranslations("healthCareCosts");
    const retirementIncome = selectTranslations("retirementIncome");

    const { attainedAge, healthCareDataLoaded, showLoader, pendingChanges, showHealthcareView } =
        useHealthcareData();

    const [showModal, setShowModal] = useState(false);
    const [showHealthcareViewDetails, setShowHealthcareViewDetails] = useState(false);

    const projectedIncome = useSelector((state) => state.projectedIncome.total || 0);
    const participant = useSelector((state) => state.participant);
    const retirementAge = participant.retirementAge;
    const isPrivileged = useIsPrivileged();

    const {
        chartLegends,
        healthSurvey,
        projections: healthViewProjections,
        warnThreshold,
        yieldThreshold
    } = useSelector((state) => state.healthCare.healthViewData);

    // get health conditions
    const pendingHealthConditions = pendingChanges.healthConditions;
    const healthConditions = selectCurrentHealthConditions(
        healthSurvey.pptHealthConditions,
        pendingHealthConditions
    );

    //get projections
    const currentProjection = selectCurrentProjection(attainedAge, healthViewProjections);

    const term = useSelector((state) => state.applicationSettings.projectionSettings.term);
    const termLabel = useSelector(reselectTermPeriod);
    const totalHealthCareCost = selectTotalHealthCareCost(currentProjection);

    const yearlyAmount = getYearlyAmount(totalHealthCareCost, term);
    const totalHealthCareExpenses = totalHealthCareCost ? yearlyAmount : 0;

    const allRetirementStates = healthSurvey.states;
    const selectedRetirementState = healthSurvey.selectedRetirementState;

    const selectedRetirementStateCode = pendingChanges.selectedRetirementStateCode;
    const retirementState = selectCurrentRetirementState(
        allRetirementStates,
        selectedRetirementStateCode,
        selectedRetirementState
    );

    const remainingIncome = selectRemainingIncome(totalHealthCareExpenses, projectedIncome);
    const canShowHealthDetails = isTypeParticipant(participant);
    const incomeParts = useSelector((state) => state.projectedIncome.incomeParts);

    const projectedHsaCoverage = useMemo(() => getProjectedHsaCoverage(incomeParts), [incomeParts]);
    const retirementIncomeSSMessage = selectTranslations("retirementIncome").ssMessage;
    const updatessMessage = useMemo(() => {
        return retirementIncomeSSMessage.replace(
            "{{totalHealthCareExpenses}}",
            totalHealthCareExpenses.toLocaleString()
        );
    }, [totalHealthCareExpenses, retirementIncomeSSMessage]);

    const toggleModal = () => {
        setShowModal(!showModal);
        dispatch(dispatchEventBus(EventBusEvents.HEALTHCARE_COSTS.PERSONALIZE_CLICK, this));

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.HEALTHCARE_COSTS.PERSONALIZE_CLICK
        });
    };

    const medicalExpensesPercentage = selectMedicalExpensesPercentage(
        projectedIncome,
        totalHealthCareExpenses
    );

    const chartPercentColor = selectChartPercentColor(medicalExpensesPercentage, {
        warnThreshold,
        yieldThreshold
    });
    const chartData = selectChartData(
        currentProjection,
        chartLegends,
        term,
        remainingIncome,
        chartPercentColor
    );

    const popoverMessage = useMemo(() => {
        return getChartPopoverMessage({
            totalHealthCareExpenses,
            projectedIncome,
            pieChartTranslations: healthCareCosts.toolTips.pieChart
        });
    }, [totalHealthCareExpenses, projectedIncome, healthCareCosts.toolTips.pieChart]);

    const chartPopover = <Popover id="popover-trigger-hover-focus">{popoverMessage}</Popover>;

    let detailsMessage = "";

    detailsMessage = showHealthcareViewDetails
        ? healthCareCosts.integratedDetails.hide
        : healthCareCosts.integratedDetails.view;

    const handleViewDetails = () => {
        dispatch(dispatchEventBus(EventBusEvents.HEALTHCARE_COSTS.VIEW_DETAILS_CLICK, this));

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.HEALTHCARE_COSTS.VIEW_DETAILS_CLICK
        });

        if (canShowHealthDetails || isPrivileged) {
            setShowHealthcareViewDetails(!showHealthcareViewDetails);
        }
    };

    const handleEventBus = (type: string, target, data) => {
        const payload = omit(data, ["action"]);
        dispatch(dispatchEventBus(type, target, payload));
    };

    const sliderBeingMoved = false;

    const handleSliderMoving = () => noop;

    const healthCareProjectionsAge = attainedAge < retirementAge ? retirementAge : attainedAge;
    const customMsg = selectTranslations("retirementIncome").srHeaderUpdatedMessage.replace(
        "{title}",
        healthCareCosts.yourMonthlyExpense.rivdHeadline
    );

    const getPercentColor = () => {
        if (yieldThreshold === null || warnThreshold === null) {
            return ColorConstants.HEALTHCARE_COSTS.WARN_STYLE;
        }

        let color;
        if (medicalExpensesPercentage < yieldThreshold) {
            color = ColorConstants.HEALTHCARE_COSTS.HEALTHY_STYLE;
        } else if (
            medicalExpensesPercentage >= yieldThreshold &&
            medicalExpensesPercentage < warnThreshold
        ) {
            color = ColorConstants.HEALTHCARE_COSTS.YIELD_STYLE;
        } else {
            color = ColorConstants.HEALTHCARE_COSTS.WARN_STYLE;
        }

        return color;
    };

    const chartSummaryViewData = [
        [
            ColorConstants.HEALTHCARE_COSTS.ESTIMATED_HEALTHCARE_COSTS,
            totalHealthCareExpenses - projectedHsaCoverage <= 0
                ? totalHealthCareExpenses
                : totalHealthCareExpenses - projectedHsaCoverage
        ],
        [
            ColorConstants.HEALTHCARE_COSTS.HSA_PROJECTED_TO_COVER,
            totalHealthCareExpenses - projectedHsaCoverage <= 0 ? 0 : projectedHsaCoverage
        ],
        [getPercentColor(), remainingIncome]
    ].map(([color, y]) => ({ color, y }));

    if (!showHealthcareView) {
        return null;
    }

    const paycheckTitle = healthCareCosts.yourMonthlyExpense.rivdHeadline as string;

    let detailsMessageClass = "projected-health-care-view-details";
    if (!canShowHealthDetails && !isPrivileged) {
        detailsMessageClass += " disabled";
    }

    return (
        <div
            id="integrated-healthcare-container"
            className="rivd-healthcare-costs-container"
            data-testid="healthcare-costs"
            role="tabpanel"
        >
            {(showLoader || !healthCareDataLoaded) && <Loader {...LOADER_PROPS} />}

            <div id="integrated-healthcare" className="healthcare-paycheck">
                <PaycheckHeader title={paycheckTitle} />
                <div className="sr-only" role="alert">
                    {customMsg}
                </div>
                <div className="rivd-projected-care-chart">
                    <div className="healthcare-chart-display">
                        <div className="rivd-projected-health-care-costs">
                            <OverlayTrigger overlay={chartPopover} placement="top">
                                <div id="projected-health-care-costs-chart">
                                    <HealthcareCostsChart
                                        showHealthcareViewDetails={showHealthcareViewDetails}
                                        chartDetailedViewData={chartData}
                                        chartSummaryViewData={chartSummaryViewData}
                                    />

                                    {remainingIncome > 0 ? (
                                        <div id="projected-health-care-costs-income-label">
                                            <CurrencyFormatter amount={remainingIncome} />
                                        </div>
                                    ) : null}
                                    <div className="svg-circle-shadow" />
                                    <HiddenA11yWrapper id={ACCESSIBILITY.HEALTHCARECOSTS_CHART_ID}>
                                        ${Math.round(remainingIncome)} remaining.
                                    </HiddenA11yWrapper>
                                </div>
                            </OverlayTrigger>
                            <div
                                tabIndex={1}
                                className={detailsMessageClass}
                                onClick={handleViewDetails}
                                onKeyDown={(event) => {
                                    const keyboardEvent = {
                                        event,
                                        keys: keyboardInteractions.button,
                                        output: handleViewDetails
                                    } as unknown as Event;
                                    handleKeyboardDownEvent(keyboardEvent);
                                }}
                                role="button"
                            >
                                {detailsMessage}
                            </div>
                        </div>
                    </div>
                    <div className="healthcare-slider-messaging">
                        <Message
                            toggleModal={toggleModal}
                            totalHealthCareExpenses={totalHealthCareExpenses}
                            projectedIncome={projectedIncome}
                            healthConditions={healthConditions}
                            termLabel={termLabel}
                            idForA11y={ACCESSIBILITY.HEALTHCARECOSTS_TEXT_ID}
                        />
                        <div className="rivd-projected-health-care-table">
                            {/* <!-- to remake the soft minimum, change to min-value={this.props.minRetirementAge}--> */}
                            {retirementAge && (
                                <SliderWrapper
                                    sliderId="attainedAgeSlider"
                                    identifier="attained-age-slider"
                                    min={retirementAge}
                                    minEditableValue={retirementAge}
                                    value={healthCareProjectionsAge}
                                    sliderMoving={handleSliderMoving}
                                    sliderBeingMoved={sliderBeingMoved}
                                    sliderChange={(id, val) => dispatch(setAttainedAge(val))}
                                    resetValueWithLimits={true}
                                    increment={1}
                                    showSliderTitle={false}
                                    dispatchEventBus={handleEventBus}
                                    showBottomText={false}
                                    valueUnit="years"
                                    ssMessage={updatessMessage}
                                    sliderTranslations={retirementIncome}
                                    ariaDescribedBy={`${ACCESSIBILITY.HEALTHCARECOSTS_CHART_ID} ${ACCESSIBILITY.HEALTHCARECOSTS_TEXT_ID}`}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {healthViewProjections.length ? (
                    <div className="paycheck-details">
                        <HealthcareCostsDetails
                            showHealthcareViewDetails={showHealthcareViewDetails}
                            term={termLabel}
                        />
                    </div>
                ) : null}
                <div className="paycheck-details-xs visible-xs-block">
                    <PaycheckDetails isMobileView={true} />
                </div>
                <hr className="line" />
            </div>

            {showModal ? (
                <PersonalizedModal
                    isPrivileged={isPrivileged}
                    showModal={showModal}
                    toggleModal={toggleModal}
                    conditions={healthConditions}
                    allRetirementStates={allRetirementStates}
                    retirementStateIn={retirementState.stateName}
                    healthCareProjectionsAge={healthCareProjectionsAge}
                />
            ) : null}
        </div>
    );
};

export default memo(HealthcareCosts);
