const roots = {
    ADVISORY_SERVICES: "advisoryServices",
    FAQ: "faq",
    HDIC: "howDoICompare",
    HEALTHCARE_COSTS: "HealthviewEvent",
    I_WANT_TO: "iWantTo",
    LIAT: "liat",
    PAYCHECK: "PaycheckEvent",
    PRERETIREE: "PreRetiree",
    TOUR: "tour",
    INTEGRATED: "integrated",
    OTHER_ASSETS: "otherAssets",
    SOCIAL_SECURITY: "socialSecurity",
    WITHDRAWAL: "withdrawal_planner",
    REFERRAL: "referral",
    DEMIN: "demin_modal",
    QUICK_VIEW: "quick_view"
};

const EventBusEvents = {
    PAYCHECK: {
        EST_INC_CLICK: roots.PAYCHECK + ".estimated_monthly_income_click_event",
        VIEW_DETAILS_CLICK: roots.PAYCHECK + ".view_details_click_event",
        TIMETAP_LINK_CLICKED: roots.PAYCHECK + ".timetap_link_clicked"
    },
    HEALTHCARE_COSTS: {
        PERSONALIZE_CLICK: roots.HEALTHCARE_COSTS + ".personalize_click_event",
        VIEW_DETAILS_CLICK: roots.HEALTHCARE_COSTS + ".view_details_click_event"
    },
    HDIC: {
        VIEW_DETAILS_CLICK: roots.HDIC + ".ToggleViewDetailsEvent",
        GROUP_CHANGE: roots.HDIC + ".group_changed_event"
    },
    ADVISORY_SERVICES: {
        BUTTON_CLICKED: roots.ADVISORY_SERVICES + ".buttonClicked",
        FOOTER_LINK_CLICKED: roots.ADVISORY_SERVICES + ".footerLinkClicked"
    },
    LIAT: {
        PAGE_VIEW: roots.LIAT + ".pageView",
        EXPAND_TOGGLE: roots.LIAT + ".expand_button_click_event",
        REVIEW_CHANGED_CLICK: roots.LIAT + ".review_changes_click_event",
        GOAL_MODAL_SAVED: roots.LIAT + ".goal_changes_saved_event",
        INCOME_PART_TAB_CLICK: roots.LIAT + ".income_part_tab_click_event"
    },
    INTEGRATED: {
        PAGE_VIEW: roots.INTEGRATED + ".pageView",
        WITHDRAWAL_PLANNER: roots.WITHDRAWAL + ".emergency_fund.click"
    },
    I_WANT_TO: {
        LINK_CLICKED: roots.I_WANT_TO + ".linkClicked"
    },
    TOUR: {
        STARTED: roots.TOUR + ".started"
    },
    FAQ: {
        CLICKED: roots.FAQ + ".clicked",
        SEARCHED: roots.FAQ + ".searched",
        CLICKED_PANEL: roots.FAQ + ".clicked_panel"
    },
    PRERETIREE: {
        ASSUMPTIONS_CLICK: roots.PRERETIREE + ".assumptions_click_event",
        PLAN_TO_AGE_MODAL_NUM_CLICK: roots.PRERETIREE + ".plan_to_age_click_event_agenum",
        PLAN_TO_AGE_MODAL_TEXT_CLICK: roots.PRERETIREE + ".plan_to_age_click_event_text",
        SAVE_CLICK_EVENT: roots.PRERETIREE + ".save_age_click_event",
        RESET_CLICK_EVENT: roots.PRERETIREE + ".reset_assumptions_click_event",
        CANCEL_CLICK_EVENT: roots.PRERETIREE + ".cancel_modal_click_event",
        SLIDER_AGE_CHANGE_EVENT: roots.PRERETIREE + ".slider_age_change_event",
        PLAN_TO_AGE_TAB_EVENT: roots.PRERETIREE + ".plan_to_age_tab_event",
        IMPORTANT_CONSIDERATIONS_TAB_EVENT:
            roots.PRERETIREE + ".important_considerations_tab_event",
        OTHER_ASSETS_LINK_CLICK_EVENT: roots.PRERETIREE + ".other_assets_link_click_event",
        SOCIAL_SECURITY_LINK_CLICK_EVENT: roots.PRERETIREE + ".social_security_link_click_event"
    },
    OTHER_ASSETS: {
        TIMETAP_LINK_CLICKED: roots.OTHER_ASSETS + ".timetap_link_clicked",
        ADD_OTHER_ASSET_CLICK: roots.OTHER_ASSETS + ".add_other_assets_click_event"
    },
    SOCIAL_SECURITY: {
        MODIFY_CLICK: roots.SOCIAL_SECURITY + ".modify_button_click_event"
    },
    REFERRAL: {
        EMAIL_CLICK: roots.REFERRAL + ".emailLinkClicked.openEmail",
        COPY_CLICK: roots.REFERRAL + ".copyLinkClicked.copy",
        TERMS_CLICK: roots.referral + ".termsClick.open"
    },
    DEMIN_MODAL: {
        X_CLICK: roots.DEMIN + "_x.click",
        CANCEL_CLICK: roots.DEMIN + "_cancel.click",
        CLAIM_ACCOUNT_CLICK: roots.DEMIN + "_claim_account.click",
        RADIO_BUTTON_CLICK: roots.DEMIN + "_radio_button.click"
    },
    QUICK_VIEW: {
        RATE_OF_RETURN_CLICKED: roots.QUICK_VIEW + ".rate_of_return_clicked",
        CONTRIBUTIONS_CLICKED: roots.QUICK_VIEW + ".contributions_clicked",
        BENEFICIARIES_CLICKED: roots.QUICK_VIEW + ".beneficiaries_clicked",
        LAST_TRANSACTION_CLICKED: roots.QUICK_VIEW + ".last_transaction_clicked"
    }
};

export default EventBusEvents;
