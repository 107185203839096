import React, { useState, memo, useCallback } from "react";

import { navigateFE } from "core-ui/client/react/core/services/adviceService.js";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { isArrayEmpty } from "gw-shared-components";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import {
    dispatchAmplitude,
    dispatchEventBus,
    setCurrentFault
} from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { getPAENavigationFault } from "../../utils/paeUtils";

const DEFAULT_ARR = [];

const MAX_DROPDOWN_SHORTLIST = 5;
const MORE = "More...";
const LESS = "Less...";

const ListItems = memo(({ menuItems = DEFAULT_ARR }) => {
    const dispatch = useDispatch();
    const paeFault = getPAENavigationFault();

    const handleClick = ({ key, paePreventNav, groupId, url }) => {
        if (paePreventNav) {
            dispatch(setCurrentFault(paeFault));
        } else if (groupId) {
            navigateFE(groupId);
        } else {
            window.dispatchEvent(
                new CustomEvent("open-sso-investment-modal", {
                    detail: url
                })
            );
            dispatch(dispatchEventBus(EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + key));
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_LINK,
                    event_properties: {
                        selection: EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + key
                    }
                })
            );
        }
    };

    return menuItems.map(
        ({
            key,
            url,
            label,
            openNewTab,
            className = "",
            enableSSOModal,
            groupId,
            paePreventNav = false
        }) => {
            if (enableSSOModal || groupId || paePreventNav) {
                return (
                    <li key={key} className={className}>
                        <button
                            type="button"
                            className="text-decoration-none text-left"
                            onClick={() =>
                                handleClick({
                                    key,
                                    paePreventNav,
                                    groupId,
                                    url
                                })
                            }
                            data-testid={key}
                        >
                            {label}
                        </button>
                    </li>
                );
            } else {
                return (
                    <li
                        key={key}
                        onClick={() => {
                            dispatch(
                                dispatchEventBus(EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + key)
                            );
                            dispatch(
                                dispatchAmplitude({
                                    event_type: AMPLITUDE_EVENTS.SELECT_LINK,
                                    event_properties: {
                                        selection: EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + key
                                    }
                                })
                            );
                        }}
                        className={className}
                    >
                        <a
                            className="text-decoration-none"
                            href={url}
                            target={openNewTab ? "_blank" : "_self"}
                            data-testid={key}
                            rel="noreferrer"
                        >
                            {label}
                        </a>
                    </li>
                );
            }
        }
    );
});

const ListItemsContainer = ({ menuItems = DEFAULT_ARR }) => {
    const dispatch = useDispatch();
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const handleClick = useCallback(() => {
        const value = dropDownOpen ? "less" : "more";
        dispatch(dispatchEventBus(EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + value));
        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_LINK,
                event_properties: {
                    selection: EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + value
                }
            })
        );
        setDropDownOpen(!dropDownOpen);
    }, [dispatch, setDropDownOpen, dropDownOpen]);

    if (isArrayEmpty(menuItems)) return null;

    if (menuItems.length <= MAX_DROPDOWN_SHORTLIST) {
        return (
            <ul className="list-unstyled dropdown">
                <ListItems menuItems={menuItems} />
            </ul>
        );
    }

    const getItems = () => {
        return menuItems.map((item, index) => {
            if (index >= MAX_DROPDOWN_SHORTLIST) {
                return dropDownOpen
                    ? { ...item, className: "show-element" }
                    : { ...item, className: "hide-element" };
            }
            return item;
        });
    };
    return (
        <ul
            className="list-unstyled dropdown"
            id="want-to-list-drop-down"
            data-testid="list-items-container"
        >
            <ListItems menuItems={getItems()} />
            <li className="text-decoration-none" key="more-key">
                <button
                    type="button"
                    aria-controls="want-to-list-drop-down"
                    aria-expanded={dropDownOpen}
                    onClick={handleClick}
                    data-testid="dropdownopen-id"
                >
                    {dropDownOpen ? LESS : MORE}
                </button>
            </li>
        </ul>
    );
};
ListItemsContainer.propTypes = {
    menuItems: PropTypes.array
};
ListItems.propTypes = {
    menuItems: PropTypes.array
};
ListItems.displayName = "ListItemsContainer";

export default memo(ListItemsContainer);
