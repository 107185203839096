/**
 * Function that truncates a given subset name if it exceeds 70 characters,
 * appending an ellipsis character at the end if truncated.
 *
 * @param subsetName The subset name to be truncated
 * @returns The truncated subset name
 */
const MAX_CHAR_LENGTH = 70;
const truncateSubsetName = (subsetName: string): string =>
    subsetName.length >= MAX_CHAR_LENGTH ? `${subsetName.slice(0, 70)}\u2026` : subsetName;
export default truncateSubsetName;
