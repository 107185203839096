import getHasAllMoneyTypeGroupings from "../getHasAllMoneyTypeGroupings";
import getIsPortfolioManagementAllowed from "../getIsPortfolioManagementAllowed";

interface IndividualOptions {
    allowAllocationsUpdate: boolean;
    currentAllocationModel: boolean;
    disableEquityMix: boolean;
    doMoneyTypeGroupingsApply: boolean;
    doTradeRestrictionsApply: null;
    hasAllMoneyTypeGroup: boolean;
    isPortfolioManagementAllowed: boolean;
    modelName: null;
}

/**
 * Does the participant require the non-all MTG flow
 * @returns {boolean}
 */
const isNonAllMoneyTypeGroupingsFlow = (individualOptions: IndividualOptions): boolean => {
    return (
        !getHasAllMoneyTypeGroupings(individualOptions) &&
        !getIsPortfolioManagementAllowed(individualOptions)
    );
};

export default isNonAllMoneyTypeGroupingsFlow;
