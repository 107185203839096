import React from "react";

import PropTypes from "prop-types";

const Backdrop = ({ handleBackdropClick }) => {
    return <div onClick={handleBackdropClick} className="progress-modal-backdrop" />;
};
Backdrop.propTypes = {
    handleBackdropClick: PropTypes.func
};
export default Backdrop;
