import StringUtil from "core-ui/client/src/app/StringUtil";

import ServiceRegister from "../../common/service/ServiceRegister.js";

const mockContext = "data";

const mocksContainedInWithQuery = (str, query) => {
    return str.indexOf(query) > -1;
};

export const hasMocks = () => {
    const query = "useMocks=true";
    const { hash, search } = window.location;

    return mocksContainedInWithQuery(search, query) || mocksContainedInWithQuery(hash, query);
};

export const canShowPreRetireeMocksEnabled = () => {
    const query = "canShowPreRetiree=true";
    const { hash, search } = window.location;

    return mocksContainedInWithQuery(search, query) || mocksContainedInWithQuery(hash, query);
};

export const canShowWhenCanIRetireMocksEnabled = () => {
    const query = "useWCIR=true";
    const { hash, search } = window.location;

    return mocksContainedInWithQuery(search, query) || mocksContainedInWithQuery(hash, query);
};

const isSlash = (char) => {
    return char === "/";
};

const slashConcat = (string1, string2) => {
    const lastChar = string1[string1.length - 1];
    const firstChar = string2[0];
    const lastCharSlash = isSlash(lastChar);
    const firstCharSlash = isSlash(firstChar);
    if (lastCharSlash && firstCharSlash) {
        return string1 + string2.substring(1);
    } else if (!lastCharSlash && !firstCharSlash) {
        return string1 + "/" + string2;
    } else {
        return string1 + string2;
    }
};

export const getUrl = (service) => {
    const serviceData = ServiceRegister[service];
    const isMock = hasMocks() || !!serviceData.forceMock;
    const baseUrl = serviceData.isPcapService
        ? globalThis.integratedEligibility.postOriginUrl
        : slashConcat(globalThis.baseApiUrl, "/participant-web-services/");

    if (isMock && !!serviceData.mockMax) {
        return slashConcat(mockContext, serviceData.mockMax);
    } else if (isMock) {
        return slashConcat(mockContext, serviceData.mock);
    } else if (serviceData.maxLive) {
        return slashConcat(globalThis.migratedContext, serviceData.maxLive);
    } else if (serviceData.isAsdb) {
        return slashConcat(globalThis.migratedContext, serviceData.live);
    } else {
        return slashConcat(baseUrl, serviceData.live);
    }
};

export const isLocalhost =
    window.location.origin.indexOf("127.0.0.1") > -1 ||
    window.location.origin.indexOf("localhost") > -1;

export const getStyleGuideUrl = (mockUrlPath, liveUrlPath) => {
    const url = isLocalhost ? __styleEnv__ + mockUrlPath : window.location.origin + liveUrlPath;

    return StringUtil.supplant(url, {
        planAccu: window.accu
    });
};

export const islocalMocksTrue = () => isLocalhost && hasMocks();
export const localMocksTrue = islocalMocksTrue();
export const islocalMocksFalse = () => isLocalhost && !hasMocks();
export const localMocksFalse = islocalMocksFalse();
