import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";

import { ROUTE_OPEN_ACCOUNT_OPTIONS, ROUTE_SETTINGS_PROFILE } from "../../../../../routes";
import { isRetailAtom, csrfAtom } from "../../../../atoms/atoms";
import { setHasRetailAccount } from "../../../../middleware/otherAssetsMiddleware";
import { QUERY_KEYS } from "../../../../queries/constants";

const useRetailAccount = () => {
    const isRetail = useAtomValue(isRetailAtom);

    const csrf = useAtomValue(csrfAtom);
    const history = useHistory();

    const { data: hasRetailAccount, isLoading: hasRetailAccountIsLoading } = useQuery({
        queryKey: [QUERY_KEYS.GET_ACCOUNTS_LITE],
        queryFn: () => setHasRetailAccount(csrf),
        enabled: !!csrf
    });

    // WCDX-14129 if user is on Profile page do not change route when refreshed to open account
    const isProfilePage = window.location.hash.indexOf(ROUTE_SETTINGS_PROFILE) !== -1;

    useEffect(() => {
        if (isRetail !== null && hasRetailAccount !== undefined && !hasRetailAccountIsLoading) {
            if (isRetail) {
                if (hasRetailAccount) {
                    globalThis.integratedEligibility.hasRetailAccount = true;
                } else {
                    if (!isProfilePage) {
                        history.push(ROUTE_OPEN_ACCOUNT_OPTIONS);
                    }
                    globalThis.integratedEligibility.hasRetailAccount = false;
                }
            }
        }
    }, [hasRetailAccount, isRetail, hasRetailAccountIsLoading, isProfilePage, history]);
};

export default useRetailAccount;
