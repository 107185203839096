import {
    SET_FINANCIAL_ENGINE_FORECAST,
    SET_ADVISORY_MESSAGING,
    SET_ADVISORY_KEY,
    SET_HAS_ADVISORY_MESSAGING,
    SET_ADVISORY_SVC_KEY
} from "../../actions/financialEngine/financialEngineForecastTypes";

const initialState = {
    feData: {},
    assumptions: {},
    advisoryMessaging: null,
    advisorySvcKey: "",
    keyType: "",
    dataIsLoaded: false,
    isAdvisoryDataLoaded: false,
    hasAdvisoryMessagingAccess: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_FINANCIAL_ENGINE_FORECAST:
            return {
                ...state,
                ...action.payload,
                ...{ dataIsLoaded: true }
            };
        case SET_ADVISORY_MESSAGING:
            return {
                ...state,
                ...action.payload,
                isAdvisoryDataLoaded: true
            };
        case SET_ADVISORY_KEY:
            return {
                ...state,
                keyType: action.payload
            };
        case SET_HAS_ADVISORY_MESSAGING:
            return {
                ...state,
                hasAdvisoryMessagingAccess: action.payload
            };
        case SET_ADVISORY_SVC_KEY:
            return {
                ...state,
                advisorySvcKey: action.payload
            };
        default:
            return state;
    }
};
