import React, { useState, useEffect, useRef, useCallback } from "react";

import { queryFocusableElements } from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isNil as _isNil } from "lodash";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import {
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    MAXIMIZER_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
} from "../../../routes";
import TABS from "../../constants/Tabs";
import { useIsEmulator, useIsPrivileged } from "../../hooks/useEmulatorType";
import {
    canShowHealthCareView,
    canShowHDICView,
    canShowMaximizer,
    canShowPreRetirementView,
    canShowWhenCanIRetire
} from "../../selectors/featureFlagSelectors";
import NavLink from "../navigation/CustomNavLink";

import { getDesktopTabsRoutes } from "./helpers";

const DesktopTabs = () => {
    const translations = selectTranslations("retirementIncome");
    const errorTranslations = selectTranslations("errors");

    const [activeTab, setActiveTab] = useState(TABS.RETIREMENT_INCOME);
    const [focusTabIndex, setFocusTabIndex] = useState(-1);
    const location = useLocation();
    const navigation = useSelector((state) => state.navigation.tabs);
    const participant = useSelector((state) => state.participant);
    const primaryPlan = useSelector((state) => state.primaryPlan);

    const howDoICompare = useSelector((state) => state.howDoICompare);
    const healthCare = useSelector((state) => state.healthCare);
    const isEmulator = useIsEmulator();
    const isPrivileged = useIsPrivileged();
    const isPAE = isEmulator && !isPrivileged;

    const history = useHistory();

    const { routes, visibleTabs } = getDesktopTabsRoutes(participant, primaryPlan);

    const getRouteIndex = useCallback(() => {
        let routeIndex = -1;
        if (
            routes.some((route, index) => {
                if (location.pathname.includes(route)) {
                    routeIndex = index;
                    return true;
                }
            })
        )
            return routeIndex;
    }, [location.pathname, routes]);

    const isFocusOnPrimaryTabs = () =>
        document.activeElement.id && document.activeElement.id.includes("income-parts-tabs");
    const isFocusOnNavArrow = () =>
        document.activeElement.id && document.activeElement.id.includes("arrow");

    const isFocusOnDesktopTabs = useCallback(
        () =>
            routes.some(
                (route) =>
                    document.activeElement.href && document.activeElement.href.includes(route)
            ),
        [routes]
    );

    const checkResetActiveTab = useCallback(() => {
        const resetPreRetirement = canShowPreRetirementView(participant, primaryPlan);

        if (!resetPreRetirement && activeTab === TABS.PRE_RETIREMENT) {
            history.push(RETIREMENT_INCOME_ROUTE);
        }
    }, [activeTab, history, participant, primaryPlan]);

    useEffect(() => {
        const route = navigation.find(
            (route) =>
                getLastStringSlashSeparatedPart(route.route) ===
                getLastStringSlashSeparatedPart(location.pathname)
        );
        if (!_isNil(route)) {
            setActiveTab(route.title);
        }
        checkResetActiveTab();
    }, [checkResetActiveTab, location.pathname, navigation]);

    useEffect(() => {
        const routeIndex = getRouteIndex();
        if (routeIndex > -1 && !isFocusOnPrimaryTabs && !isFocusOnNavArrow) {
            setFocusTabIndex(routeIndex);
        }
    }, [getRouteIndex, location]);

    const getLastStringSlashSeparatedPart = (str) => {
        const splittedArray = str.split("/");
        return splittedArray.pop();
    };

    useEffect(() => {
        const isFocusOnTabs = isFocusOnDesktopTabs();
        focusTabIndex > -1 &&
            listElements &&
            isFocusOnTabs &&
            listElements.current[focusTabIndex].focus();
    }, [focusTabIndex, isFocusOnDesktopTabs]);

    const handleTabListKeydown = (event) => {
        event.preventDefault();
        let nextFocusTabIndex;
        let index;
        switch (event.key) {
            case "ArrowLeft":
                index =
                    ((focusTabIndex === -1 ? visibleTabs.indexOf(activeTab) : focusTabIndex) - 1) %
                    visibleTabs.length;
                nextFocusTabIndex = index === -1 ? visibleTabs.length - 1 : index;
                setFocusTabIndex(nextFocusTabIndex);
                break;
            case "ArrowRight":
                nextFocusTabIndex =
                    ((focusTabIndex === -1 ? visibleTabs.indexOf(activeTab) : focusTabIndex) + 1) %
                    visibleTabs.length;
                setFocusTabIndex(nextFocusTabIndex);
                break;
            case "Tab":
                if (event.shiftKey) {
                    const rightArrowEl = document.querySelector(".right.arrow");
                    if (rightArrowEl) {
                        rightArrowEl.focus();
                    } else {
                        document.querySelector("#income-parts-tabs-1").focus();
                    }

                    setFocusTabIndex(-1);
                } else {
                    document
                        .querySelector("#retirement-savings-slide")
                        .querySelector(queryFocusableElements)
                        .focus();

                    setFocusTabIndex(-1);
                }
                break;
            case "Enter":
                listElements.current[focusTabIndex].click();
                break;
            case " ":
                listElements.current[focusTabIndex].click();
                break;
        }
    };

    const handleTabListFocus = () => {
        listElements.current[focusTabIndex === -1 ? getRouteIndex() : focusTabIndex].focus();
    };

    visibleTabs.push(TABS.RETIREMENT_INCOME);

    const tablist = useRef(null);
    const listElements = useRef([]);
    let currentTab = 0;

    return (
        <div className="middle-tabs--desktop" role="complementary" data-testid="desktop-tabs">
            <div className={`middle-tabs-nav`}>
                <div
                    className={
                        "list-middle-tabs" +
                        (canShowMaximizer(primaryPlan) ? " includes-maximizer" : "")
                    }
                    role="tablist"
                    onKeyDown={handleTabListKeydown}
                    onFocus={handleTabListFocus}
                    ref={tablist}
                >
                    <NavLink
                        className={activeTab === TABS.RETIREMENT_INCOME ? "is-active" : ""}
                        ref={(el) => (listElements.current[currentTab++] = el)}
                        role="tab"
                        aria-selected={activeTab === TABS.RETIREMENT_INCOME}
                        aria-controls="projected-income-tabpanel tab-content"
                        to={RETIREMENT_INCOME_ROUTE}
                        isActive={() => activeTab === TABS.RETIREMENT_INCOME}
                        id="income-parts-subtab-0"
                    >
                        {" "}
                        {translations.mainTabs.retirementIncome}{" "}
                    </NavLink>
                    {canShowHealthCareView(participant, primaryPlan) && (
                        <NavLink
                            className={activeTab === TABS.HEALTHCARE_COSTS ? "is-active" : ""}
                            ref={(el) => (listElements.current[currentTab++] = el)}
                            role="tab"
                            aria-selected={activeTab === TABS.HEALTHCARE_COSTS_ROUTE}
                            aria-controls="integrated-healthcare-container tab-content"
                            to={isPAE ? "#" : HEALTHCARE_COSTS_ROUTE}
                            disableLink={healthCare.errorOccurred}
                            tooltip={errorTranslations.featureCurrentlyUnavailable}
                            isActive={() => activeTab === TABS.HEALTHCARE_COSTS_ROUTE}
                            id="income-parts-subtab-1"
                        >
                            {" "}
                            {translations.mainTabs.healthCareCosts}
                        </NavLink>
                    )}
                    {canShowHDICView(primaryPlan) && (
                        <NavLink
                            className={activeTab === TABS.HOW_DO_I_COMPARE ? "is-active" : ""}
                            ref={(el) => (listElements.current[currentTab++] = el)}
                            role="tab"
                            aria-selected={activeTab === TABS.HOW_DO_I_COMPARE}
                            aria-controls="integrated-hdic tab-content"
                            to={HOW_DO_I_COMPARE_ROUTE}
                            disableLink={howDoICompare.errorOccurred}
                            tooltip={errorTranslations.defaultErrorMessage}
                            isActive={() => activeTab === TABS.HOW_DO_I_COMPARE}
                            id="income-parts-subtab-2"
                        >
                            {" "}
                            {translations.mainTabs.howDoICompare}{" "}
                        </NavLink>
                    )}

                    {canShowPreRetirementView(participant, primaryPlan) && (
                        <NavLink
                            className={activeTab === TABS.PRE_RETIREMENT ? "is-active" : ""}
                            ref={(el) => (listElements.current[currentTab++] = el)}
                            role="tab"
                            aria-selected={activeTab === TABS.PRE_RETIREMENT}
                            aria-controls="integrated-pre-retiree-view tab-content"
                            to={PRE_RETIREMENT_ROUTE}
                            disableLink={isPAE}
                            tooltip="Feature not available in emulator"
                            id="income-parts-subtab-3"
                            isActive={() => activeTab === TABS.PRE_RETIREMENT}
                        >
                            {" "}
                            {translations.mainTabs.preRetirement}{" "}
                        </NavLink>
                    )}

                    {canShowWhenCanIRetire(participant, primaryPlan) && (
                        <NavLink
                            className={activeTab === TABS.WHEN_CAN_I_RETIRE ? "is-active" : ""}
                            ref={(el) => (listElements.current[currentTab++] = el)}
                            role="tab"
                            aria-selected={activeTab === TABS.WHEN_CAN_I_RETIRE}
                            aria-controls="when-can-i-retire tab-content"
                            to={isPAE ? "#" : WHEN_CAN_I_RETIRE_ROUTE}
                            id="income-parts-subtab-4"
                            isActive={() => activeTab === TABS.WHEN_CAN_I_RETIRE}
                        >
                            {" "}
                            {translations.mainTabs.whenCanIRetire}{" "}
                        </NavLink>
                    )}

                    {canShowMaximizer(primaryPlan) && (
                        <NavLink
                            className={activeTab === TABS.MAXIMIZER ? "is-active" : ""}
                            ref={(el) => (listElements.current[currentTab++] = el)}
                            role="tab"
                            aria-selected={() => activeTab === TABS.MAXIMIZER}
                            {...(activeTab === TABS.MAXIMIZER && {
                                "aria-controls": "tab-content"
                            })}
                            to={MAXIMIZER_ROUTE}
                            id="income-parts-subtab-5"
                        >
                            {" "}
                            {translations.mainTabs.maximizer}{" "}
                        </NavLink>
                    )}
                </div>
            </div>
        </div>
    );
};

export { DesktopTabs };
