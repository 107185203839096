import React from "react";

import currencyFormatter from "currency-formatter";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import { formatCurrency } from "./formatCurrency";

/**
 * Wraps NumberFormat component
 */
export class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFormat: true
        };
    }

    /**
     * Pass the unformatted values to handlers based on props
     * @param {object} values - Represents the object with both formatted and unformatted values
     */
    onChange = (values) => {
        if (values.floatValue === this.props.value) {
            return;
        }

        const val = values.value;
        if (this.props.portion) {
            this.props.handlePortionBlur &&
                typeof this.props.handlePortionBlur === "function" &&
                this.props.handlePortionBlur(val, this.props.isPercent);
            return;
        }
        if (this.props.spouse) {
            this.props.handleSpouseIncome &&
                typeof this.props.handleSpouseIncome === "function" &&
                this.props.handleSpouseIncome(val, this.props.identifier);
        } else {
            this.props.handleIncome &&
                typeof this.props.handleIncome === "function" &&
                this.props.handleIncome(val, this.props.additional, this.props.identifier);
        }
    };
    format = (val) => {
        if (typeof val === "number") {
            val = val.toString();
        }
        const maxEntryLength = String(this.props.maxEditableEntry).length;
        let num = val;
        //take off leading zeros
        if (Number(num) >= 1) {
            num = num.replace(/^0+/, "");
        }
        //limit to max entry
        if (this.props.maxEditableEntry && Number(val) > this.props.maxEditableEntry) {
            const valStringArray = val.toString().split(".");
            num = valStringArray[0].substr(0, maxEntryLength);
            if (valStringArray.length > 1) {
                num += "." + valStringArray[1];
            }
        }
        return num;
    };

    removeFormat = (val) => {
        //take out repeating decimals
        val = val.replace(/\.+/, ".");
        //remove extra decimals
        let valDecArray = val.split(".");
        if (valDecArray.length > 2) {
            let newVal = valDecArray[0] + ".";
            for (let k = 1; k < valDecArray.length; k++) {
                newVal += valDecArray[k];
            }
            val = newVal;
        }
        //take out anything but numbers
        const valWhole = val.split(".")[0].replace(/\D+/g, "");
        if (this.props.decimals && valDecArray.length > 1) {
            const valDecimals = val.split(".")[1].replace(/\D+/, "");
            val = valWhole + "." + valDecimals;
        } else {
            val = valWhole;
        }
        //remove extra decimal values
        valDecArray = val.split(".");
        if (valDecArray.length > 1 && valDecArray[1].length > this.props.decimals) {
            val = valDecArray[0] + "." + valDecArray[1].substr(0, 2);
        }
        return val;
    };

    /**
     * Remove formatting from input values on focus
     * @param {object} e
     */
    handleFocus = (e) => {
        this.setState({
            showFormat: ""
        });
        e.target.value = currencyFormatter.unformat(e.target.value, { code: "USD" }) || "";
    };

    /**
     * Re-format values on focus
     * @param {object} e
     */
    handleBlur = (e) => {
        this.setState({
            showFormat: true
        });
        e.target.value = currencyFormatter.format(e.target.value, { code: "USD" });
    };

    /**
     * Make sure only numbers and one '.' is allowed
     * @param {object} e
     */
    validate = (e) => {
        const val = e.target.value;
        const valWhole = val.split(".")[0];
        if (e.key === "0" && e.target.selectionEnd === 0) {
            e.preventDefault();
        } else if (
            valWhole.length >= this.props.maxEditableEntry.toString().length &&
            e.key !== "Backspace"
        ) {
            e.preventDefault();
        }
    };
    /**
     * Function to validate the minimum value and apply
     * @param {valStr} string money value
     */

    validateMinValue = ({ valStr }) => {
        const minValue = 0.01;

        const valWhole = valStr.split(".")[0];
        const valDec = valStr.split(".")[1];
        const valDecNumber = Number(valDec);

        if (
            parseFloat(valStr) <= parseFloat(minValue) &&
            valDec &&
            valDec.length >= 3 &&
            valDecNumber !== 0
        ) {
            return minValue.toString();
        } else if (valDecNumber === 0) {
            return valWhole;
        }
        return valStr;
    };

    /**
     * Function for formatting to US currency
     */
    formatVal = (val, identifier) => {
        let valStr = val.toString();

        if (
            identifier === "part-salary" ||
            identifier === "spouse-salary" ||
            identifier === "spouse-portion" ||
            identifier === "part-portion"
        ) {
            return this.validateMinValue({ valStr });
        } else {
            valStr = formatCurrency(valStr);
            const valWhole = valStr.split(".")[0];
            const valDec = Number(valStr.split(".")[1]);
            return Number(valDec) === 0 ? valWhole : valStr;
        }
    };
    render() {
        let classDef = this.props.modal
            ? `form-control ${this.props.portion ? "dollar-portion" : ""}`
            : this.props.customizeClass
              ? this.props.className
              : "numberInput";

        // Override className using prop type, className and set prop type, customizeClass to true
        classDef = this.props.customizeClass ? this.props.className : classDef;

        return (
            <NumericFormat
                id={this.props.id}
                thousandSeparator={this.state.showFormat}
                decimalScale={this.props.decimals}
                fixedDecimalScale={!!(this.props.decimals > 0 && this.state.showFormat)}
                allowNegative={false}
                className={classDef}
                value={this.formatVal(this.props.value, this.props.identifier)}
                onValueChange={(values) => {
                    this.onChange(values);
                }}
                required={this.props.modal ? !this.props.additional : false}
                prefix={this.state.showFormat ? (this.props.modal ? "$" : "") : ""}
                onKeyDown={this.validate}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                //format={this.format}
                placeholder={this.props.placeholder ? this.props.placeholder : ""}
                removeFormatting={this.removeFormat}
                onClick={this.props.onClick}
            />
        );
    }
}

NumberInput.defaultProps = {
    maxEditableEntry: 10000000, // Do we want to drop one zero here ? per defect PART-29882
    decimals: 2
};
NumberInput.propTypes = {
    maxEditableEntry: PropTypes.number,
    decimals: PropTypes.number,
    handlePortionBlur: PropTypes.func,
    customizeClass: PropTypes.string,
    className: PropTypes.string,
    handleIncome: PropTypes.func,
    handleSpouseIncome: PropTypes.func,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    modal: PropTypes.bool,
    id: PropTypes.string,
    isPercent: PropTypes.bool,
    spouse: PropTypes.bool,
    value: PropTypes.number,
    portion: PropTypes.bool,
    identifier: PropTypes.string,
    additional: PropTypes.bool
};
