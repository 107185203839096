import React, { memo } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { hasHsaDeferralInPlan } from "../../../business/hsaLogic/hsa";
import AddContributionButton from "../../addDeferralModal/AddDeferralButton";
import ContributionSliders from "../components/ContributionsSliders";

const SummarySliderBreakdown = ({
    showSummaryExpansion,
    canShowSummary,
    setShowCsorModal,
    handleShowAddDeferralModal,
    availableDeferrals
}) => {
    const translations = selectTranslations("retirementIncome");
    const plans = useSelector((state) => state.participant.plans);
    const deferrals = useSelector((state) => state.participant.deferrals);

    return (
        <div className={`summaryBreakdown ${canShowSummary ? "multipleDeferrals" : ""}`}>
            <AddContributionButton
                handleShowAddDeferralModal={handleShowAddDeferralModal}
                availableDeferrals={availableDeferrals}
            />

            <ContributionSliders
                canShowSummary={canShowSummary}
                showSummaryExpansion={showSummaryExpansion}
                handleShowCsorModal={setShowCsorModal}
            />
            {hasHsaDeferralInPlan(plans, deferrals) &&
                translations.addContribution.deferralAlert && (
                    <div className="alert alert-warning rivd-customDeferralAlert">
                        <p>{translations.addContribution.deferralAlert}</p>
                    </div>
                )}
        </div>
    );
};
SummarySliderBreakdown.propTypes = {
    showSummaryExpansion: PropTypes.bool,
    canShowSummary: PropTypes.bool,
    setShowCsorModal: PropTypes.func,
    handleShowAddDeferralModal: PropTypes.func,
    availableDeferrals: PropTypes.array
};
export default memo(SummarySliderBreakdown);
