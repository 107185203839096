import StringUtil from "core-ui/client/src/app/StringUtil";

import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

export const getManagedAccountsProgramName = (investorId) => {
    const service = "managedAccountsProgramName";
    const url = StringUtil.supplant(getUrl(service), { investorId });

    const getManagedAccountsSuccess = (response) => {
        if (response) {
            if (response.data && response.data.message === "Unsupported Media Type") {
                const fault = {
                    code: "DSB100",
                    message: "Unsupported Media Type"
                };
                return Promise.reject(fault);
            }
            return response.data.data;
        }
    };

    return executeGet(url, "getManagedAccountsFault").then(getManagedAccountsSuccess);
};
