import {
    SET_ADVISORY_MESSAGING,
    SET_ADVISORY_SVC_KEY
} from "../financialEngine/financialEngineForecastTypes";

export const setAdvisoryMessaging = (payload) => {
    return {
        type: SET_ADVISORY_MESSAGING,
        payload: { advisoryMessaging: payload }
    };
};

export const setAdvisorySvcKey = (payload) => {
    return {
        type: SET_ADVISORY_SVC_KEY,
        payload
    };
};
