import React, { memo, useMemo } from "react";

import { useQuery } from "@tanstack/react-query";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useAtomValue } from "jotai";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { csrfAtom, hasRsgServicesAtom, isRetailAtom } from "../../atoms/atoms";
import {
    getAdvisoryServicesMessagingAttributes,
    PROVIDER_FE
} from "../../containers/AdvisoryServicesMessaging";
import { useHeaderStoreSelector } from "../../contexts";
import { getAccounts } from "../../middleware/otherAssetsMiddleware";
import { getReferral } from "../../middleware/userMessagesMiddleware";
import { useMaDetails, usePrimaryPlan } from "../../queries";
import { QUERY_KEYS } from "../../queries/constants";

import useWantToListItems from "./hooks/useWantToListItems";
import ListItemsContainer from "./ListItemsContainer";

const WantToListData = () => {
    const openNewAccountLink = useHeaderStoreSelector((state) => {
        return state?.navMenu?.integratedNavSettings
            ?.filter((item) => item.id === 6136)[0] // "Account" object
            ?.subMenu?.filter((subItem) => subItem.id === 1406)[0]; // returns string: "Open a new account"
    });

    const inboundSSOUser = useHeaderStoreSelector((state) => {
        return state?.shared?.authenticationStatus.inboundSSOUser;
    });

    const advisoryServicesMessaging = selectTranslations("advisoryServicesMessaging");
    const { iWantTo } = selectTranslations("rivdTodayView");

    const { pathname: currentRoute } = useLocation();
    const { primaryPlan, participant, navigationMenu, txnAccess } = useSelector(
        (state) => ({
            participant: state.participant,
            primaryPlan: state.primaryPlan,
            navigationMenu: state.navigationMenu,
            txnAccess: state.shared.txnAccess
        }),
        shallowEqual
    );

    const isRetail = useAtomValue(isRetailAtom);
    const hasRsgServices = useAtomValue(hasRsgServicesAtom);
    const csrf = useAtomValue(csrfAtom);

    const { data: hideIWantToLinks } = useQuery({
        queryKey: [QUERY_KEYS.GET_ACCOUNT_2],
        queryFn: () => getAccounts(csrf),
        enabled: !!csrf
    });
    const { data: referral } = useQuery({
        queryKey: [QUERY_KEYS.GET_USER_MESSAGES],
        queryFn: () => getReferral(csrf),
        enabled: !!csrf
    });
    const { data: userPrimaryPlan } = usePrimaryPlan();
    const { data: maDetails } = useMaDetails(userPrimaryPlan);
    const provider = useMemo(() => maDetails?.maProduct || "", [maDetails]);

    const enrolledInFullManagedAccounts = maDetails?.enrolledInManagedAccounts;

    /**
     * use to check is a user is FE and enrolled in Managed Accounts
     */
    const isUserFeMaEnrolled = useMemo(() => {
        return provider === PROVIDER_FE && enrolledInFullManagedAccounts;
    }, [enrolledInFullManagedAccounts, provider]);

    const showManagedAccounts = useMemo(() => {
        if (!maDetails) {
            return false;
        }

        const eligibleForManagedAccounts = isRetail || !!provider;

        if (eligibleForManagedAccounts || enrolledInFullManagedAccounts) {
            const msgAttributes = getAdvisoryServicesMessagingAttributes(
                enrolledInFullManagedAccounts,
                provider,
                primaryPlan.planRules.feForecastEnabled,
                currentRoute,
                advisoryServicesMessaging
            );

            if (maDetails.maProgramName) {
                msgAttributes.linkName = maDetails.maProgramName.replace("<TM>", "\u2122");
            }

            if (!msgAttributes) return false;

            return msgAttributes;
        }
        return false;
    }, [
        advisoryServicesMessaging,
        currentRoute,
        enrolledInFullManagedAccounts,
        isRetail,
        primaryPlan,
        maDetails,
        provider
    ]);
    const { empowerCrossSellConsent } = globalThis.integratedEligibility;

    const menuItems = useWantToListItems({
        isRetail,
        hasRsgServices,
        empowerCrossSellConsent,
        isUserFeMaEnrolled,
        showManagedAccounts,
        iWantTo,
        openNewAccountLink,
        inboundSSOUser,
        hideIWantToLinks,
        referral,
        navigationMenu,
        participant,
        primaryPlan,
        txnAccess,
        provider
    });

    /*
     // ORDER OF ITEMS
    1. View statements and documents
    2. Upload a document
    3. View/edit beneficiary information
    4. Update personal information
    5. Enroll in My Total Retirement or Go to My Total Retirement
    6. View/manage investments
    7. View transaction history
    8. Take a loan
    9. Take a Withdrawal
    10. Receive financial wellness consultation
    11. Learn about consolidating accounts
    12. Open a new account
    */
    return (
        <div className="main-container i-want-to-wrapper-list" data-testid="want-to-list">
            <div className="col-12">
                <ListItemsContainer menuItems={menuItems} />
            </div>
        </div>
    );
};

export default memo(WantToListData);
