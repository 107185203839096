import { isNonAllMoneyTypeGroupingsFlow, getIsPortfolioManagementAllowed } from "../index";

interface IndividualOptions {
    allowAllocationsUpdate: boolean;
    currentAllocationModel: boolean;
    disableEquityMix: boolean;
    doMoneyTypeGroupingsApply: boolean;
    doTradeRestrictionsApply: null;
    hasAllMoneyTypeGroup: boolean;
    isPortfolioManagementAllowed: boolean;
    modelName: null;
}

/**
 * Logic for detecting whether or not to submit an investment mix change
 * @returns {boolean}
 */
const equityMixChangesAllowed = (individualOptions: IndividualOptions): boolean => {
    return (
        !isNonAllMoneyTypeGroupingsFlow(individualOptions) &&
        getIsPortfolioManagementAllowed(individualOptions)
    );
};

export default equityMixChangesAllowed;
