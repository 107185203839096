import React, { useEffect, useState } from "react";

import { navigateFESSO, navigateFE } from "core-ui/client/react/core/services/adviceService";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { ObjectUtil } from "gw-shared-components";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE,
    HSA_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    PRE_RETIREMENT_ROUTE
} from "../../routes";
import { SET_ADVISORY_KEY } from "../actions/financialEngine/financialEngineForecastTypes";
import { setCurrentFault, dispatchEventBus } from "../actions/shared/sharedActionCreators";
import FEForecastModal from "../components/investmentTabs/feForecastModal/FEForecastModal";
import LeavingSiteToGoToMaModal from "../components/leavingSiteModal/LeavingSiteToGoToMaModal";
import { MaMessagingShell } from "../components/loadShells/LoadShells";
import EventBusEvents from "../events/eventBusEvents";
import LoggerFactory from "../factories/LoggerFactory";
import { useIsEmulator } from "../hooks/useEmulatorType";
import { loadAdvisoryMessaging } from "../middleware/financialEngineMiddleware";
import { useMaDetails, usePrimaryPlan } from "../queries";
import { getPAEActionFault, getPAENavigationFault } from "../utils/paeUtils";

export const PROVIDER_MTR = "MTR";
export const PROVIDER_MTR_TAS = "MTR_TAS";
export const PROVIDER_FE = "FINANCIAL_ENGINES";
export const PROVIDER_IBBOTSON = "IBBOTSON";

const PROVIDER_AMA = "AMA";
const PROGRAM_NAME_KEY = "{programName}";

export const IMAGE_SHOES = "image-shoes";
export const IMAGE_PUZZLE = "image-puzzle";
export const IMAGE_FLAG = "image-flag";

export const FOOTER_DISPLAY_FE_FORECAST = "FOOTER_DISPLAY_FE_FORECAST";
export const FOOTER_LEARN_MORE = "FOOTER_LEARN_MORE";
export const FOOTER_LEARN_MORE_FE_FORECAST = "FOOTER_LEARN_MORE_FE_FORECAST";
export const FOOTER_VIEW_PERSONALIZED_ANALYSIS = "FOOTER_VIEW_PERSONALIZED_ANALYSIS";

const OPEN_FE_FORECAST_MODAL = "OPEN_FE_FORECAST_MODAL";
const SSO_FINANCIAL_ENGINES = "SSO_FINANCIAL_ENGINES";
const SSO_FINANCIAL_ENGINES_FOOTER = "SSO_FINANCIAL_ENGINES_FOOTER";
const logger = LoggerFactory.getInstance("AdvisoryServicesMessaging");

/**
 * Returns a hash containing the following attributes used by Advisory Services messaging component
 * {
 *   title
 *   bodyHighlight
 *   body
 *   buttonLabel
 *   buttonUrl
 *   footer
 * }
 * or {
 *  missingData: true
 * }
 * If no Advisory Services message should be displayed
 */
export const getAdvisoryServicesMessagingAttributes = (
    enrolledInManagedAccounts,
    provider,
    displayRetirementIncomeForecast,
    currentRoute,
    advisoryServicesMessagingJson,
    customMtrMessageEnabled
) => {
    let key = null;
    const customInsert = customMtrMessageEnabled ? "_custom" : "";

    if (provider === PROVIDER_AMA) {
        provider = PROVIDER_MTR;
    }

    if (enrolledInManagedAccounts && provider === PROVIDER_MTR) {
        key = "enrolled_mtr_new";
    } else if (
        enrolledInManagedAccounts &&
        provider === PROVIDER_FE &&
        !displayRetirementIncomeForecast
    ) {
        key = "enrolled_fe_no_forecast";
    } else if (
        enrolledInManagedAccounts &&
        provider === PROVIDER_FE &&
        displayRetirementIncomeForecast
    ) {
        key = "enrolled_fe_forecast";
    } else if (enrolledInManagedAccounts && provider === PROVIDER_MTR_TAS) {
        key = "enrolled_tas";
    } else if (
        !enrolledInManagedAccounts &&
        provider === PROVIDER_FE &&
        !displayRetirementIncomeForecast
    ) {
        key = "not_enrolled_fe_no_forecast";
    } else if (
        !enrolledInManagedAccounts &&
        provider === PROVIDER_FE &&
        displayRetirementIncomeForecast
    ) {
        key = "not_enrolled_fe_forecast";
    } else if (!enrolledInManagedAccounts && currentRoute === SOCIAL_SECURITY_ROUTE) {
        key = `not_enrolled_mtr_new_social_security`;
    } else if (!enrolledInManagedAccounts && currentRoute === OTHER_ASSETS_ROUTE && !customInsert) {
        key = `not_enrolled_mtr_new_other_assets`;
    } else if (!enrolledInManagedAccounts && currentRoute === HSA_ROUTE && !customInsert) {
        key = `not_enrolled_mtr_new_hsa`;
    } else if (!enrolledInManagedAccounts && currentRoute === INCOME_GAP_ROUTE && !customInsert) {
        key = `not_enrolled_mtr_new_income_gap`;
    } else if (
        !enrolledInManagedAccounts &&
        currentRoute === HEALTHCARE_COSTS_ROUTE &&
        !customInsert
    ) {
        key = `not_enrolled_mtr_new_healthcare_costs`;
    } else if (
        !enrolledInManagedAccounts &&
        currentRoute === HOW_DO_I_COMPARE_ROUTE &&
        !customInsert
    ) {
        key = `not_enrolled_mtr_new_hdic`;
    } else if (
        !enrolledInManagedAccounts &&
        currentRoute === PRE_RETIREMENT_ROUTE &&
        !customInsert
    ) {
        key = `not_enrolled_mtr_new_pre_retirement`;
        // This handles all other routes for !enrolledInManagedAccounts and provider is MTR_NEW
    } else if (!enrolledInManagedAccounts && provider === PROVIDER_MTR) {
        key = `not_enrolled_mtr_new${customInsert || "_default"}`;
    }

    if (key) {
        return { ...advisoryServicesMessagingJson[key], ...{ key: key } };
    }
    return {
        missingData: true
    };
};

const addGaIdAndIndIdToUrl = (url, planId, individualId) => {
    return url.replace("{gaId}", planId).replace("{indId}", individualId);
};

const ButtonLabel = ({ label, professionalManagementProgramName }) => {
    return (
        <span className="btn-label">
            {label.replace(PROGRAM_NAME_KEY, professionalManagementProgramName)}
        </span>
    );
};
ButtonLabel.propTypes = {
    label: PropTypes.string,
    professionalManagementProgramName: PropTypes.string
};

const AdvisoryServicesMessaging = ({
    additionalClasses = "",
    showLoader = false,
    getDynamicMessaging
}) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("advisoryServicesMessaging");
    const currentRoute = useLocation().pathname;

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const participant = useSelector((state) => state.participant);
    const individualId = participant.individualId;
    const planId = primaryPlan.id;
    const financialEngineForecast = useSelector((state) => state.financialEngineForecast);
    const customMtrMessageEnabled = useSelector(
        (state) => state.shared.txnAccess.customMtrMessageEnabled
    );
    const txnAccess = useSelector((state) => state.shared.txnAccess);
    const txnAccessSet = useSelector((state) => state.shared.txnAccessSet);
    const hasAdvisoryAccess = !txnAccess.hideAdvisoryServicesMessaging;
    const displayForecast = txnAccess.displayFeForecast;

    const [attributes, setAttributes] = useState(null);
    const [feForecastModalIsVisible, setFeForecastModalIsVisible] = useState(false);
    const [leavingSiteToGoToFeModalIsVisible, setLeavingSiteToGoToFeModalIsVisible] =
        useState(false);
    const [ktmgLoaded, setKtmgLoaded] = useState(false);

    const { data: userPrimaryPlan } = usePrimaryPlan();
    const { data: maDetails } = useMaDetails(userPrimaryPlan);

    const programName = maDetails?.maProgramName;
    const provider = maDetails?.maProduct;
    const enrolledInFullManagedAccounts = maDetails?.enrolledInManagedAccounts;

    const professionalManagementProgramName =
        !!maDetails && !!programName
            ? String(programName).indexOf("<C>") > -1
                ? programName.replace("<C>", "\u00A9")
                : String(programName).indexOf("<TM>") > -1
                  ? programName.replace("<TM>", "\u2122")
                  : programName
            : "managed accounts";

    const isPAEUser = useIsEmulator();
    const componentClass = !showLoader ? "loaded" : "";

    const readyToShowAdvisoryMessaging = getDynamicMessaging
        ? financialEngineForecast.isAdvisoryDataLoaded
        : true;

    useEffect(() => {
        if (individualId && planId && !ktmgLoaded && maDetails && txnAccessSet) {
            setKtmgLoaded(true);

            if (getDynamicMessaging) {
                dispatch(
                    loadAdvisoryMessaging(
                        individualId,
                        planId,
                        translations,
                        displayForecast,
                        enrolledInFullManagedAccounts
                    )
                );
            }
        }
    }, [
        individualId,
        translations,
        planId,
        ktmgLoaded,
        dispatch,
        maDetails,
        enrolledInFullManagedAccounts,
        getDynamicMessaging,
        displayForecast,
        txnAccessSet
    ]);

    useEffect(() => {
        if (readyToShowAdvisoryMessaging) {
            let advisoryMessagingTranslations = {
                ...translations
            };

            if (!ObjectUtil.isUndefinedOrNull(financialEngineForecast.advisoryMessaging)) {
                advisoryMessagingTranslations = {
                    ...advisoryMessagingTranslations,
                    ...financialEngineForecast.advisoryMessaging
                };
            }

            const msgAttributes = getAdvisoryServicesMessagingAttributes(
                enrolledInFullManagedAccounts,
                provider,
                displayForecast,
                currentRoute,
                advisoryMessagingTranslations,
                customMtrMessageEnabled
            );

            if (
                !ObjectUtil.isUndefinedOrNull(msgAttributes) &&
                !ObjectUtil.isUndefinedOrNull(msgAttributes.key)
            ) {
                setAttributes(msgAttributes);
                logger.debug("advisory messaging: " + msgAttributes.key);

                dispatch({
                    type: SET_ADVISORY_KEY,
                    payload: msgAttributes.key
                });
            }
        }
    }, [
        provider,
        primaryPlan,
        currentRoute,
        translations,
        financialEngineForecast.advisorySvcKey,
        dispatch,
        financialEngineForecast.hasAdvisoryMessagingAccess,
        customMtrMessageEnabled,
        enrolledInFullManagedAccounts,
        financialEngineForecast.advisoryMessaging,
        financialEngineForecast.feData,
        readyToShowAdvisoryMessaging,
        displayForecast
    ]);

    /**
     * Navigate to the Financial Engines using it's SSO process.
     *
     * @returns {promise}
     */
    const navigateToFinancialEngines = (payload) => {
        // do we still need to init the advice service?
        setLeavingSiteToGoToFeModalIsVisible(false);

        const groupAccount = {
            individualId: participant.individualId,
            groupId: primaryPlan.id
        };

        return payload
            ? navigateFESSO(groupAccount, payload.pageCode, payload.treatment, payload.viewCode)
            : navigateFE(groupAccount);
    };

    const navigateTo = (url) => {
        window.location.href = url;
    };

    const dispatchButtonClick = (buttonUrl) => {
        dispatch(
            dispatchEventBus(EventBusEvents.ADVISORY_SERVICES.BUTTON_CLICKED, this, {
                enrolledInFullManagedAccounts,
                provider,
                feForecastEnabled: primaryPlan.planRules.feForecastEnabled,
                currentRoute,
                buttonUrl
            })
        );
    };

    const openFeForecastModal = () => {
        setFeForecastModalIsVisible(true);
    };

    const handleClickFinancialEngineSSO = () => {
        if (isPAEUser) {
            dispatch(setCurrentFault(getPAEActionFault()));
            return;
        }
        navigateToFinancialEngines(attributes.sso);
    };

    const hasButton = !!attributes?.buttonUrl && !!attributes?.buttonLabel;
    const isSingleSignOn = attributes?.buttonUrl === SSO_FINANCIAL_ENGINES;

    return (
        readyToShowAdvisoryMessaging &&
        !!attributes &&
        !attributes.missingData &&
        hasAdvisoryAccess && (
            <React.Fragment>
                <div
                    className={`advisory-services-messaging ${additionalClasses} ${componentClass}`}
                    data-testid="advisory-services-messaging"
                >
                    {showLoader && <MaMessagingShell />}
                    <div className={`use-fade-in ${componentClass}`}>
                        <div className="col1-tablet">
                            {!!attributes.image && <div className={"image " + attributes.image} />}
                            {!!attributes.title && (
                                <div
                                    className="title"
                                    role="heading"
                                    aria-level="2"
                                    data-testid="attribute-title"
                                >
                                    {attributes.title.replace(
                                        PROGRAM_NAME_KEY,
                                        professionalManagementProgramName
                                    )}
                                </div>
                            )}
                            {(!!attributes.bodyHighlight || !!attributes.body) && (
                                <div className="body">
                                    <p className="body-highlight">{attributes.bodyHighlight}</p>
                                    <section data-testid="attribute-body">
                                        <p>{attributes.body}</p>
                                    </section>
                                </div>
                            )}
                        </div>
                        <div className="col2-tablet">
                            {hasButton && !isSingleSignOn && (
                                <button
                                    className={`btn ${"btn-pcap-primary"} 'btn-save'`}
                                    onClick={() => {
                                        if (isPAEUser) {
                                            dispatch(setCurrentFault(getPAENavigationFault()));
                                            return;
                                        }
                                        dispatchButtonClick(attributes.buttonUrl);
                                        navigateTo(
                                            addGaIdAndIndIdToUrl(
                                                attributes.buttonUrl,
                                                primaryPlan.id,
                                                participant.individualId
                                            )
                                        );
                                    }}
                                    data-testid="attribute-button-label"
                                >
                                    <ButtonLabel
                                        label={attributes.buttonLabel}
                                        professionalManagementProgramName={
                                            professionalManagementProgramName
                                        }
                                    />
                                </button>
                            )}

                            {hasButton && isSingleSignOn && (
                                <button
                                    className="btn btn-pcap-primary"
                                    onClick={() => {
                                        if (isPAEUser) {
                                            dispatch(setCurrentFault(getPAEActionFault()));
                                            return;
                                        }
                                        dispatchButtonClick(attributes.buttonUrl);
                                        navigateToFinancialEngines(attributes.sso);
                                    }}
                                >
                                    <ButtonLabel
                                        label={attributes.buttonLabel}
                                        professionalManagementProgramName={
                                            professionalManagementProgramName
                                        }
                                    />
                                </button>
                            )}

                            {!!attributes.footer &&
                                attributes.footer.length > 0 &&
                                provider !== PROVIDER_MTR &&
                                provider !== PROVIDER_AMA && (
                                    // Don't display this footer for AMA, MTR
                                    <div className="footer">
                                        {attributes.footer.map((data) => {
                                            return (
                                                <span key={data.preLinkText}>
                                                    {data.preLinkText}&nbsp;
                                                    {!!data.link &&
                                                        data.link.lookup &&
                                                        data.link.url ===
                                                            OPEN_FE_FORECAST_MODAL && (
                                                            <a
                                                                role="button"
                                                                tabIndex="0"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        dispatchEventBus(
                                                                            EventBusEvents
                                                                                .ADVISORY_SERVICES
                                                                                .FOOTER_LINK_CLICKED,
                                                                            this,
                                                                            {
                                                                                enrolledInFullManagedAccounts,
                                                                                provider,
                                                                                feForecastEnabled:
                                                                                    primaryPlan
                                                                                        .planRules
                                                                                        .feForecastEnabled,
                                                                                currentRoute,
                                                                                destination:
                                                                                    "Open Financial Engine Forecast modal"
                                                                            }
                                                                        )
                                                                    );
                                                                    openFeForecastModal();
                                                                }}
                                                            >
                                                                {data.link.label}
                                                            </a>
                                                        )}
                                                    {!!data.link &&
                                                        data.link.label &&
                                                        data.link.lookup &&
                                                        data.link.url ===
                                                            SSO_FINANCIAL_ENGINES_FOOTER && (
                                                            <section className="fe-sso-class">
                                                                <button
                                                                    className="as-button-link"
                                                                    onClick={
                                                                        handleClickFinancialEngineSSO
                                                                    }
                                                                >
                                                                    {data.link.label}
                                                                </button>
                                                            </section>
                                                        )}
                                                    {!!data.link && !data.link.lookup && (
                                                        <button
                                                            className="as-button-link"
                                                            onClick={() => {
                                                                const url = addGaIdAndIndIdToUrl(
                                                                    data.link.url,
                                                                    primaryPlan.id,
                                                                    participant.individualId
                                                                );
                                                                dispatch(
                                                                    dispatchEventBus(
                                                                        EventBusEvents
                                                                            .ADVISORY_SERVICES
                                                                            .FOOTER_LINK_CLICKED,
                                                                        this,
                                                                        {
                                                                            enrolledInFullManagedAccounts,
                                                                            provider,
                                                                            feForecastEnabled:
                                                                                primaryPlan
                                                                                    .planRules
                                                                                    .feForecastEnabled,
                                                                            currentRoute,
                                                                            url
                                                                        }
                                                                    )
                                                                );
                                                                window.location.href = url;
                                                            }}
                                                        >
                                                            {data.link.label}
                                                        </button>
                                                    )}
                                                    &nbsp;{data.postLinkText}&nbsp;
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                {feForecastModalIsVisible && (
                    <FEForecastModal
                        eventBus={{ dispatch: dispatchEventBus }}
                        visible={
                            feForecastModalIsVisible &&
                            _get(financialEngineForecast, "dataIsLoaded")
                        }
                        data={financialEngineForecast}
                        handleCloseFE={() => {
                            setFeForecastModalIsVisible(false);
                        }}
                        handleBackdropClick={() => {
                            setFeForecastModalIsVisible(false);
                        }}
                    />
                )}
                {/* <!-- SSO FE (Financial Engines) Hidden Form --> */}
                {/* <!-- Allows users to navigate to FE by dropping SAML token details into the form and submitting --> */}
                <div className="hidden">
                    <form id="SAMLFORM" method="post" target="_blank">
                        <textarea id="SAMLResponse" name="SAMLResponse" aria-label="SAMLResponse" />
                    </form>
                </div>

                {/* <!-- FE/Ibbotson/ Leaving site warning modal --> */}
                {leavingSiteToGoToFeModalIsVisible && (
                    <LeavingSiteToGoToMaModal
                        onClick={() => navigateToFinancialEngines()}
                        onCancel={() => setLeavingSiteToGoToFeModalIsVisible(false)}
                    />
                )}
            </React.Fragment>
        )
    );
};

AdvisoryServicesMessaging.propTypes = {
    additionalClasses: PropTypes.string,
    showLoader: PropTypes.bool,
    getDynamicMessaging: PropTypes.bool
};

export default AdvisoryServicesMessaging;
