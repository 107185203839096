import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";

import ManagedAccountsFactory from "../factories/ManagedAccountsFactory";
import RequestFactory from "../factories/RequestFactory";
import { getUrl } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("InvestmentService");

export const getManagedAccounts = (individualId, plan) => {
    logger.info("get( individualId = {0}, plan = {1} )", [individualId, plan.id]);

    const getInvestmentServicesResult = function (result) {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.error("getInvestmentServicesResult( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("getInvestmentServicesResult()");

            const data = result.data;
            const managedAccountsOptions = data.managedAccountsOptions;

            return ManagedAccountsFactory.create(managedAccountsOptions);
        }
    };

    const getInvestmentServicesFault = function (fault) {
        logger.error("getInvestmentServicesFault( fault = {0} )", [fault.message]);
        throw fault;
    };

    const params = {
        individualId: individualId,
        groupId: plan.id // the primary plan's ID is the group ID
    };

    const url = StringUtil.supplant(getUrl("getInvestmentServices"), params);
    const method = RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const request = RequestFactory.create(url, null, method, contentType);
    delete request.headers.samlToken;
    return axios(request).then(getInvestmentServicesResult, getInvestmentServicesFault);
};
