interface IndividualOptions {
    allowAllocationsUpdate: boolean;
    currentAllocationModel: boolean;
    disableEquityMix: boolean;
    disableInvestmentMix?: boolean;
    doMoneyTypeGroupingsApply: boolean;
    doTradeRestrictionsApply: null;
    hasAllMoneyTypeGroup: boolean;
    isPortfolioManagementAllowed: boolean;
    modelName: null;
}

interface PartialPrimaryPlan {
    planRules: {
        employerDirectedAllocationsOnly: boolean;
    };
}
/**
 * Can model investment mix - if this returns false, the investment mix slider will be grayed out
 * @returns {boolean}
 */
const canChangeEquityMix = (
    primaryPlan: PartialPrimaryPlan,
    individualOptions: IndividualOptions
): boolean => {
    return (
        !primaryPlan.planRules.employerDirectedAllocationsOnly &&
        !(individualOptions.disableEquityMix || individualOptions.disableInvestmentMix)
    );
};

export default canChangeEquityMix;
