import React, { useEffect } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import StringUtil from "core-ui/client/src/app/StringUtil";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import { NumberInput } from "../numberinput/NumberInput.jsx";

import PortionForm from "./PortionForm";
import Tooltip from "./Tooltip";

const UserBody = (props) => {
    const additionalComp = props.additionalComp >= 0 ? props.additionalComp : "";
    const userClass = "label-left";
    const modalFlex = "modal-well-flex";
    const salary = props.mustEnterSalary && props.salary === 0 ? "" : props.salary;
    const portionText = props.labelTranslations.portionForRet;

    const incomeText = !props.isJPM()
        ? props.tooltipTranslations.currentIncomeText
        : `${props.labelTranslations.current} ${props.labelTranslations.base} ${props.labelTranslations.salary}`;

    useEffect(() => {
        if (props.errorId.includes("part-salary")) {
            props.portionValidator(
                props.portion,
                props.salary,
                props.additionalComp,
                props.isPercent,
                "part-salary",
                props.isSpouse
            );
        }
        props.setErrorHighlights("part");
    }, [props]);

    /**
     * Passes user salary and additional compensation input value to dispatch function
     * Rejects if salary is less than 0 or greater than 10 million
     * @param {string} val - Input value
     * @param {boolean} additional - Determine whether or not to call dispatch function for additional comp
     */
    const handleIncome = (val, additional, id) => {
        let additionalPortion, salary;
        if (additional) {
            additionalPortion = val === "" ? 0 : Number(val);
            salary = props.salary;
            props.handleAdditionalComp(additionalPortion);
        } else {
            salary = Number(val);
            additionalPortion = props.additionalComp || 0;
            props.handleBlurSalary(salary);
        }
        props.portionValidator(
            props.portion,
            salary,
            additionalPortion,
            props.isPercent,
            id,
            props.isSpouse
        );
    };

    const handleTooltipShow = (id) => {
        const tooltip = document.getElementById(id);
        tooltip?.classList?.toggle("messageHover");
        props.eventBus.dispatch(props.mtrGoalModalEvents.TOGGLE_TOOLTIP, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.TOGGLE_EVENT,
            selection: props.mtrGoalModalEvents.TOGGLE_TOOLTIP
        });
    };

    const handleIncomeClick = () => {
        props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_INCOME, this, {
            income: props.salary
        });
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.CHANGE_FIELD,
            selection: props.mtrGoalModalEvents.UPDATE_INCOME,
            payload: {
                income: props.salary
            }
        });
    };

    const handleAdditionalIncomeClick = () => {
        props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_ADDITIONAL_INCOME, this, {
            additional: props.additionalComp
        });
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.CHANGE_FIELD,
            selection: props.mtrGoalModalEvents.UPDATE_ADDITIONAL_INCOME,
            payload: {
                additional: props.additionalComp
            }
        });
    };

    const handleDobClick = () => {
        props.eventBus.dispatch(props.mtrGoalModalEvents.UPDATE_DOB, this, {
            dob: props.dob
        });
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.CHANGE_FIELD,
            selection: props.mtrGoalModalEvents.UPDATE_DOB,
            payload: {
                dob: props.dob
            }
        });
    };

    return (
        <div className={`modal-well goal with-background`} data-testid="user-body">
            <div className={modalFlex}>
                <div className="individual-goal">
                    <p>
                        {`${props.labelTranslations.myGoalFor} ${props.period} ${props.labelTranslations.retirementIncomeIs}`}
                        <NumericFormat
                            thousandSeparator={true}
                            decimalScale={0}
                            fixedDecimalScale={false}
                            allowNegative={false}
                            displayType={"text"}
                            prefix={"$"}
                            value={`${Math.round(props.userGoal)}`}
                            className="goal-text max-goal"
                        />
                    </p>
                </div>

                <div className="form-container-flex-child">
                    <div id="part-salary" className="form-group row salary-container input-flex">
                        <label htmlFor="todaysSalary" className={`${userClass} control-label`}>
                            {incomeText}
                            {""}
                        </label>
                        <div id="ppt-sal" onClick={handleIncomeClick} className={userClass}>
                            <NumberInput
                                id="todaysSalary"
                                additional={false}
                                handleIncome={handleIncome}
                                handleError={props.handleError}
                                type="text"
                                value={salary}
                                modal={true}
                                canSave={props.canSave}
                                identifier="part-salary"
                            />
                            {props.showSalaryDisplay && (
                                <div
                                    onChange={props.handleSalaryMessage}
                                    className="salary-message-container"
                                >
                                    <input id="salary-message" type="checkbox" />
                                    <label htmlFor="salary-message">
                                        <span>
                                            Use the salary provided by my employer (
                                            <NumericFormat
                                                thousandSeparator={true}
                                                decimalScale={0}
                                                fixedDecimalScale={false}
                                                allowNegative={false}
                                                displayType={"text"}
                                                prefix={"$"}
                                                value={`${Math.round(props.employerSalary)}`}
                                            />
                                            )
                                        </span>
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    {props.hasOtherVariableComp ? (
                        <div
                            id="part-var"
                            className="form-group row other-income-container input-flex"
                        >
                            <Tooltip
                                message={props.labelTranslations.varSalTooltip}
                                id={"addCompTooltip"}
                            />
                            <label
                                onMouseEnter={() => handleTooltipShow("addCompTooltip")}
                                onMouseLeave={() => handleTooltipShow("addCompTooltip")}
                                htmlFor="otherIncome"
                                className={`${userClass} control-label`}
                            >
                                {props.labelTranslations.varSal}
                                {""}
                            </label>
                            <div
                                id="var-sal"
                                onClick={handleAdditionalIncomeClick}
                                className={userClass}
                            >
                                <NumberInput
                                    id="otherIncome"
                                    additional={true}
                                    handleIncome={handleIncome}
                                    handleError={props.handleError}
                                    type="text"
                                    value={additionalComp}
                                    modal={true}
                                    canSave={props.canSave}
                                    identifier="part-var"
                                />
                            </div>
                        </div>
                    ) : null}
                    <div
                        id="part-dob"
                        className="form-group row date-of-birth-container input-flex"
                    >
                        <Tooltip
                            message={
                                props.dobDisabled
                                    ? StringUtil.supplant(
                                          props.labelTranslations.contactForChangesTooltipText
                                      )
                                    : StringUtil.supplant(
                                          props.labelTranslations.dateOfBirthTooltipText
                                      )
                            }
                            id={props.dobDisabled ? "contactChangesTooltip" : "dateOfBirthTooltip"}
                        />
                        <label
                            htmlFor="date-of-birth"
                            className={`${userClass} control-label`}
                            data-tooltip="portion"
                            onMouseEnter={() => {
                                if (props.dobDisabled) {
                                    handleTooltipShow("contactChangesTooltip");
                                }
                            }}
                            onMouseLeave={() => {
                                if (props.dobDisabled) {
                                    handleTooltipShow("contactChangesTooltip");
                                }
                            }}
                        >
                            {props.labelTranslations.dateOfBirth}
                            {""}
                            <i
                                data-tooltip="portion"
                                onMouseEnter={() => handleTooltipShow("dateOfBirthTooltip")}
                                onMouseLeave={() => handleTooltipShow("dateOfBirthTooltip")}
                                className="em-question-mark"
                            />
                        </label>
                        <div onClick={handleDobClick} className={userClass}>
                            <input
                                type="text"
                                onKeyDown={(e) => props.handleDOBValidation(e, false, "part-dob")}
                                onKeyUp={(e) => props.handleDOBValidation(e, false, "part-dob")}
                                onPaste={(e) => props.handleDOBValidation(e, false, "part-dob")}
                                onBlur={props.handleDobBlur}
                                className={`form-control ${props.dobDisabled ? "is-disabled" : ""}`}
                                id="date-of-birth"
                                data-testid="date-of-birth"
                                name="dateOfBirth"
                                defaultValue={props.dob}
                                aria-disabled={props.dobDisabled}
                                required
                            />
                            <p className="goal-modal-error dob-error">{props.dobError}</p>
                        </div>
                    </div>
                    <div id="part-portion" className="form-group row portion-container input-flex">
                        <Tooltip
                            message={StringUtil.supplant(
                                props.labelTranslations.incomeReplacementTooltipText,
                                { num: props.defaultIncomeGoal }
                            )}
                            id={"portionTooltipUser"}
                        />
                        <label
                            htmlFor="income-replacement"
                            className={`${userClass} control-label long-label`}
                        >
                            {portionText}{" "}
                            <span className="portion-suggestion">
                                {`(${StringUtil.supplant(
                                    props.labelTranslations.incomeReplacementSubText,
                                    { num: props.defaultIncomeGoal }
                                )} `}
                                {")"}
                                <i
                                    data-tooltip="portion"
                                    onMouseEnter={() => handleTooltipShow("portionTooltipUser")}
                                    onMouseLeave={() => handleTooltipShow("portionTooltipUser")}
                                    className="em-question-mark"
                                />
                            </span>
                            {""}
                        </label>
                        <div className={userClass}>
                            <PortionForm
                                handlePortion={props.handleBlurPortion}
                                handleError={props.handleError}
                                portionValidator={props.portionValidator}
                                salary={props.salary}
                                isSpouse={false}
                                additional={props.additionalComp || 0}
                                portion={props.portion}
                                canSave={props.canSave}
                                isPercent={props.isPercent}
                                eventBus={props.eventBus}
                                mtrGoalModalEvents={props.mtrGoalModalEvents}
                                hasLinkedAccounts={props.hasLinkedAccounts}
                                identifier="part-portion"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserBody.propTypes = {
    dataTestId: PropTypes.string,
    handleChangeView: PropTypes.func.isRequired,
    handleBlurPortion: PropTypes.func.isRequired,
    handleBlurSalary: PropTypes.func.isRequired,
    handleAdditionalComp: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    handleDOBValidation: PropTypes.func.isRequired,
    portionValidator: PropTypes.func.isRequired,
    hasOtherVariableComp: PropTypes.bool.isRequired,
    additionalComp: PropTypes.number,
    portion: PropTypes.number,
    salary: PropTypes.number.isRequired,
    period: PropTypes.string.isRequired,
    userGoal: PropTypes.number.isRequired,
    dob: PropTypes.string.isRequired,
    canSave: PropTypes.bool.isRequired,
    isPercent: PropTypes.bool.isRequired,
    dobDisabled: PropTypes.bool.isRequired,
    tooltipTranslations: PropTypes.object.isRequired,
    labelTranslations: PropTypes.object.isRequired,
    errorId: PropTypes.array.isRequired,
    dobError: PropTypes.string.isRequired,
    mustEnterSalary: PropTypes.bool,
    setErrorHighlights: PropTypes.func,
    isSpouse: PropTypes.bool,
    defaultIncomeGoal: PropTypes.number,
    removeLeadingZeroFromDate: PropTypes.func,
    handleDobBlur: PropTypes.func,
    hasLinkedAccounts: PropTypes.bool,
    isJPM: PropTypes.func,
    eventBus: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    mtrGoalModalEvents: PropTypes.object,
    showSalaryDisplay: PropTypes.bool,
    handleSalaryMessage: PropTypes.func,
    employerSalary: PropTypes.number
};

export default UserBody;
