import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isUndefinedOrNull, Loader } from "gw-shared-components";
import { useAtomValue } from "jotai";
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    RETIREMENT_INCOME_ROUTE,
    HSA_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    PRE_RETIREMENT_ROUTE,
    MAXIMIZER_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
} from "../../../routes";
import {
    setShowModal as setModalVisible,
    tabChange
} from "../../actions/goal/goalModalActionCreators";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import { csrfAtom } from "../../atoms/atoms";
import { ACCESSIBILITY } from "../../constants/AccessibilityPaycheck";
import { mtrGoalModalEvents } from "../../events/mtrGoalModalEvents";
import { updateParticipantSalaryReminder } from "../../middleware/goalMiddleware";
import { QUERY_KEYS } from "../../queries/constants";
import { isValueUnitsPercent } from "../../selectors/participantSelectors";
import { reselectTermPeriod } from "../../selectors/reselectSelectors";
import { getPersons } from "../../services/profileServices";
import { convertDobFormat } from "../../utils/dateUtils";
import {
    getLoadingRadialChartSegments,
    getRadialChartSegmentsFromIncomeParts,
    OTHER_ASSETS_CODE,
    RETIREMENT_INCOME_CODE,
    INCOME_GAP_CODE,
    GOVERNMENT_BENEFITS_CODE,
    HSA_CODE,
    calculateGoal
} from "../../utils/goalUtils";
import HiddenA11yWrapper from "../accessibility/HiddenA11yWrapper";
import Backdrop from "../backdrop";
import IntegratedGoalModal from "../goalModal/IntegratedGoalModal";

import RadialChartShadow from "./RadialChartShadow";

const getSelectedSegmentCode = (route) => {
    switch (route) {
        case RETIREMENT_INCOME_ROUTE:
        case HEALTHCARE_COSTS_ROUTE:
        case HOW_DO_I_COMPARE_ROUTE:
        case WHEN_CAN_I_RETIRE_ROUTE:
        case MAXIMIZER_ROUTE:
        case PRE_RETIREMENT_ROUTE:
            return RETIREMENT_INCOME_CODE;

        case SOCIAL_SECURITY_ROUTE:
            return GOVERNMENT_BENEFITS_CODE;

        case OTHER_ASSETS_ROUTE:
            return OTHER_ASSETS_CODE;

        case HSA_ROUTE:
            return HSA_CODE;

        case INCOME_GAP_ROUTE:
            return INCOME_GAP_CODE;
        default:
            return "";
    }
};

const LifetimeIncomeScoreCircle = () => {
    const dispatch = useDispatch();
    // shared values
    const translations = selectTranslations("paycheck");
    const showLoader = useSelector((state) => state.shared.showLoader);
    const readyToShowApplication = useSelector((state) => state.shared.readyToShowApplication);

    // projected income
    const projectedIncome = useSelector((state) => state.projectedIncome);
    const percentageOfGoal = projectedIncome.percentageOfGoal;
    const hubCalculated = projectedIncome.hubCalculationDone;

    // slider moving
    const sliderBeingMoved = useSelector((state) => state.slider).sliderBeingMoved;

    // app settings
    const period = useSelector(reselectTermPeriod);

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const mustEnterSalary = primaryPlan.salary.base === 0 && primaryPlan.terminated;
    const promptForSalary = !!primaryPlan.planRules.promptForSalary; // Needs to be boolean for PropTypes

    const participant = useSelector((state) => state.participant);
    const portion = useSelector((state) => state.participant.incomeGoal.value);

    const salary = useSelector((state) => state.participant.salary);
    const { base: baseSalary, salaryReminder, variable: otherVariableCompensation } = { ...salary };
    const dateOfBirth = participant.dateOfBirth;

    const spouse = useSelector((state) => state.spouse);

    const dob = convertDobFormat(dateOfBirth);
    const spouseDob = convertDobFormat(spouse.dateOfBirth);

    const additional = otherVariableCompensation ? otherVariableCompensation : 0;
    const totalComp = Number(baseSalary) + additional;

    const isPercent = isValueUnitsPercent(participant);
    const isPercentSpouse = isValueUnitsPercent(spouse);

    const spouseSalary = spouse.salary.base;
    const spousePortion = spouse.incomeGoal.value
        ? spouse.incomeGoal.value
        : spouse.incomeGoal.default;

    const confidenceLevel = useSelector(
        (state) => state.applicationSettings.projectionSettings.confidence
    );

    const primaryTabIndex = useSelector((state) => state.goalModal.primaryTabIndex);

    const {
        firstName: spouseName,
        age: spouseAge,
        retirementAge: desiredRetAge,
        gender: spouseGender
    } = spouse;

    const spouseObj = useMemo(
        () => ({
            spouseDob,
            spouseAge,
            spouseName,
            spouseSalary,
            spousePortion,
            isPercentSpouse,
            spouseGender
        }),
        [
            isPercentSpouse,
            spouseAge,
            spouseDob,
            spouseGender,
            spouseName,
            spousePortion,
            spouseSalary
        ]
    );

    /*** LOCAL STATE VALUES ****/
    const [goalSegments, setGoalSegments] = useState(getLoadingRadialChartSegments());
    const modalVisible = useSelector((state) => state.goalModal.showModal);

    const [canSave, setCanSave] = useState(promptForSalary);
    const [userGoal, setUserGoal] = useState(0);
    const [spouseGoal, setSpouseGoal] = useState(0);
    const [totalGoal, setTotalGoal] = useState(0);
    const [deleteSpouse, setDeleteSpouse] = useState(false);
    const [errorId, setErrorId] = useState([]);

    const [userTabActive, setUserTabActive] = useState(primaryTabIndex === "user");
    const [spouseDobError, setSpouseDobError] = useState("");
    const [userDobError, setUserDobError] = useState("");

    const [statePortion, setStatePortion] = useState(portion);
    const [stateIsPercent, setStateIsPercent] = useState(isPercent);
    const [statePeriod, setStatePeriod] = useState(period);
    const [stateSalary, setStateSalary] = useState(baseSalary);
    const [stateDesiredRetAge, setStateDesiredRetAge] = useState(desiredRetAge);
    const [stateSpouse, setStateSpouse] = useState({ ...spouseObj });
    const [additionalComp, setAdditionalComp] = useState(otherVariableCompensation);
    const [stateParticipantDob, setStateParticipantDob] = useState(dob);
    const [stateConfidenceLevel, setStateConfidenceLevel] = useState(confidenceLevel);
    const [isEnabled, setIsEnabled] = useState(false);
    const [spouseSaved, setSpouseSaved] = useState(spouse.exists);
    const [hdicClass, setHdicClass] = useState("");
    const { pathname } = useLocation();
    const csrf = useAtomValue(csrfAtom);

    const {
        data: personsData,
        isLoading: personsDataLoading,
        isError: personDataError
    } = useQuery({
        queryKey: [QUERY_KEYS.GET_PERSONS],
        queryFn: () => getPersons(csrf),
        enabled: !!csrf
    });

    // Prevents the chart goal from displaying 100% while percentage of goal is 99.5 - 99.99
    // and the rendered chart has a noticeable income gap segment still remaining.
    let percentageOfGoalRounded;
    if (Math.round(percentageOfGoal) === 100 && percentageOfGoal < 100) {
        percentageOfGoalRounded = Math.floor(percentageOfGoal);
    } else {
        percentageOfGoalRounded = Math.round(percentageOfGoal);
    }

    /*** END OF LOCAL STATE  ***/
    useEffect(() => {
        const profileLogoElmt = document.getElementById("itemNavUserList");
        const headerLogoElmt = document.getElementById("logwrapper");
        if (profileLogoElmt != null && headerLogoElmt != null) {
            if (modalVisible) {
                profileLogoElmt.classList.add("badge-opacity");
                headerLogoElmt.classList.add("badge-opacity");
            } else {
                profileLogoElmt.classList.remove("badge-opacity");
                headerLogoElmt.classList.remove("badge-opacity");
            }
        }
    }, [modalVisible]);

    useEffect(() => {
        setUserTabActive(primaryTabIndex === "user");
    }, [primaryTabIndex]);

    useEffect(() => {
        if (pathname === HOW_DO_I_COMPARE_ROUTE || pathname === HOW_DO_I_COMPARE_ROUTE) {
            setHdicClass("how-do-i-compare");
        } else {
            setHdicClass("");
        }
    }, [pathname]);

    const handleUserGoal = useCallback(
        (
            salary,
            spouseSalary,
            portion,
            spousePortion,
            period,
            isPercent,
            isPercentSpouse,
            additionalComp
        ) => {
            const additional = additionalComp ? additionalComp : 0;
            const totalComp = Number(salary) + additional;
            let spouseGoal = 0;
            let userGoal = 0;

            if (spouse && spouse.exists) {
                spouseGoal = calculateGoal(spousePortion, spouseSalary, period, isPercentSpouse);
                setSpouseGoal(spouseGoal);
            }
            userGoal = calculateGoal(portion, totalComp, period, isPercent);
            setUserGoal(userGoal);
            setTotalGoal(userGoal + spouseGoal);
        },
        [spouse]
    );

    const refreshState = useCallback(() => {
        setStatePortion(portion);
        setStateIsPercent(isPercent);
        setStatePeriod(period);
        setStateSalary(baseSalary);
        setStateDesiredRetAge(desiredRetAge);
        setStateSpouse({ ...spouseObj });
        setAdditionalComp(otherVariableCompensation);
        setStateParticipantDob(dob);
        setStateConfidenceLevel(confidenceLevel);
        setSpouseSaved(spouse.exists);
        setIsEnabled(false);

        handleUserGoal(
            baseSalary,
            spouseObj.spouseSalary,
            portion,
            spouseObj.spousePortion,
            period,
            isPercent,
            spouseObj.isPercentSpouse,
            otherVariableCompensation
        );

        //GA event
        if (percentageOfGoalRounded && percentageOfGoalRounded !== 0) {
            const payload = {
                goalValue: percentageOfGoalRounded
            };
            dispatch(dispatchEventBus("ApplicationEvent.init_complete_event.liat", {}, payload));

            dispatchAmplitude({
                eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
                selection: "ApplicationEvent.init_complete_event.liat",
                payload
            });
        }
    }, [
        baseSalary,
        confidenceLevel,
        desiredRetAge,
        dispatch,
        dob,
        handleUserGoal,
        isPercent,
        otherVariableCompensation,
        percentageOfGoalRounded,
        period,
        portion,
        spouse.exists,
        spouseObj
    ]);

    useEffect(() => {
        if (!_isEmpty(projectedIncome.incomeParts)) {
            const radialChartSegments = getRadialChartSegmentsFromIncomeParts(
                projectedIncome.incomeParts,
                true
            );
            setGoalSegments(radialChartSegments);
        }
    }, [projectedIncome]);

    // Depending on load time for GHID, state can be populated with initial 0 values
    // Refresh state when readyToShowApplication is true
    useEffect(() => {
        refreshState();
    }, [readyToShowApplication, refreshState]);

    useEffect(() => {
        setStateSpouse((prevStateSpouse) => ({
            ...prevStateSpouse,
            spouseSalary: spouse.salary.base
        }));
    }, [spouse.salary]);

    const updateSalaryReminder = () => {
        return dispatch(updateParticipantSalaryReminder());
    };

    const callUpdateSalaryReminder = () => {
        if (salaryReminder) {
            updateSalaryReminder();
        }
    };

    useEffect(() => {
        let calcSpouseGoal = 0;

        if (spouse && spouse.exists) {
            calcSpouseGoal = calculateGoal(spousePortion, spouseSalary, period, isPercentSpouse);
            setSpouseGoal(calcSpouseGoal);
        }

        const participantGoal = calculateGoal(portion, totalComp, period, isPercent);
        setUserGoal(participantGoal);

        let totalGoal =
            spouse.exists && !deleteSpouse ? participantGoal + calcSpouseGoal : participantGoal;

        totalGoal = Math.round(totalGoal);

        setTotalGoal(totalGoal);
    }, [
        deleteSpouse,
        isPercent,
        isPercentSpouse,
        period,
        portion,
        readyToShowApplication,
        spouse,
        spouse.exists,
        spousePortion,
        spouseSalary,
        totalComp
    ]);

    /**
     * Fires when user hits cancel button
     * Switch back to user tab
     * Remove error states from all corresponding elements
     */
    const handleClose = (reset = true) => {
        const closeGoalModalEvent = new CustomEvent("modal-close");
        let el;

        for (let i = 0; i < errorId.length; i++) {
            el = document.getElementById(errorId[i]);

            if (el) {
                el.classList.remove("has-error");
            }
        }

        callUpdateSalaryReminder();

        dispatch(setModalVisible(!modalVisible));
        setCanSave(false);

        setErrorId([]);
        dispatch(tabChange("user"));
        setSpouseDobError("");
        setUserDobError("");
        setIsEnabled(false);
        setDeleteSpouse(false);

        eventBus.dispatch(mtrGoalModalEvents.CANCEL_CHANGES, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: mtrGoalModalEvents.CANCEL_CHANGES
        });

        if (reset) {
            refreshState();
        }

        window.dispatchEvent(closeGoalModalEvent);
    };

    /**
     * Toggle the goal modal on/off when chart is clicked
     */
    const handleClick = () => {
        if (mustEnterSalary) {
            return;
        }
        if (!modalVisible) {
            dispatch(setModalVisible(!modalVisible));
        } else {
            handleClose();
        }

        eventBus.dispatch(mtrGoalModalEvents.TOGGLE_MODAL, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.TOGGLE_EVENT,
            selection: mtrGoalModalEvents.TOGGLE_MODAL
        });
    };

    const handleBackdropClick = () => {
        if (mustEnterSalary || showLoader) {
            return;
        }
        handleClose();
        eventBus.dispatch(mtrGoalModalEvents.TOGGLE_MODAL, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.TOGGLE_EVENT,
            selection: mtrGoalModalEvents.TOGGLE_MODAL
        });
    };

    const eventBus = {
        dispatch: (payload) => {
            dispatch(dispatchEventBus(payload));
        }
    };
    const showBackdrop = modalVisible && <Backdrop handleBackdropClick={handleBackdropClick} />;

    const selectedSegmentCode = getSelectedSegmentCode(pathname);

    const progressClass = modalVisible ? "active" : "";

    const emptyGoalSegments = _cloneDeep(goalSegments).map((segment) => {
        if (segment.code === INCOME_GAP_CODE) {
            segment.percent = 100;
        } else {
            segment.percent = 0;
        }

        return segment;
    });

    const getDescription = () => {
        const value = percentageOfGoalRounded;
        const text = translations.ofMyGoal;
        return !hubCalculated ? `- ${text}` : `${value}% ${text}`;
    };

    if (personsDataLoading) {
        return <Loader />;
    }

    const isEnrolled = !personDataError && !!csrf ? personsData[0].isEnrolled : false;

    return (
        <div id="my-goal-chart">
            <div id="progress-to-goal" className={`${progressClass}`} data-paycheck={hdicClass}>
                {showBackdrop}
                <div className="radial-chart-and-shadow-wrapper">
                    {!hubCalculated && (
                        <RadialChartShadow
                            wrapperClass="progress-to-goal-radial-chart-wrapper"
                            segments={emptyGoalSegments}
                            handleClick={handleClick}
                            donutHoleClass="radial-chart-inner--white"
                            arcAnimationDuration="2s"
                            arcAnimationDelay="0.5"
                            strokeWidth={16}
                            altText={translations.altText}
                            description={getDescription()}
                        >
                            <text fill="#002157" x="50%" y="49%" className="lis-score-color--black">
                                <tspan className={"score"}>—</tspan>
                            </text>
                            <text
                                className="goal-subtext-of-goal progress-to-goal-chart-action"
                                x="50%"
                                y="60%"
                                fill="#0047bb"
                                style={{ textDecoration: "none" }}
                            >
                                {translations.ofMyGoal}
                            </text>
                        </RadialChartShadow>
                    )}
                    {hubCalculated && (
                        <RadialChartShadow
                            wrapperClass="progress-to-goal-radial-chart-wrapper"
                            segments={goalSegments}
                            handleClick={handleClick}
                            donutHoleClass="radial-chart-inner--white"
                            selectedSegmentCode={selectedSegmentCode}
                            arcAnimationDuration={sliderBeingMoved ? ".25s" : "1s"}
                            arcAnimationDelay="0"
                            strokeWidth={16}
                            altText={translations.altText}
                            description={getDescription()}
                        >
                            <HiddenA11yWrapper
                                id={ACCESSIBILITY.MY_GOAL["target-me-percentage-value"]}
                            >
                                {percentageOfGoalRounded} % {translations.ofMyGoal}
                            </HiddenA11yWrapper>
                            <text fill="#002157" x="52%" y="50%" className="lis-score-color--black">
                                <tspan
                                    className={
                                        "score" +
                                        (percentageOfGoalRounded > 99 ? " three-digits" : "")
                                    }
                                >
                                    {percentageOfGoalRounded}
                                </tspan>
                                <tspan className="percent-sign" dx="3px" dy="-1%">
                                    %
                                </tspan>
                            </text>
                            <text
                                className="goal-subtext-of-goal progress-to-goal-chart-action"
                                x="50%"
                                y="60%"
                                fill="#0047bb"
                                style={{ textDecoration: "none" }}
                            >
                                {translations.ofMyGoal}
                            </text>
                        </RadialChartShadow>
                    )}
                    {!isUndefinedOrNull(baseSalary) && (
                        <IntegratedGoalModal
                            additionalComp={{ value: additionalComp, set: setAdditionalComp }}
                            callUpdateSalaryReminder={callUpdateSalaryReminder}
                            canSave={canSave}
                            errorId={errorId}
                            eventBus={eventBus}
                            deleteSpouse={deleteSpouse}
                            handleClose={handleClose}
                            handleUserGoal={handleUserGoal}
                            isEnrolled={isEnrolled}
                            isEnabled={{ value: isEnabled, set: setIsEnabled }}
                            isLoading={showLoader}
                            isPercent={isPercent}
                            portionValue={portion}
                            setCanSave={setCanSave}
                            setDeleteSpouse={setDeleteSpouse}
                            setErrorId={setErrorId}
                            setSpouseGoal={setSpouseGoal}
                            showModal={modalVisible}
                            spouseDobError={{ value: spouseDobError, set: setSpouseDobError }}
                            spouseGoal={spouseGoal}
                            spouseSaved={{ value: spouseSaved, set: setSpouseSaved }}
                            stateConfidenceLevel={{
                                value: stateConfidenceLevel,
                                set: setStateConfidenceLevel
                            }}
                            stateDesiredRetAge={{
                                value: stateDesiredRetAge,
                                set: setStateDesiredRetAge
                            }}
                            stateIsPercent={{ value: stateIsPercent, set: setStateIsPercent }}
                            stateParticipantDob={{
                                value: stateParticipantDob,
                                set: setStateParticipantDob
                            }}
                            statePeriod={{ value: statePeriod, set: setStatePeriod }}
                            statePortion={{ value: statePortion, set: setStatePortion }}
                            stateSalary={{ value: stateSalary, set: setStateSalary }}
                            stateSpouse={{ value: stateSpouse, set: setStateSpouse }}
                            totalGoal={totalGoal}
                            toggleProgressModal={() => dispatch(setModalVisible(!modalVisible))}
                            userDobError={{ value: userDobError, set: setUserDobError }}
                            userGoal={userGoal}
                            userTabActive={{ value: userTabActive, set: setUserTabActive }}
                            dob={dob}
                            spouseDob={spouseDob}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default LifetimeIncomeScoreCircle;
