import React from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import PropTypes from "prop-types";

import { formatUsDollarAmountNoDecimals } from "../../utils/currencyUtils";
import { decimalToPercent } from "../../utils/numberUtils";

const Message = ({
    healthConditions,
    totalHealthCareExpenses,
    projectedIncome,
    toggleModal,
    termLabel,
    idForA11y
}) => {
    const translations = selectTranslations("healthCareCosts")?.summaryMessage;

    const getPercent =
        projectedIncome === 0 ? 0 : decimalToPercent(totalHealthCareExpenses / projectedIncome);

    const estimatePercentage = StringUtil.supplant(translations.sentenceFragmentPercentage, {
        estimatedPercent: getPercent
    });
    const formattedHealthExpenses = formatUsDollarAmountNoDecimals(totalHealthCareExpenses);

    const estimatedCost = StringUtil.supplant(translations.sentenceFragmentDollarAmount, {
        estimatedCost: formattedHealthExpenses,
        termLabel
    });

    let sentenceFragmentA = translations.noHealthConditions.sentenceFragmentA;
    let sentenceFragmentLink = translations.noHealthConditions.sentenceFragmentLink;

    // determine which message based on pre-existing conditions
    const hasPreSelectedConditions = healthConditions.some(
        (condition) => condition.isPreselected === true
    );

    if (hasPreSelectedConditions) {
        sentenceFragmentA = translations.healthConditions.sentenceFragmentA;
        sentenceFragmentLink = translations.healthConditions.sentenceFragmentLink;
    }

    return (
        <div className="projected-health-care-costs-message-summary" data-testid="message">
            <p className="health-care-costs-message" id={idForA11y}>
                <span data-testid="sentence-Fragment-A">{sentenceFragmentA}</span>
                <a
                    onClick={toggleModal}
                    tabIndex="2"
                    onKeyDown={(e) => {
                        handleKeyboardDownEvent({
                            event: e,
                            keys: keyboardInteractions.button,
                            output: toggleModal
                        });
                    }}
                    role="button"
                >
                    {" "}
                    <span data-testid="sentence-Fragment-link">{sentenceFragmentLink}</span>
                </a>
                {translations.sentenceFragmentEstimatePercentage}
                <span className="health-care-summary-percentage" data-testid="estimate-percentage">
                    {estimatePercentage}
                </span>
                {estimatedCost}
            </p>
        </div>
    );
};
Message.propTypes = {
    healthConditions: PropTypes.array,
    totalHealthCareExpenses: PropTypes.number,
    projectedIncome: PropTypes.number,
    toggleModal: PropTypes.func,
    termLabel: PropTypes.string,
    idForA11y: PropTypes.string
};
export default Message;
