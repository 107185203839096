import React, { useState, useEffect, MouseEventHandler } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useAtomValue } from "jotai";

import { setNavigatedToMaxDashboard } from "../../actions/investments/investmentsActions";
import { dispatchAmplitude, dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import { hasRsgServicesAtom } from "../../atoms/atoms";
import EventBusEvents from "../../events/eventBusEvents";
import { useSelector, useDispatch } from "../../hooks/use-redux-actions";
import { initialize } from "../../middleware/initializationMiddleware.js";
import CurrencyFormatter from "../currencyFormatter/CurrencyFormatter";

const MAXIMIZER = "maximizer";
const amplitudeEvent = {
    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
    event_properties: {
        selection: EventBusEvents.PAYCHECK.TIMETAP_LINK_CLICKED
    }
};

const set415Limit = (get415Limit: number | undefined) => {
    if (get415Limit) {
        return get415Limit;
    }
    return 0;
};

interface IntegratedIncomeGapProps {
    get415Limit?: number;
    getContribSliderUrl: string;
    isManagedAccountsUser?: boolean;
    isMaximizerAvailable?: boolean;
    managedAccountsName?: string;
    nextPayrollYear: number;
    routeFrom?: string;
    showEmulatorMessage: boolean;
    throwPaeError: MouseEventHandler<HTMLAnchorElement>;
}

const IntegratedIncomeGap = ({
    routeFrom,
    managedAccountsName = "",
    get415Limit,
    getContribSliderUrl,
    isManagedAccountsUser,
    showEmulatorMessage,
    isMaximizerAvailable = false,
    throwPaeError,
    nextPayrollYear
}: IntegratedIncomeGapProps) => {
    const routeFromMaximizer = routeFrom === MAXIMIZER;
    const closeIncomeGapTranslations = selectTranslations("closeIncomeGap");
    const incomeGapTabTranslations = selectTranslations("incomeGapTab");
    const managedAccountsNameCleaned = managedAccountsName
        ? String(managedAccountsName).indexOf("<C>") > -1
            ? managedAccountsName.replace("<C>", "\u00A9")
            : String(managedAccountsName).indexOf("<TM>") > -1
              ? managedAccountsName.replace("<TM>", "\u2122")
              : managedAccountsName
        : managedAccountsName;
    const colWidth = routeFromMaximizer ? "col-md-12 col-sm-12" : "col-md-9 col-sm-9";

    const { empowerCrossSellConsent } = globalThis.integratedEligibility;
    const hasRsgServices = useAtomValue(hasRsgServicesAtom);

    const [clickedMaxLink, setClickedMaxLink] = useState(false);
    const navigatedToMaxDashboard = useSelector(
        (state) => state.investments.navigatedToMaxDashboard
    );

    const txnAccess = useSelector((state) => state.shared.txnAccess);
    const dispatch = useDispatch();

    useEffect(() => {
        if (clickedMaxLink) {
            setClickedMaxLink(false);
            dispatch(setNavigatedToMaxDashboard(true));
            window.location.href = "#/max-dashboard";
        }
    }, [clickedMaxLink, dispatch]);

    useEffect(() => {
        if (navigatedToMaxDashboard) {
            dispatch(setNavigatedToMaxDashboard(false));
            dispatch(initialize());
        }
    });

    const renderContributionLink = () => {
        if (getContribSliderUrl) {
            return (
                <a
                    className="btn btn-primary pull-right btn-mobile"
                    href={getContribSliderUrl}
                    data-testid="contribute-link"
                >
                    {closeIncomeGapTranslations.linkOutApple.link}
                </a>
            );
        }
    };

    const handleLinkClick = () => {
        dispatch(dispatchEventBus(EventBusEvents.PAYCHECK.TIMETAP_LINK_CLICKED));
        dispatch(dispatchAmplitude(amplitudeEvent));
    };

    return (
        <>
            {isManagedAccountsUser ? (
                <div
                    className="close-income-gap row"
                    id="redwood-close-income-gap-tab"
                    role="tabpanel"
                    data-testid="integrated-income-gap-mau"
                >
                    <div className="col-md-2">
                        <div className="income-gap-logo"></div>
                    </div>
                    <div className="close-income-gap-description close-income-gap-content col-md-10">
                        <h4>
                            {incomeGapTabTranslations.managedAccountsMessage.title1}{" "}
                            <span data-testid="managed-account-name">
                                {managedAccountsNameCleaned}
                            </span>
                        </h4>
                    </div>
                    <div className="close-income-gap-description col-md-10">
                        <p>
                            {incomeGapTabTranslations.managedAccountsMessage.part1}
                            {showEmulatorMessage ? (
                                <a onClick={throwPaeError} data-testid="dashboard-link">
                                    {incomeGapTabTranslations.managedAccountsMessage.dashboardLink}
                                </a>
                            ) : (
                                <a
                                    onClick={() => {
                                        setClickedMaxLink(true);
                                    }}
                                >
                                    {incomeGapTabTranslations.managedAccountsMessage.dashboardLink}
                                </a>
                            )}
                            {incomeGapTabTranslations.managedAccountsMessage.part2}
                        </p>
                    </div>
                </div>
            ) : (
                <div
                    className="close-income-gap row"
                    id="tab-content"
                    role="tabpanel"
                    data-testid="integrated-income-gap"
                >
                    {!routeFromMaximizer && (
                        <div className="col-md-2 col-sm-2">
                            <div className="income-gap-logo"></div>
                        </div>
                    )}
                    <div className={`close-income-gap-description ${colWidth}`}>
                        {isMaximizerAvailable && routeFromMaximizer ? (
                            <p className="slide-description">
                                {closeIncomeGapTranslations.takeActionAppleBeginning}{" "}
                                {nextPayrollYear} {closeIncomeGapTranslations.takeActionAppleEnd}
                            </p>
                        ) : (
                            <>
                                <h4 data-testid="lessengap-header">
                                    {" "}
                                    {closeIncomeGapTranslations.lessenGapMessage.header}{" "}
                                </h4>

                                <p className="slide-description" data-testid="lessengap-msg">
                                    {closeIncomeGapTranslations.lessenGapMessage.message}
                                </p>
                            </>
                        )}

                        {isMaximizerAvailable && routeFromMaximizer ? (
                            /* Apple specific  */
                            <div className="col-md-12">
                                <ul data-testid="block-apple">
                                    <li>
                                        {closeIncomeGapTranslations.secondBlockApple.description}
                                    </li>
                                    <li>
                                        {
                                            closeIncomeGapTranslations.thirdBlockApple
                                                .descriptionBeginning
                                        }
                                        {nextPayrollYear}{" "}
                                        {closeIncomeGapTranslations.thirdBlockApple.descriptionEnd}
                                        <CurrencyFormatter
                                            amount={set415Limit(get415Limit)}
                                            precision={0}
                                        />
                                        .
                                    </li>
                                    <li>{closeIncomeGapTranslations.fourthBlockApple}</li>
                                </ul>

                                <p>{closeIncomeGapTranslations.variableApple}</p>

                                {renderContributionLink()}
                            </div>
                        ) : (
                            <div className="close-income-gap-content col-md-12">
                                <div className="row">
                                    <div className="close-income-gap-pod">
                                        <ul className="list-group">
                                            <li>{closeIncomeGapTranslations.target1}</li>
                                            <li>{closeIncomeGapTranslations.target2}</li>
                                            <li>{closeIncomeGapTranslations.target3}</li>
                                            <li>{closeIncomeGapTranslations.target4}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                        {hasRsgServices &&
                            empowerCrossSellConsent &&
                            txnAccess.accountOpenEnabled && (
                                <a
                                    onClick={handleLinkClick}
                                    className="wellness-assistance-link"
                                    href="https://fwconsult.empowermytime.com/#/"
                                    target="_blank"
                                    data-testid="need-assitance"
                                    rel="noreferrer"
                                >
                                    {closeIncomeGapTranslations.wellnessAssistance}
                                </a>
                            )}
                    </div>
                </div>
            )}
        </>
    );
};

export default IntegratedIncomeGap;
