import React from "react";

import { useSelector } from "react-redux";

import ReferenceData from "../../constants/ReferenceData";

import { ageTickerValues, checkAgeIndx } from "./helpers";

const UPDATE_STYLE = {
    width: "14%"
};

const AgeTicker = () => {
    const participant = useSelector((state) => state.participant);
    const ageRange = ageTickerValues(participant.wcir.retireAge);

    const renderRow = (list) => {
        return list.map((item, index) => {
            return (
                <li
                    key={item + index} //eslint-disable-line
                    style={UPDATE_STYLE}
                    className={
                        participant.wcir.retireAge === ReferenceData.MIN_RETIREMENT_AGE ||
                        participant.wcir.retireAge === ReferenceData.MAX_RETIREMENT_AGE
                            ? checkAgeIndx(participant.wcir.retireAge, index)
                            : ""
                    }
                >
                    <a>{item}</a>
                </li>
            );
        });
    };

    return (
        <div className="timeline-container" data-testid="age-ticker">
            <ol>{renderRow(ageRange)}</ol>
        </div>
    );
};

export default AgeTicker;
