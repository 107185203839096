import React, { useCallback, useState, memo } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { fixedPointNotationNumber } from "gw-shared-components";
import { Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { dispatchAmplitude, dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import Deferrals from "../../business/deferralsLogic/deferrals";
import AddDeferralModal from "../addDeferralModal/AddDeferralModal";
import { CsorModal } from "../modals/index";

import SummarySlider from "./components/SummarySlider";
import SummarySliderBreakdown from "./containers/SummarySliderBreakdown";
import { MultiSliderEvents } from "./MultiSliderEvents";

const MultiSlider = () => {
    const dispatch = useDispatch();
    const participant = useSelector((state) => state.participant);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const activeDeferal = useSelector((state) => state.activeDeferral);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const deferrals = useSelector((state) => state.participant.deferrals);
    const [showCsorModal, setShowCsorModal] = useState(false);
    const [showAddDeferralModal, setShowAddDeferralModal] = useState(false);
    const [isFirstEvent, setIsFirstEvent] = useState(true);
    const [showSummaryExpansion, setShowSummaryExpansion] = useState(false);
    const displayMulti = primaryPlan.planRules.allowMultipleDeferrals;
    const deferralsLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);

    const isTerminatedOrSpecialCatchup =
        primaryPlan.terminated || deferralsLogic.hasSpecialCatchup(primaryPlan);

    const canShowSummary = participant.deferrals.length > 1 && displayMulti;

    const toggleSummary = useCallback(() => {
        if (showSummaryExpansion && isFirstEvent) {
            setIsFirstEvent(false);
        } else {
            setIsFirstEvent(true);
        }

        if (!isTerminatedOrSpecialCatchup && !isFirstEvent) {
            dispatch(
                dispatchEventBus(MultiSliderEvents.TOGGLE, this, {
                    expanded: !showSummaryExpansion
                })
            );
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_SLIDER,
                    event_properties: {
                        selection: MultiSliderEvents.TOGGLE,
                        expanded: !showSummaryExpansion
                    }
                })
            );
        }

        setShowSummaryExpansion(!showSummaryExpansion);
    }, [
        showSummaryExpansion,
        isFirstEvent,
        setIsFirstEvent,
        isTerminatedOrSpecialCatchup,
        dispatch,
        setShowSummaryExpansion
    ]);

    const getDeferralSummaryPrefix = deferralsLogic.getDeferralSummaryPrefix(activeDeferal);

    const summarySliderTotal = deferralSetupConfig.deferralSummaryTotal;

    const getConvertedSummaryValue = (value) => {
        return value * deferralsLogic.getOverallSummaryConversionFactor(activeDeferal);
    };
    const getSummaryDisplayTotal = () => {
        const value = fixedPointNotationNumber(summarySliderTotal, 2);
        return getConvertedSummaryValue(value);
    };
    const summarySliderSuffix = deferralsLogic.getDeferralSummarySuffix(activeDeferal);
    const availableContributionTypes = participant.availableDeferrals || [];
    const availableDeferrals = deferralsLogic.getNewAvailableDeferrals(availableContributionTypes);

    const handleSetShowCsorModal = useCallback(() => {
        setShowCsorModal(true);
    }, [setShowCsorModal]);

    return (
        <div className="multiSlider">
            {showCsorModal && (
                <CsorModal show={showCsorModal} closeModal={() => setShowCsorModal(false)} />
            )}
            {true && (
                <AddDeferralModal
                    visible={showAddDeferralModal}
                    closeModal={setShowAddDeferralModal}
                    setShowExpansion={setShowSummaryExpansion}
                    availableContributionTypes={availableContributionTypes}
                />
            )}
            {canShowSummary && (
                <SummarySlider
                    showSummaryExpansion={showSummaryExpansion}
                    setShowCsorModal={() => setShowCsorModal(true)}
                    toggleSummary={toggleSummary}
                    deferralSummaryPrefix={getDeferralSummaryPrefix}
                    summarySliderSuffix={summarySliderSuffix}
                    summaryDisplayTotal={getSummaryDisplayTotal}
                    summarySliderTotal={summarySliderTotal}
                    deferralsLogic={deferralsLogic}
                    getConvertedSummaryValue={getConvertedSummaryValue}
                    isTerminatedOrSpecialCatchup={isTerminatedOrSpecialCatchup}
                    handleShowAddDeferralModal={setShowAddDeferralModal}
                    availableDeferrals={availableDeferrals}
                />
            )}
            <div>
                <Collapse in={showSummaryExpansion || !canShowSummary}>
                    <div id="summary-slider-collapse">
                        <SummarySliderBreakdown
                            canShowSummary={canShowSummary}
                            showSummaryExpansion={showSummaryExpansion}
                            setShowCsorModal={handleSetShowCsorModal}
                            handleShowAddDeferralModal={setShowAddDeferralModal}
                            availableDeferrals={availableDeferrals}
                        />
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default memo(MultiSlider);
