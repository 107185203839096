import { SET_MANAGED_ACCOUNTS_PROGRAM_NAME } from "../actions/managedAccounts";
import { getManagedAccountsProgramName } from "../services/managedAccountsProgramNameService";

export const loadManagedAccountsProgramName = () => {
    return (dispatch, getState) => {
        const state = getState();

        return getManagedAccountsProgramName(state.participant.investorId)
            .then((response) => {
                return dispatch({
                    type: SET_MANAGED_ACCOUNTS_PROGRAM_NAME,
                    payload: response
                });
            })
            .catch(() => {
                // silently fail.   Other assets in ma-ui will be passed the null values from the
                // applicationSettingsReducer.managedAccountsProgramNameData, which mimics what angular does today.
            });
    };
};
