import React from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import currencyFormatter from "currency-formatter";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { mtrGoalModalEvents } from "../../events/mtrGoalModalEvents";
import { isValueUnitsPercent } from "../../selectors/participantSelectors";
import { reselectTermPeriod } from "../../selectors/reselectSelectors";
import PaycheckHeader from "../../shared/PaycheckHeader";
import { calculateGoal } from "../../utils/goalUtils";

const PreRetireeHeader = ({ toggleModal, setActiveTab }) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("preRetiree");
    const customMsg = selectTranslations("retirementIncome").srHeaderUpdatedMessage.replace(
        "{title}",
        translations.header.message6
    );

    const spouse = useSelector((state) => state.spouse);
    const participant = useSelector((state) => state.participant);

    const period = useSelector(reselectTermPeriod);
    const age = participant.adjustedLEAge;
    const percentOfGoal = parseFloat(participant.incomeGoal.value) || 0;

    const salary = participant.salary.total;
    const isPercent = isValueUnitsPercent(participant);
    const hasSpouse = spouse.exists;

    let goal = calculateGoal(percentOfGoal, salary, period, isPercent);

    if (hasSpouse) {
        const spouseSalary = spouse.salary.total;
        const spousePercentOfGoal = parseFloat(spouse.incomeGoal.value) || 0;
        const isPercentSpouse = isValueUnitsPercent(spouse);

        goal += calculateGoal(spousePercentOfGoal, spouseSalary, period, isPercentSpouse);
    }

    const income = currencyFormatter.format(goal, { locale: "en-US", precision: 0 });

    const openConsiderationsModal = () => {
        setActiveTab(0);
        dispatch(dispatchEventBus(EventBusEvents.PRERETIREE.PLAN_TO_AGE_MODAL_NUM_CLICK, this));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.PRERETIREE.PLAN_TO_AGE_MODAL_NUM_CLICK
        });
        toggleModal(true);
    };

    const openGoalModal = () => {
        dispatch(dispatchEventBus(mtrGoalModalEvents.TOGGLE_MODAL, this));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.TOGGLE_EVENT,
            selection: mtrGoalModalEvents.TOGGLE_MODAL
        });
        const actionableChartWrapper = document.querySelector(
            ".progress-to-goal-radial-chart-wrapper"
        );
        actionableChartWrapper.click();
        actionableChartWrapper.focus();
    };

    return (
        <React.Fragment>
            <div className="sr-only" role="alert">
                {customMsg}
            </div>
            <PaycheckHeader title={translations.header.message6} />
            <div className="pre-retiree-header">
                <h2 className="pre-retiree-body">
                    {translations.header.message1}
                    <strong className="pre-retiree-goal">
                        <a
                            onClick={openGoalModal}
                            tabIndex="0"
                            onKeyDown={(e) => {
                                handleKeyboardDownEvent({
                                    event: e,
                                    keys: keyboardInteractions.button,
                                    output: openGoalModal
                                });
                            }}
                        >
                            {StringUtil.supplant(translations.header.message2, {
                                income,
                                period
                            })}
                        </a>
                    </strong>
                    {translations.header.message3}
                    <strong className="pre-retiree-plan-to-age">
                        <a
                            className="pre-retiree-understanding"
                            onClick={openConsiderationsModal}
                            tabIndex="0"
                            onKeyDown={(e) => {
                                handleKeyboardDownEvent({
                                    event: e,
                                    keys: keyboardInteractions.button,
                                    output: openConsiderationsModal
                                });
                            }}
                        >
                            {StringUtil.supplant(translations.header.message4, { age })}
                        </a>
                    </strong>
                    {translations.header.message5}
                </h2>
            </div>
        </React.Fragment>
    );
};
PreRetireeHeader.propTypes = {
    toggleModal: PropTypes.func,
    setActiveTab: PropTypes.func
};
export default PreRetireeHeader;
