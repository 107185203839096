import { combineReducers } from "redux";

import applicationSettings from "./applicationSettings/applicationSettingsReducer";
import baseline from "./baseline/baselineReducer";
import activeDeferral from "./deferrals/activeDeferralReducer";
import deferralSetupConfig from "./deferrals/deferralSetupConfigReducer";
import enrollment from "./enrollment/enrollmentReducer";
import faq from "./faq/faqReducer";
import financialEngineForecast from "./financialEngineForecast/financialEngineForecastReducer";
import goalModal from "./goal/goalModalReducer";
import healthCare from "./healthcare/healthCareReducer";
import howDoICompare from "./howDoICompare/howDoICompareReducer";
import investments from "./investments/investmentsReducer";
import irsLimitsRefData from "./irsLimitsRefData/irsLimitsRefDataReducer";
import navigation from "./navigation/navigationReducer";
import navigationMenu from "./navigationMenu/navigationMenuReducer";
import nextSteps from "./nextStep/nextStepReducer";
import openNewAccountLink from "./openNewAccountLink/openNewAccountLinkReducer";
import otherAssets from "./otherAssets/otherAssetsReducer";
import participant from "./participant/participantReducer";
import paycheck from "./paycheck/paycheckReducer";
import primaryPlan from "./plans/primaryPlanReducer";
import projectedIncome from "./projectedIncome/projectedIncomeReducer";
import retirementSavings from "./retirementSavings/retirementSavingsReducer";
import reviewChanges from "./reviewChanges/reviewChangesReducer";
import shared from "./shared/sharedReducer";
import slider from "./slider/sliderReducer";
import socialSecurity from "./socialSecurity/socialSecurityReducer";
import spouse from "./spouse/spouseReducer";

export default combineReducers({
    activeDeferral,
    applicationSettings,
    baseline,
    deferralSetupConfig,
    enrollment,
    faq,
    financialEngineForecast,
    goalModal,
    healthCare,
    howDoICompare,
    investments,
    irsLimitsRefData,
    navigation,
    navigationMenu,
    nextSteps,
    otherAssets,
    openNewAccountLink,
    participant,
    paycheck,
    primaryPlan,
    projectedIncome,
    retirementSavings,
    reviewChanges,
    shared,
    socialSecurity,
    spouse,
    slider
});
