"use strict";
/**
 * @name app.common.model.domain.AssetAllocationEligibility
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the AssetAllocationEligibility domain model object.
 */

class AssetAllocationEligibility {
    constructor(config) {
        this.individualId = "";
        this.planId = "";
        this.planName = "";
        this.isEligible = true;
        this.templateId = "";
        this.restrictions = null;
        this.oneClickInfo = null;

        if (config) {
            this.individualId = config.individualId;
            this.planId = config.planId;
            this.planName = config.planName;
            this.isEligible = config.isEligible;
            this.templateId = config.templateId;
            this.restrictions = config.restrictions;
            this.oneClickInfo = config.oneClickInfo;
        }
    }
}

export default AssetAllocationEligibility;
