import React, { memo } from "react";

import { dispatchAmplitude, AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import { dispatchEventBus } from "../../../../actions/shared/sharedActionCreators";
import { WantToComponent } from "../../../../components/dashboard";
import EventBusEvents from "../../../../events/eventBusEvents";
import { useSelector, useDispatch } from "../../../../hooks/use-redux-actions";
import { useMaDetails, usePrimaryPlan } from "../../../../queries";
import AdvisoryServicesMessaging, { PROVIDER_FE } from "../../../AdvisoryServicesMessaging";
import PremierIRA from "../PremierIRA";

interface SideBarFaqTranslations {
    href: string;
    title: string;
}

interface RightSideBarProps {
    homePageEligibility: boolean;
    isPAE: boolean;
    showLoader: boolean;
    showWidgets: boolean;
}

const RightSideBar = (props: RightSideBarProps) => {
    const { homePageEligibility, showWidgets, isPAE, showLoader } = props;
    const dispatch = useDispatch();
    const txnAccess = useSelector((state) => state.shared.txnAccess);

    const sideBarFaqTranslations: SideBarFaqTranslations = selectTranslations("sideBarFaq");

    const title = sideBarFaqTranslations?.title || "";

    const { data: primaryPlan } = usePrimaryPlan();
    const { data: maDetails } = useMaDetails(primaryPlan);

    const provider = maDetails?.maProduct;
    const maEligible = !!provider;
    const maEnrolled = maDetails?.enrolledInManagedAccounts;

    let faqTarget = "_self";
    if (title.toLowerCase() === "retirement planner overview") {
        faqTarget = "_blank";
    }

    const handleFaqClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.FAQ.CLICKED
        });

        dispatch(dispatchEventBus(EventBusEvents.FAQ.CLICKED));
        window.open(sideBarFaqTranslations?.href, faqTarget);
    };

    return (
        <div className="right-side-bar d-flex">
            {!txnAccess.hideAdvisoryServicesMessaging &&
                provider !== PROVIDER_FE &&
                maEligible &&
                !maEnrolled && (
                    // If it is AMA, MTR, and PPT is not enrolled, then display this widget above "I want too..." menu.
                    <AdvisoryServicesMessaging
                        additionalClasses={"rounded-border bg-white advisory-messaging-container"}
                        showLoader={showLoader}
                        getDynamicMessaging={false}
                    />
                )}
            {homePageEligibility && <WantToComponent showLoader={showLoader} />}
            {provider && provider === PROVIDER_FE && (
                <AdvisoryServicesMessaging
                    additionalClasses={"rounded-border bg-white advisory-messaging-container"}
                    showLoader={showLoader}
                    getDynamicMessaging={true}
                />
            )}
            {((showWidgets && txnAccess.aceMessagingEnabled) || isPAE) && <PremierIRA />}
            {
                <a onClick={handleFaqClick} href="" target={faqTarget}>
                    {sideBarFaqTranslations?.title}
                </a>
            }
        </div>
    );
};

export default memo(RightSideBar);
