import { getPrimaryGrpAcct } from "core-ui/client/react/core/services/accountService";

import { SET_NAVIGATION_MENU } from "../actions/navigationMenu";
import { setParticipantIndividualId } from "../actions/participantDetails/participantDetailsActions";
import { setPrimaryPlanId } from "../actions/plans/primaryPlanActions";
import ReferenceData from "../constants/ReferenceData";

import { updateManagedAccounts } from "./investmentMiddleware";
import { loadNavigationMenu } from "./navigationMenuMiddleware";
import { getTxnAccessSettings, loadGroupTxnAccess } from "./sharedMiddleware";

interface PrimaryGroupAccountResponse {
    accountsDetailsLoaded?: boolean;
    database: string;
    groupId: string;
    individualId: string;
    planId: string;
    totalBal: number;
}

const setManagedAccountsData = (dispatch) => {
    dispatch(getTxnAccessSettings());
    getPrimaryGrpAcct().then(
        // TODO(EOC): Update the getPrimaryGrpAcct to clearly defined the expected response.
        (res: void | PrimaryGroupAccountResponse) => {
            if (res?.accountsDetailsLoaded) {
                const isTaxableAcct =
                    String(res.groupId).indexOf(ReferenceData.TAXABLE_ACCOUNT) > -1;
                const acctArr = String(res.groupId).split("-");
                const isTrsFlex = String(acctArr[1]).substring(0, 1) === "T";

                if (!isTaxableAcct) {
                    dispatch(loadNavigationMenu(res.groupId));
                } else {
                    dispatch({
                        type: SET_NAVIGATION_MENU,
                        payload: []
                    });
                }
                dispatch(setParticipantIndividualId(res.individualId));
                dispatch(setPrimaryPlanId(res.groupId));
                dispatch(
                    loadGroupTxnAccess(
                        res.individualId,
                        res.groupId,
                        "CXACR,WNHASM,CXAOE,WNOAIN,WNINSS"
                    )
                );

                const primaryPlan = {
                    ...res,
                    id: res.groupId
                };

                if (!isTrsFlex) {
                    // if primary plan is trsflex skip investment service call
                    // update primary plan managed accounts, investments managed accounts
                    dispatch(updateManagedAccounts(res.individualId, primaryPlan, true));
                }
            }
        },
        (error: Error) => {
            console.log("getPrimaryGrpAcct() :: ERROR :: ", error);
        }
    );
};

export default setManagedAccountsData;
