import { SET_INIT_DATA } from "../../actions/initData";
import {
    SET_INVESTMENTS_MANAGED_ACCOUNTS_OPTIONS,
    SET_EQUITY_MIX,
    SET_INVESTMENTS_MIX_WARNINGS,
    SET_NAVIGATED_TO_MAX_DASHBOARD
} from "../../actions/investments/investmentActionTypes";
import { findPrimaryPlan } from "../../utils/planUtils";

const initialState = {
    advisoryServicesOptions: {
        isCurrentInRiskBasedFund: false,
        currentAllocations: null,
        allowRiskBasedFunds: false,
        assetModelsAllowed: false,
        isCurrentInTargetDateFund: false,
        allowTargetDateFunds: false
    },
    equityMix: 0,
    individualOptions: {
        isPortfolioManagementAllowed: false,
        allowAllocationsUpdate: false,
        modelName: null,
        doTradeRestrictionsApply: null,
        doMoneyTypeGroupingsApply: false,
        currentAllocationModel: false,
        hasAllMoneyTypeGroup: false,
        disableEquityMix: false
    },
    equityMixWarnings: [],
    isRebalancerAllowed: false,
    managedAccountsOptions: {
        groupServiceRules: {
            allowNextGenAdvisoryServices: false,
            allowAdvice: false,
            allowFullManagedAccounts: false,
            allowGuidance: false
        },
        menuOptions: { FE: false, IBBOTSON: false },
        fundLineupStatus: null,
        managedAccountsProgramName: null,
        onlineAdviceProgramName: null,
        onlineGuidanceProgramName: null,
        advisoryServicesProgramName: null,
        advisoryServiceProviderCode: null,
        eligibleForManagedAccounts: null,
        eligibleForAdvice: null,
        enrolledInFullManagedAccounts: null,
        enrolledInAdvice: null,
        enrolledInGuidance: null,
        privacyUrl: null,
        disclosureUrl: null,
        disclosureText: null,
        termsOfServiceUrl: null,
        adviceUrl: null,
        balance: null
    },
    moneyTypeGroupings: [],
    noEquityMixData: false,
    partHasZeroBalance: false,
    partLevelMoneyTypeGroupings: false,
    navigatedToMaxDashboard: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INIT_DATA: {
            const primaryPlan = findPrimaryPlan(payload.user.plans);
            return {
                ...state,
                ...payload.data.investmentOverview,
                equityMix: primaryPlan.equityPercent * 100
            };
        }
        case SET_INVESTMENTS_MANAGED_ACCOUNTS_OPTIONS:
            return { ...state, managedAccountsOptions: payload };

        case SET_EQUITY_MIX:
            return { ...state, equityMix: payload };
        case SET_INVESTMENTS_MIX_WARNINGS:
            return { ...state, equityMixWarnings: payload };
        case SET_NAVIGATED_TO_MAX_DASHBOARD:
            return { ...state, navigatedToMaxDashboard: payload };
        default:
            return state;
    }
};
