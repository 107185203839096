type LoginLashHash = string | undefined;

const getLoginLastHash = (): LoginLashHash => {
    const loginLastHash = sessionStorage.getItem("participant-loginLastHash");

    if (loginLastHash) {
        return loginLastHash;
    }
};

export default getLoginLastHash;
