/*
 * Shared reducers, for common functionalities across the application
 */

import {
    GET_SCREEN_SIZE,
    SET_SCREEN_SIZE,
    SET_CURRENT_FAULT,
    SHOW_LOADER_MESSAGES,
    ON_INIT_LOAD,
    READY_TO_SHOW_APP,
    SHOW_LOADER,
    UPDATE_TRANSLATIONS,
    SET_TXN_ACCESS,
    SET_CURRENT_LOCATION,
    SET_LOAD_STATUS,
    SET_SECONDARY_LOADER,
    SET_OPEN_DEMIN_MODAL
} from "../../actions/shared/sharedActionTypes";

const initialState = {
    currentFault: {},
    priorFaults: [],
    initCalled: false,
    readyToShowApplication: false,
    showLoader: false,
    showLoaderMessages: false,
    txnAccess: {
        aceMessagingEnabled: false,
        addressUpdateForActiveEmployeeEnabled: false,
        addressUpdateForTermEmployeeEnabled: false,
        uploadDocumentsEnabled: false,
        accountOpenEnabled: false,
        is1Id: false,
        statementsAndDocuments: false,
        beneficiary: false,
        referralEnabled: false,
        showConsiderationsTab: false,
        allowDeminModal: false
    },
    txnAccessSet: false,
    currentLocation: null,
    loadStatus: {
        sidebarLoaded: false,
        compMeterLoaded: false,
        bottomWidgetsLoaded: false,
        insightsWidgetLoaded: false,
        aceBlockLoaded: false
    },
    showSecondaryLoader: false,
    openDeminModal: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ON_INIT_LOAD:
            return { ...state, initCalled: true };

        case READY_TO_SHOW_APP:
            return { ...state, readyToShowApplication: true };

        case GET_SCREEN_SIZE:
            return { ...state, screenSize: payload };
        case SET_SCREEN_SIZE:
            return { ...state, screenSize: payload };
        case SET_CURRENT_FAULT:
            return {
                ...state,
                currentFault: payload,
                priorFaults: [...state.priorFaults, payload]
            };
        case SHOW_LOADER:
            return { ...state, showLoader: payload };
        case SHOW_LOADER_MESSAGES:
            return { ...state, showLoaderMessages: payload };
        case UPDATE_TRANSLATIONS:
            return {
                ...state,
                translations: { ...state.translations, ...payload }
            };

        case SET_TXN_ACCESS:
            return { ...state, txnAccess: { ...state.txnAccess, ...payload }, txnAccessSet: true };

        case SET_CURRENT_LOCATION:
            return { ...state, currentLocation: payload };

        case SET_LOAD_STATUS:
            return { ...state, loadStatus: { ...state.loadStatus, ...payload } };

        case SET_SECONDARY_LOADER:
            return { ...state, showSecondaryLoader: payload };

        case SET_OPEN_DEMIN_MODAL:
            return { ...state, openDeminModal: payload };

        default:
            return state;
    }
};
