import axios, { spread } from "axios";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";
import { get as _get } from "lodash";

import { RetirementIncomeJsonUrls } from "../constants/RetirementIncomeJsonUrls";
import LoggerFactory from "../factories/LoggerFactory";
import { executeGetAll, executeGet } from "../utils/apiUtils";
import { getUrl, getStyleGuideUrl } from "../utils/urlUtils";

//-----------------------------------------------------------------------
// PROTECTED METHODS
//-----------------------------------------------------------------------

/**
 * Allows for a single entry point for getting both the assumptions for the FE Forecast modal and the FE
 * forecast data. If both are successful, a single object is passed back.
 *
 * @param isEnrolledInFullManagedAccounts - needed to get the proper methods and assumptions as they are slightly
 * different for enrolled and non-enrolled participants
 * @returns {*}
 */
export const getFinancialEngineForecast = (isEnrolledInFullManagedAccounts) => {
    const processResults = function (resolutions) {
        const returnData = {};
        returnData.assumptions = isEnrolledInFullManagedAccounts
            ? _get(resolutions, "assumptionsResult.data.member")
            : _get(resolutions, "assumptionsResult.data.nonMember");
        returnData.feData = _get(resolutions, "feDataResult.data.data.RetirementIncomeForecast");
        return returnData;
    };

    const dataUrl = getUrl("getFEData");
    const dataRequest = axios.get(dataUrl);

    const assumptionsUrl = getStyleGuideUrl(
        RetirementIncomeJsonUrls.URL_FE_FORECAST_ASSUMPTIONS_LOCAL,
        RetirementIncomeJsonUrls.URL_FE_FORECAST_ASSUMPTIONS_LIVE
    );
    const assumptionsRequest = axios.get(assumptionsUrl);

    return executeGetAll([dataRequest, assumptionsRequest], "getFinancialEngineForecastFault").then(
        spread((...responses) => {
            return processResults({
                feDataResult: responses[0],
                assumptionsResult: responses[1]
            });
        })
    );
};

export const getAdvisoryMessaging = (individualId, planId) => {
    const logger = LoggerFactory.getInstance("financialEngineService");

    const getAdvisoryMessagingResult = function (result) {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.info("getAdvisoryMessagingResult( Response is null. )");
            return null;
        } else {
            logger.info("getAdvisoryMessagingResult() for " + individualId + ", " + planId);

            return result.data;
        }
    };

    const params = {
        individualId: individualId,
        groupId: planId, // the primary plan's ID is the group ID
        pageCode: "homepage",
        treatment: "tile"
    };

    const url = StringUtil.supplant(getUrl("getAdvisoryMessaging"), params);

    return executeGet(url, "getAdvisoryMessagingFault")
        .then(getAdvisoryMessagingResult)
        .catch(() => {});
};
