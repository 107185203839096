import React, { memo, useCallback, useState } from "react";

import { ErrorBoundary, withProfiler } from "@sentry/react";
import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { get as _get, uniq as _uniq, uniqBy as _uniqBy, concat as _concat } from "lodash";
import { Collapse } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import {
    setShowPaycheckViewDetails,
    updatePayDetailsToolTips,
    setNoMatchesOrProfitSharing
} from "../../actions/paycheck/paycheckActions";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import {
    getEmployerMatchExistsForPlan,
    getProfitSharingExistsForPlan
} from "../../business/paycheck";
import NumberCounter from "../../components/numberCounter/numberCounter";
import PaycheckDetails from "../../components/paycheck/PaycheckDetails";
import { ACCESSIBILITY } from "../../constants/AccessibilityPaycheck";
import EventBusEvents from "../../events/eventBusEvents";
import { reselectTermPeriod } from "../../selectors/reselectSelectors";
import PaycheckHeader from "../../shared/PaycheckHeader";
import { usePrevious } from "../../utils/customHooks";
import HiddenA11yWrapper from "../accessibility/HiddenA11yWrapper";
import FallbackComponent from "../errorBoundary/Fallback";

import { usePaycheckRetirementYear } from "./hooks";

const sentryFallback = <FallbackComponent />;

const IntegratedPaycheck = () => {
    const dispatch = useDispatch();

    const translationsPaycheck = selectTranslations("paycheck");
    const translationsViewDetails = selectTranslations("viewDetailsToolTips");

    const projectedIncome = useSelector((state) => state.projectedIncome);
    const term = useSelector(reselectTermPeriod);

    const paycheck = useSelector((state) => state.paycheck);
    const {
        showPaycheckViewDetails: showIncomeDetails,
        plansWithEmployerFuture,
        plansWithMyFutureContributions,
        plansWithMyPastContributions,
        plansWithEmployerPast
    } = paycheck;

    const retirementYear = usePaycheckRetirementYear();

    const {
        employerPastAssets: otherAssetsForEmployerPast,
        employerFutureAssets: assetsWithEmployerFuture
    } = useSelector((state) => state.otherAssets);

    const [open, setOpen] = useState(false);
    const [showGATag, setShowGATag] = useState(true);

    const estimatedIncome = StringUtil.supplant(translationsPaycheck.estimatedIncomeNoColons, {
        term
    });

    const startingRetirementTitle = StringUtil.supplant(
        translationsPaycheck.startingRetirementTitle,
        {
            retirementYear
        }
    );

    const previousProjectedIncome = usePrevious(projectedIncome);

    const updatePaycheckDetailsTooltip = useCallback(
        (translations) => {
            const employerFuturePlans = _uniq(plansWithEmployerFuture);
            const employerFuture = [];
            let temp = "";
            let employerMatchExist = false;
            let profitSharingExists = false;

            let employerFutureContributions;

            for (let i = 0; i < employerFuturePlans.length; i++) {
                if (!employerFuturePlans[i].terminated) {
                    temp = employerFuturePlans[i].name;
                    employerMatchExist = getEmployerMatchExistsForPlan(i, employerFuturePlans[i]);
                    profitSharingExists = getProfitSharingExistsForPlan(i, employerFuturePlans[i]);

                    if (employerMatchExist) {
                        temp += " - " + translations.companyMatch;
                        dispatch(setNoMatchesOrProfitSharing(false));
                    }

                    if (profitSharingExists) {
                        if (employerMatchExist) {
                            temp += ", " + translations.profitSharing;
                        } else {
                            temp += " - " + translations.profitSharing;
                        }
                    }

                    employerFuture.push({
                        id: employerFuturePlans[i].id,
                        name: temp
                    });
                }
            }

            const myCurrentSavingPlans = _uniq(plansWithMyPastContributions);
            const myFutureSavingPlans = _uniq(plansWithMyFutureContributions);
            const employerPastContribution = _uniqBy(
                _concat(plansWithEmployerPast, otherAssetsForEmployerPast),
                "name"
            );

            employerFutureContributions = [].concat(employerFuture);
            employerFutureContributions = _uniqBy(
                _concat(employerFutureContributions, assetsWithEmployerFuture),
                "name"
            );

            const payload = {
                myCurrentSavingPlans: myCurrentSavingPlans,
                myFutureSavingPlans: myFutureSavingPlans,
                employerPastContribution: employerPastContribution,
                employerFutureContributions: employerFutureContributions,
                showMyCurrentSavingPlansTooltip: myCurrentSavingPlans.length > 0,
                showMyFutureSavingPlansTooltip: myFutureSavingPlans.length > 0,
                showEmployerPastContributionTooltip: employerPastContribution.length > 0,
                showEmployerFutureContributionsTooltip: employerFutureContributions.length > 0
            };

            dispatch(updatePayDetailsToolTips(payload));
        },
        [
            assetsWithEmployerFuture,
            dispatch,
            otherAssetsForEmployerPast,
            plansWithEmployerFuture,
            plansWithEmployerPast,
            plansWithMyFutureContributions,
            plansWithMyPastContributions
        ]
    );

    const handleIncomeClick = useCallback(() => {
        setOpen(!open);
        dispatch(setShowPaycheckViewDetails(!open));
        dispatch(dispatchEventBus(EventBusEvents.PAYCHECK.EST_INC_CLICK, { expanded: open }));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.PAYCHECK.EST_INC_CLICK,
            payload: open
        });
        if (
            (!showIncomeDetails || !open) &&
            translationsViewDetails &&
            plansWithMyPastContributions
        ) {
            updatePaycheckDetailsTooltip(translationsViewDetails.employerFuture);
        }
    }, [
        open,
        dispatch,
        showIncomeDetails,
        translationsViewDetails,
        plansWithMyPastContributions,
        updatePaycheckDetailsTooltip
    ]);

    const handleKeyDownIncomeClick = (event) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: handleIncomeClick
        });
    };

    const show = open || showIncomeDetails;
    const chevronIcon = show ? "fa-chevron-up" : "fa-chevron-down";

    if (projectedIncome && projectedIncome.total && showGATag) {
        const periodicity = estimatedIncome.split(" ");
        const payload = {
            incomeAmt: Math.round(projectedIncome.total),
            incomeRng: periodicity[1]
        };
        dispatch(dispatchEventBus("ApplicationEvent.init_complete_event_pay", {}, payload));

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "ApplicationEvent.init_complete_event_pay",
            payload
        });

        setShowGATag(false);
    }

    const customMsg = selectTranslations("retirementIncome")?.srHeaderUpdatedMessage?.replace(
        "{title}",
        startingRetirementTitle
    );

    return (
        <ErrorBoundary fallback={sentryFallback}>
            <PaycheckHeader title={startingRetirementTitle} />
            <div className="sr-only" role="alert">
                {customMsg}
            </div>

            <div
                className="monthly-retirement-income-amount"
                data-testid="integrated-paycheck"
                id="projected-income-tabpanel"
                role="tabpanel"
            >
                <p id="projected-income-label" data-testid="estimated-income">
                    {" "}
                    {estimatedIncome}{" "}
                </p>
                <div
                    role="button"
                    tabIndex="0"
                    data-bs-toggle="collapse"
                    aria-expanded={open}
                    onClick={handleIncomeClick}
                    onKeyDown={handleKeyDownIncomeClick}
                    aria-controls="paycheck-details-drop-down"
                    aria-labelledby="projected-income-label"
                    className="btn btn-link collapsible-link button-estimated-income hidden-xs"
                    id="estimated-income-number"
                >
                    {term.length > 0 && !!projectedIncome.total && (
                        <span>
                            <NumberCounter
                                key={
                                    "estimated_income_" +
                                    _get(previousProjectedIncome, "total") +
                                    projectedIncome.total
                                }
                                durationInMilliseconds={1000} //(sliderBeingMoved ? 250 :) to be added next release
                                isCurrency={true}
                                startingNumber={
                                    previousProjectedIncome ? previousProjectedIncome.total : null
                                }
                                endingNumber={projectedIncome.total}
                            />
                            <HiddenA11yWrapper
                                id={
                                    ACCESSIBILITY.ESTIMATED_MONTHLY_INCOME[
                                        "projected-income-monthly-value"
                                    ]
                                }
                            >
                                {Math.round(projectedIncome.total)}
                            </HiddenA11yWrapper>
                        </span>
                    )}
                    <i className={`fa-solid ${chevronIcon}`}></i>
                </div>
                <Collapse in={show}>
                    <div
                        id="paycheck-details-drop-down"
                        className="container-paycheck-details-drop-down"
                    >
                        <PaycheckDetails isMobileView={false} open={open} />
                    </div>
                </Collapse>
            </div>
        </ErrorBoundary>
    );
};

export default withProfiler(memo(IntegratedPaycheck), { name: "IntegratedPaycheck" });
