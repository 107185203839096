import React, { useState, useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useAtomValue } from "jotai";
import { noop as _noop } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { dispatchEventBus } from "../actions/shared/sharedActionCreators";
import { csrfAtom } from "../atoms/atoms";
import EventBusEvents from "../events/eventBusEvents";
import { useIsPrivileged } from "../hooks/useEmulatorType";
import { getReferral } from "../middleware/userMessagesMiddleware";
import { QUERY_KEYS } from "../queries/constants";
import { updateUserMessages } from "../services/userMessagesService";

const CustomerReferralButtons = {
    Copy: "customer-referral-copy",
    Email: "customer-referral-email"
};

const CustomerReferral = () => {
    const csrf = useAtomValue(csrfAtom);
    const { data: referral } = useQuery({
        queryKey: [QUERY_KEYS.GET_USER_MESSAGES],
        queryFn: () => getReferral(csrf),
        enabled: !!csrf
    });
    const privileged = useIsPrivileged();
    const referralTranslations = selectTranslations("customerReferral");
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const input = document.querySelector("input[name=referral]");

        input.focus();
        input.select();
    }, []);

    const buttonClick = (key, link) => {
        const servicePayload = {
            userMessageIds: [referral.userMessageId],
            actions: ["markClicked"]
        };

        switch (key) {
            case "COPY":
                window.navigator.clipboard.writeText(link);
                setShowTooltip(true);

                window.setTimeout(() => {
                    setShowTooltip(false);
                }, 2000);
                break;

            case "EMAIL":
                window.open(link, "_blank");
                break;
        }

        dispatchEventBus(EventBusEvents.REFERRAL[`${key}_CLICK`]);
        updateUserMessages(servicePayload);
    };

    const termsClick = () => {
        dispatchEventBus(EventBusEvents.REFERRAL.TERMS_CLICK);
    };

    const handleEnterPress = (e) => {
        if (e.key === "Enter") {
            const { key, url } =
                CustomerReferralButtons.Copy === e.currentTarget.name ? copyLinkObj : emailLinkObj;
            buttonClick(key, url);
        }
    };

    const copyLinkObj = referral ? referral.action.find((obj) => obj.key === "COPY") : {};
    const emailLinkObj = referral ? referral.action.find((obj) => obj.key === "EMAIL") : {};

    return (
        <div data-testid="customer-referral" className="pc-module-container customer-referral-page">
            <div className="customer-referral-page__header">
                <h1>{referral.title}</h1>
                <h3>{referral.explanation}</h3>
                <div className="customer-referral-page__header-svg"></div>
            </div>
            <div className="customer-referral-page__link-section">
                <div className="link-section__input">
                    <label htmlFor="referral" id="referralInputId">
                        {referral.resources[0].jsonContent.referralUrlLabel}
                    </label>
                    <input
                        data-testid="customer-referral-input"
                        name="referral"
                        type="text"
                        readOnly={true}
                        aria-readonly={true}
                        value={copyLinkObj.url}
                        aria-describedby="referralInputId"
                    />
                </div>
                <div className="link-section__buttons">
                    <OverlayTrigger
                        placement="bottom"
                        show={showTooltip}
                        overlay={
                            <Tooltip
                                data-testid="customer-referral-tooltip"
                                className="link-section__button-tooltip"
                                id="copyTooltipId"
                            >
                                {referralTranslations.tooltip}
                            </Tooltip>
                        }
                    >
                        <button
                            data-testid={CustomerReferralButtons.Copy}
                            onClick={() => buttonClick(copyLinkObj.key, copyLinkObj.url)}
                            onKeyDown={handleEnterPress}
                            aria-describedby="copyTooltipId"
                            name={CustomerReferralButtons.Copy}
                        >
                            {copyLinkObj.label}
                        </button>
                    </OverlayTrigger>
                    <button
                        className={privileged ? "is-disabled" : ""}
                        data-testid={CustomerReferralButtons.Email}
                        aria-disabled={privileged}
                        onClick={() => buttonClick(emailLinkObj.key, emailLinkObj.url)}
                        onKeyDown={!privileged ? handleEnterPress : _noop}
                        name={CustomerReferralButtons.Email}
                    >
                        {emailLinkObj.label}
                    </button>
                </div>
                <div className="link-section__guide">
                    <p>{referral.summary}</p>
                </div>
            </div>
            <div className="customer-referral-page__terms">
                <a
                    onClick={termsClick}
                    href={referral.resources[0].jsonContent.disclosures.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {referral.resources[0].jsonContent.disclosures.title}
                </a>
            </div>
        </div>
    );
};

export default CustomerReferral;
