import { ObjectUtil } from "gw-shared-components";
import { cloneDeep as _cloneDeep, each as _each } from "lodash";

import {
    setAdvisoryMessaging,
    setAdvisorySvcKey
} from "../actions/financialEngine/financialEngineActions";
import { SET_FINANCIAL_ENGINE_FORECAST } from "../actions/financialEngine/financialEngineForecastTypes";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import LoggerFactory from "../factories/LoggerFactory";
import {
    getFinancialEngineForecast,
    getAdvisoryMessaging
} from "../services/financialEngineService";

const logger = LoggerFactory.getInstance("financialEngineMiddleware");

export const loadFinancialEngineForecast = (isEnrolledInFullManagedAccounts) => {
    return (dispatch) => {
        return getFinancialEngineForecast(isEnrolledInFullManagedAccounts)
            .then((response) => {
                dispatch({
                    type: SET_FINANCIAL_ENGINE_FORECAST,
                    payload: response
                });
            })
            .catch((fault) => {
                dispatch(setCurrentFault({ ...fault, stayOnCurrentPage: true }));
            });
    };
};

export const loadAdvisoryMessaging = (
    individualId,
    planId,
    advisoryMessagingTranslations,
    displayForecast,
    enrolled
) => {
    return (dispatch) => {
        return getAdvisoryMessaging(individualId, planId)
            .then((response) => {
                if (response) {
                    logger.debug("load advisory messaging successful");
                    logger.debug(JSON.stringify(response));

                    let resultKey = null;

                    _each(response, (objVal, objKey) => {
                        resultKey = objKey;
                    });

                    if (!ObjectUtil.isUndefinedOrNull(resultKey)) {
                        if (!ObjectUtil.isUndefinedOrNull(response[resultKey])) {
                            const updatedTranslations = _cloneDeep(advisoryMessagingTranslations);

                            dispatch(setAdvisorySvcKey(resultKey));

                            updatedTranslations[resultKey] = _cloneDeep(response[resultKey]);

                            // Default to "image-shoes" class if "icon-" class not found
                            // Check if "image" field is still present; at some point it will be changed to "imageCode"
                            if (
                                !ObjectUtil.isUndefinedOrNull(updatedTranslations[resultKey].image)
                            ) {
                                if (
                                    String(updatedTranslations[resultKey].image).indexOf(
                                        "icon-"
                                    ) === -1
                                ) {
                                    updatedTranslations[resultKey].image = "image-shoes";
                                }
                            }
                            if (
                                !ObjectUtil.isUndefinedOrNull(
                                    updatedTranslations[resultKey].imageCode
                                )
                            ) {
                                if (
                                    String(updatedTranslations[resultKey].imageCode).indexOf(
                                        "icon-"
                                    ) === -1
                                ) {
                                    updatedTranslations[resultKey].image = "image-shoes";
                                } else {
                                    updatedTranslations[resultKey].image =
                                        updatedTranslations[resultKey].imageCode;
                                }
                            }

                            if (displayForecast) {
                                dispatch(loadFinancialEngineForecast(enrolled));
                            }

                            dispatch(
                                setAdvisoryMessaging({
                                    [resultKey]: updatedTranslations[resultKey]
                                })
                            );
                        }
                    }
                }
            })
            .catch((fault) => {
                dispatch(setCurrentFault({ ...fault, stayOnCurrentPage: true }));
            });
    };
};
