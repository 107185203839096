/******* HANDLE ALL PLANS *******/
export const SET_PRIMARY_PLAN = "SET_PRIMARY_PLAN";
export const SET_PRIMARY_PLAN_RULES_SALARY_REMINDER = "SET_PRIMARY_PLAN_RULES_SALARY_REMINDER";
export const SET_PRIMARY_PLAN_MANAGED_ACCOUNTS = "SET_PRIMARY_PLAN_MANAGED_ACCOUNTS";
export const SET_MAXIMIZER_IS_ONGOING = "SET_MAXIMIZER_IS_ONGOING";
export const SET_DEFERRAL_RESTRICTIONS = "SET_DEFERRAL_RESTRICTIONS";
export const SET_ALLOW_DEFERRAL = "SET_ALLOW_DEFERRAL";
export const SET_DEFERRAL_INFO = "SET_DEFERRAL_INFO";
export const UPDATE_PRIMARY_PLAN_SALARY = "UPDATE_PRIMARY_PLAN_SALARY";
export const SET_MANAGED_ACCOUNTS = "SET_MANAGED_ACCOUNTS";
export const SET_PRIMARY_PLAN_ID = "SET_PRIMARY_PLAN_ID";
