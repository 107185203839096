import {
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    MAXIMIZER_ROUTE,
    PRE_RETIREMENT_ROUTE,
    RETIREMENT_INCOME_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE
} from "../../../../routes";
import TABS from "../../../constants/Tabs";
import {
    canShowHDICView,
    canShowHealthCareView,
    canShowMaximizer,
    canShowPreRetirementView,
    canShowWhenCanIRetire
} from "../../../selectors/featureFlagSelectors";

export const getDesktopTabsRoutes = (participant, primaryPlan) => {
    const routes: string[] = [];
    const visibleTabs: string[] = [];

    routes.push(RETIREMENT_INCOME_ROUTE);
    if (canShowHealthCareView(participant, primaryPlan)) {
        visibleTabs.push(TABS.HEALTHCARE_COSTS);
        routes.push(HEALTHCARE_COSTS_ROUTE);
    }
    if (canShowHDICView(primaryPlan)) {
        visibleTabs.push(TABS.HOW_DO_I_COMPARE);
        routes.push(HOW_DO_I_COMPARE_ROUTE);
    }
    if (canShowPreRetirementView(participant, primaryPlan)) {
        visibleTabs.push(TABS.PRE_RETIREMENT);
        routes.push(PRE_RETIREMENT_ROUTE);
    }
    if (canShowWhenCanIRetire(participant, primaryPlan)) {
        visibleTabs.push(TABS.WHEN_CAN_I_RETIRE);
        routes.push(WHEN_CAN_I_RETIRE_ROUTE);
    }
    if (canShowMaximizer(primaryPlan)) {
        visibleTabs.push(TABS.MAXIMIZER);
        routes.push(MAXIMIZER_ROUTE);
    }

    return {
        routes,
        visibleTabs
    };
};
