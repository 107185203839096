import { setInvestmentsManagedAccountsOptions } from "../actions/investments/investmentsActions";
import { setPrimaryPlanManagedAccounts } from "../actions/plans/primaryPlanActions";
import { getManagedAccounts } from "../services/investmentService";

export const updateManagedAccounts = (individualId, primaryPlan, hideLIAT) => {
    return (dispatch) => {
        return getManagedAccounts(individualId, primaryPlan).then((result) => {
            if (result !== null) {
                dispatch(setPrimaryPlanManagedAccounts(result));
            }
            //@TODO SET INVESTMENT TABS (see investmentTabsService.setMyInvestmentTabs)

            if (hideLIAT && result && result !== null) {
                dispatch(setInvestmentsManagedAccountsOptions(result));
            }
        });
    };
};
