import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";

globalThis.integratedEligibility = {
    csrf: null, //TODO(EOC): Should be able to remove these references ??
    deminAccountForWithdrawalLanding: "",
    eligibilityLoaded: false, //TODO(EOC): Should be able to remove these references
    empowerCrossSellConsent: false,
    groupDetails: {},
    hasRetailAccount: false,
    homePageEligibility: false,
    integrated: true, //TODO(EOC): Should be able to remove these references
    integratedCSS: "",
    integratedJS: "",
    isEmulator: false,
    isRetail: false,
    notifications: [],
    postOriginUrl: null,
    samlPosted: false, //TODO(EOC): Should be able to remove these references
    samlToken: null, //TODO(EOC): Should be able to remove these references
    samlTokenError: { error: false, message: "", code: "" }, //TODO(EOC): Should be able to remove these references
    secDeminModal: [],
    setEmulator: false,
    showCommunicationPreferences: false,
    showLIAT: true,
    showWidgets: false,
    success: false,
    userGuid: null,
    hasRsgServices: false,
    type: "", //TODO(EOC): ??
    savingsPlannerEnabled: false,
    retirementPlannerEnabled: false
};

globalThis.integratedSharedData = {
    translations: null,
    AMPLITUDE_EVENTS
};

globalThis.pcapInitComponents = false;
