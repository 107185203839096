import {
    ROUTE_OPEN_ACCOUNT,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_FIRST_USE_INTERVIEW,
    ROUTE_MANAGED_ACCOUNTS_ADVICE_NEW,
    ROUTE_MANAGED_ACCOUNTS_STARTED_NEW,
    ROUTE_MANAGED_ACCOUNTS_INTRO_NEW,
    ROUTE_MANAGED_ACCOUNTS_HOME_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_ONLINE_ADVICE_GET_STARTED,
    ROUTE_ONLINE_ADVICE_PREVIEW,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT
} from "../../../../routes";

export const ROUTES_WITH_NO_SIDEBAR = [
    ROUTE_OPEN_ACCOUNT,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_NEW,
    ROUTE_MANAGED_ACCOUNTS_FIRST_USE_INTERVIEW,
    ROUTE_MANAGED_ACCOUNTS_ADVICE_NEW,
    ROUTE_MANAGED_ACCOUNTS_STARTED_NEW,
    ROUTE_MANAGED_ACCOUNTS_INTRO_NEW,
    ROUTE_MANAGED_ACCOUNTS_HOME_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_ONLINE_ADVICE_GET_STARTED,
    ROUTE_ONLINE_ADVICE_PREVIEW,
    ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT
];
