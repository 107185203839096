import React, { forwardRef } from "react";

import { omit as _omit } from "lodash";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import ToolTip from "../toolTip/ToolTip";

/**
 * Decorates the NavLink by adding the following props
 * disableLink - if set to true the link will be disabled
 * tooltip - if disabled, a tooltip is expected to explain why
 */
const CustomNavLink = forwardRef((props, ref) => {
    const isLinkDisabled = props.disableLink;

    const disableClick = (e) => {
        e.preventDefault();
    };

    return (
        <>
            {isLinkDisabled && (
                <NavLink
                    data-testid="custom-nav-link"
                    isActive={false}
                    onClick={disableClick}
                    to={"#"}
                    disableLink={true}
                    ref={ref}
                    className={"disabled-link disabled"}
                >
                    <ToolTip text={props.tooltip} placement={"top"}>
                        <span className="disabled-link" data-testid="disabled-link-tooltip">
                            {props.children}
                        </span>
                    </ToolTip>
                </NavLink>
            )}
            {!isLinkDisabled && (
                <NavLink
                    data-testid="custom-nav-link"
                    {..._omit(props, ["disableLink", "tooltip"])}
                    ref={ref}
                >
                    {props.children}
                </NavLink>
            )}
        </>
    );
});
CustomNavLink.propTypes = {
    disableLink: PropTypes.bool,
    children: PropTypes.array,
    tooltip: PropTypes.string
};
CustomNavLink.displayName = "CustomNavLink";
export default CustomNavLink;
