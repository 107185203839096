import React from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { formatCurrency } from "../../../utils/currencyUtils";

const getToolTipLabel = (config) => {
    return (
        <div>
            {config.prefix}
            <a href="http://www.ssa.gov/OACT/quickcalc/" target="_blank" rel="noreferrer">
                {config.linkTxt}
            </a>
            {config.suffix}
        </div>
    );
};

const MonthlyAmountCheckBox = ({
    estimatedAmount,
    translations,
    handleOpenTable,
    estimatedAmountCheckboxStatus,
    handleChange
}) => {
    const amountText = translations.details.Amount;

    return (
        <div className="checkbox" data-testid="monthly-amount-check-box">
            <div className="label-frame d-flex">
                <input
                    className="social-security-participant-monthly-benefit-checkbox"
                    type="checkbox"
                    name="overridePersonCheckbox"
                    onChange={handleChange}
                    checked={estimatedAmountCheckboxStatus}
                    aria-labelledby="social-security-participant-benefit"
                    data-testid="participant-checkbox"
                />
                <p id="social-security-participant-benefit">
                    {amountText}
                    <a
                        id="social-security-participant-benefit-amount-lnk"
                        onClick={handleOpenTable}
                        onKeyDown={(e) =>
                            handleKeyboardDownEvent({
                                event: e,
                                keys: keyboardInteractions.button,
                                output: handleChange
                            })
                        }
                        tabIndex="0"
                    >
                        <span data-testid="estimate-amt">{formatCurrency(estimatedAmount)}</span>
                    </a>{" "}
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="personToolTip" data-bs-toggle="mytooltip">
                                {getToolTipLabel(translations.details.PersonToolTip)}
                            </Tooltip>
                        }
                    >
                        <span className="inline-elm-wrapper" id="person-ss-tooltip">
                            <a style={{ textDecoration: "none" }} tabIndex="0">
                                <i className="em-question-mark"></i>
                            </a>
                        </span>
                    </OverlayTrigger>
                </p>
            </div>
        </div>
    );
};
MonthlyAmountCheckBox.propTypes = {
    estimatedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    translations: PropTypes.object,
    handleOpenTable: PropTypes.func,
    estimatedAmountCheckboxStatus: PropTypes.bool,
    handleChange: PropTypes.func
};

export default MonthlyAmountCheckBox;
