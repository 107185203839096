import { useQuery } from "@tanstack/react-query";

import { getAccountSummary } from "../../services/accountSummaryService";
import { IAccountSummary } from "../../types/common";
import { QUERY_KEYS } from "../constants";

interface AccountSummaryResponse {
    data: IAccountSummary;
    error: Error | null;
    isError: boolean;
    isLoading: boolean;
}

const useAccountSummary = (): AccountSummaryResponse => {
    const { data, isError, error, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.SUMMARY],
        queryFn: () => getAccountSummary()
    });

    return { data, isError, error, isLoading };
};

export default useAccountSummary;
