import {
    ROUTE_NETWORTH,
    ROUTE_TRANSACTIONS,
    ROUTE_PORTFOLIO,
    ROUTE_RETIREMENT_PLANNER,
    ROUTE_SAVINGS_PLANNER,
    ROUTE_MANAGED_ACCOUNTS,
    ROUTE_INVESTMENT_CHECKUP,
    ROUTE_ENROLL,
    ROUTE_SETTINGS,
    ROUTE_CASHFLOW,
    ROUTE_CLOSED_ACCOUNTS,
    ROUTE_EDUCATION,
    ROUTE_STOCK_OPTIONS,
    ROUTE_MANUAL_CLASSIFICATIONS,
    ROUTE_APPT,
    ROUTE_ACCOUNTS_DETAILS,
    ROUTE_WELLNESS,
    ROUTE_NOTIFICATIONS,
    ROUTE_INVESTMENT_LINEUP,
    ROUTE_TRANSFER_FUNDS,
    ROUTE_DOCUMENTS,
    ROUTE_MANAGE_BENEFICIARIES,
    ROUTE_MANAGE_INVESTMENTS,
    ROUTE_ADVISOR,
    ROUTE_VIEW_MEETING,
    ROUTE_RECURRING_TRANSFERS,
    ROUTE_GMWB,
    ROUTE_LEAVE_ROUTE,
    ROUTE_ACCOUNT_ACTIONS,
    ROUTE_OPEN_BANK_ACCOUNT,
    ROUTE_CLOSE_BANK_ACCOUNT,
    ROUTE_TRANSFER_MANAGER,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_MARKETING_CONSENT,
    ROUTE_PLANNING_HISTORY,
    ROUTE_MANAGE_BENEFICIARIES_PS,
    ROUTE_PERSONAL_STRATEGY,
    ROUTE_PERSONAL_STRATEGY_TRADES,
    ROUTE_PERSONAL_STRATEGY_DIVIDENDS,
    ROUTE_PERSONAL_STRATEGY_RECOMMENDATION,
    ROUTE_STRATEGY_REVIEW_WIZARD,
    ROUTE_PERSONAL_STRATEGY_PLUS,
    ROUTE_PERSONAL_STRATEGY_PLUS_INTRO,
    ROUTE_PERSONAL_STRATEGY_PLUS_OWNER_INFO,
    ROUTE_PERSONAL_STRATEGY_PLUS_LINK_PAYMENT,
    ROUTE_RECONNECT_RETIREMENT_ACCOUNT,
    ROUTE_UPDATE_FORWARDING_CONTACT_INFORMATION,
    ROUTE_NEXT_BEST_ACTIONS
} from "../../../../routes";
import SUB_PAGE_NO_SIDEBAR_ROUTES from "../../IntegratedSubPageContainer/helpers/subPageNoSidebarRoutes";

const SUB_PAGE_ROUTES = [
    ROUTE_NETWORTH,
    ROUTE_TRANSACTIONS,
    ROUTE_CASHFLOW,
    ROUTE_PORTFOLIO,
    ROUTE_INVESTMENT_CHECKUP,
    ROUTE_ENROLL,
    ROUTE_MANAGED_ACCOUNTS,
    ROUTE_SETTINGS,
    ROUTE_CLOSED_ACCOUNTS,
    ROUTE_STOCK_OPTIONS,
    ROUTE_MANUAL_CLASSIFICATIONS,
    ROUTE_EDUCATION,
    ROUTE_APPT,
    ROUTE_WELLNESS,
    ROUTE_ACCOUNTS_DETAILS,
    ROUTE_NOTIFICATIONS,
    ROUTE_INVESTMENT_LINEUP,
    ROUTE_TRANSFER_FUNDS,
    ROUTE_DOCUMENTS,
    ROUTE_MANAGE_BENEFICIARIES,
    ROUTE_VIEW_MEETING,
    ROUTE_MANAGE_INVESTMENTS,
    ROUTE_ADVISOR,
    ROUTE_RECURRING_TRANSFERS,
    ROUTE_GMWB,
    ROUTE_LEAVE_ROUTE,
    ROUTE_ACCOUNT_ACTIONS,
    ROUTE_OPEN_BANK_ACCOUNT,
    ROUTE_CLOSE_BANK_ACCOUNT,
    ROUTE_TRANSFER_MANAGER,
    ROUTE_OPEN_ACCOUNT_OPTIONS,
    ROUTE_MARKETING_CONSENT,
    ROUTE_MANAGE_BENEFICIARIES_PS,
    ROUTE_PERSONAL_STRATEGY,
    ROUTE_PERSONAL_STRATEGY_TRADES,
    ROUTE_PERSONAL_STRATEGY_DIVIDENDS,
    ROUTE_PERSONAL_STRATEGY_RECOMMENDATION,
    ROUTE_STRATEGY_REVIEW_WIZARD,
    ROUTE_PLANNING_HISTORY,
    ROUTE_PERSONAL_STRATEGY_PLUS,
    ROUTE_PERSONAL_STRATEGY_PLUS_INTRO,
    ROUTE_PERSONAL_STRATEGY_PLUS_OWNER_INFO,
    ROUTE_PERSONAL_STRATEGY_PLUS_LINK_PAYMENT,
    ROUTE_RECONNECT_RETIREMENT_ACCOUNT,
    ROUTE_UPDATE_FORWARDING_CONTACT_INFORMATION,
    ROUTE_NEXT_BEST_ACTIONS
];

const getSubPageRoutes = (includeSideBarRoutes = true) => {
    const subPageRoutes = [...SUB_PAGE_ROUTES];

    if (
        globalThis.integratedEligibility.savingsPlannerEnabled &&
        subPageRoutes.indexOf(ROUTE_SAVINGS_PLANNER) === -1
    ) {
        subPageRoutes.push(ROUTE_SAVINGS_PLANNER);
    }

    if (
        globalThis.integratedEligibility.retirementPlannerEnabled &&
        subPageRoutes.indexOf(ROUTE_RETIREMENT_PLANNER) === -1
    ) {
        subPageRoutes.push(ROUTE_RETIREMENT_PLANNER);
    }

    if (includeSideBarRoutes) {
        return subPageRoutes.concat(SUB_PAGE_NO_SIDEBAR_ROUTES);
    }

    return subPageRoutes;
};

export default getSubPageRoutes;
