import { SET_NAVIGATION_MENU } from "../../actions/navigationMenu";

const initialState = {
    menus: [],
    dataIsLoaded: false
};

/**
 * The navigation menu is the overall site navigation beyond just LIAT.   This is used in the "I Want To" dropdown to
 * link to external areas of the site.
 */
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_NAVIGATION_MENU:
            return { ...state, ...{ menus: payload, dataIsLoaded: true } };

        default:
            return state;
    }
};
