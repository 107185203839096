import React from "react";

import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";

import { ACCESSIBILITY } from "../../../../constants/AccessibilityPaycheck";

export const MyPeers = ({ translations }) => {
    const peersPopOver = (
        <Popover id="popover-trigger-hover-focus" title={translations.peers.rivdTitle}>
            {translations.peers.popoverMessage}
        </Popover>
    );

    return (
        <td className="integrated-hdic-peers hdic-tooltip">
            <div
                className="integrated-hdic-my-peers-title"
                id={ACCESSIBILITY.HOW_DO_I_COMPARE["my-peers-title"]}
            >
                {translations.peers.rivdTitle}&nbsp;
                <OverlayTrigger overlay={peersPopOver} placement="top">
                    <i className="em-question-circle integrated-hdic-color-primary-1000"></i>
                </OverlayTrigger>
            </div>
        </td>
    );
};
MyPeers.propTypes = {
    translations: PropTypes.object
};
