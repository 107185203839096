import { useQuery } from "@tanstack/react-query";

import { getFunnelAttributesData } from "../../services/initDataService";
import { FunnelData } from "../../types/common/funnelAttributes";
import { QUERY_KEYS } from "../constants";

const useFunnelAttributes = () => {
    return useQuery<FunnelData["data"]>({
        queryKey: [QUERY_KEYS.GET_FUNNEL_DATA],
        queryFn: async () => {
            const response = await getFunnelAttributesData();
            return response.data;
        }
    });
};

export default useFunnelAttributes;
