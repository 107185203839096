import React from "react";

import { ErrorBoundary, withProfiler } from "@sentry/react";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import DateUtil from "core-ui/client/src/app/DateUtil";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";
import { get as _get, isNil as _isNil } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import FallbackComponent from "../components/errorBoundary/Fallback";
import IntegratedIncomeGap from "../components/incomeGap/IntegratedIncomeGap";
import ReferenceData from "../constants/ReferenceData";
import { useMaDetails, usePrimaryPlan } from "../queries";
import { getPAEFault } from "../utils/paeUtils";

const sentryFallback = <FallbackComponent />;

const IncomeGapControls = (props) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("incomeGapTab")?.managedAccountsMessage;
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const participant = useSelector((state) => state.participant);
    const userPrimaryPlan = usePrimaryPlan();
    const maDetails = useMaDetails(userPrimaryPlan);
    const dataIsLoaded = !!translations && primaryPlan.dataIsLoaded;
    const INCOME_GAP = "incomeGap";
    const MAXIMIZER = "maximizer";

    let routeFrom = "";
    if (dataIsLoaded && !ObjectUtil.isUndefinedOrNull(props.path)) {
        const actualRoute = props.path.split("/");
        routeFrom = props && actualRoute[2] === MAXIMIZER ? MAXIMIZER : INCOME_GAP;
    }

    const getNextPayrollYear = () => {
        return activeDeferral.nextPayrollDate
            ? DateUtil.getYear(
                  DateUtil.getDateFormatted("DD-MMM-YYYY", activeDeferral.nextPayrollDate)
              )
            : null;
    };

    const get415Limit = () => {
        const currentYear = new Date().getFullYear();
        if (currentYear !== getNextPayrollYear()) {
            const nextYearsLimit = primaryPlan.IRSLimits.limit402gNextYear;
            if (_isNil(nextYearsLimit)) {
                return primaryPlan.IRSLimits.limit402g;
            } else {
                return nextYearsLimit;
            }
        } else {
            return primaryPlan.IRSLimits.limit402g;
        }
    };

    const getContributionSliderLinkUrl = () => {
        const url = ReferenceData.URL_MY_CONTRIBUTIONS;

        const params = {
            individualId: participant.individualId,
            groupId: primaryPlan.id // the primary plan's ID is the group ID
        };

        return StringUtil.supplant(url, params);
    };

    const isEmulatorParticipant = () => {
        return participant.type !== "PARTICIPANT";
    };

    const throwPAENavigationModal = (e) => {
        const paeFault = getPAEFault();
        dispatch(setCurrentFault(paeFault));
        e.stopPropagation();
    };

    const isMaximizerAvailable = _get(primaryPlan, "planRules.maximizer") || false;
    const provider = maDetails?.maProduct;

    const isManagedAccountsUser = maDetails?.enrolledInManagedAccounts;

    return (
        <div data-testid="income-gap-controls">
            {dataIsLoaded && (
                <ErrorBoundary fallback={sentryFallback}>
                    <IntegratedIncomeGap
                        routeFrom={routeFrom}
                        isManagedAccountsUser={isManagedAccountsUser}
                        isMaximizerAvailable={isMaximizerAvailable}
                        nextPayrollYear={getNextPayrollYear()}
                        get415Limit={get415Limit()}
                        getContribSliderUrl={getContributionSliderLinkUrl()}
                        managedAccountsName={provider}
                        showEmulatorMessage={isEmulatorParticipant()}
                        throwPaeError={throwPAENavigationModal}
                    />
                </ErrorBoundary>
            )}
        </div>
    );
};
IncomeGapControls.propTypes = {
    path: PropTypes.any
};
export default withProfiler(IncomeGapControls, { name: "IncomeGapControls" });
