import { SET_NAVIGATION_MENU } from "../actions/navigationMenu";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import { getNavigationMenu } from "../services/navigationMenuService";

export const loadNavigationMenu = (groupId) => {
    return (dispatch) => {
        return getNavigationMenu(groupId)
            .then((response) => {
                return dispatch({
                    type: SET_NAVIGATION_MENU,
                    payload: response
                });
            })
            .catch((fault) => {
                dispatch(setCurrentFault(fault));
                throw fault;
            });
    };
};
