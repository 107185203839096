import { AxiosResponse } from "axios";

import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

interface PrimaryPlanResponse {
    accuCode: string;
    database: string;
    defaultGroupId: string;
    defaultIndId: string;
    definedBenefitPlan: boolean;
    gaId: string;
    planId: string;
    statusCode: string;
    totalBal: number;
}

export const getPrimaryPlan = async () => {
    try {
        const url = getUrl("getPrimaryPlan");
        const response: AxiosResponse<PrimaryPlanResponse> = await executeGet(url);
        return response.data;
    } catch (err) {
        console.error("Error with service getPrimaryPlan: ", err);
        return err;
    }
};

export default getPrimaryPlan;
