import {
    DiagnosticsConstants,
    formatTimingPrefix,
    determineLoginFlow,
    LoginFlowEvents
} from "./common";
import { getLiatAppStartTime } from "./getEvents";
import { sendMetricEventMFA, sendMetricEvent } from "./sendEvents";

const LIAT_APP_START = DiagnosticsConstants.LIAT_APP_START;
const LIAT_APP_START_DELTA = DiagnosticsConstants.LIAT_APP_START + "Delta";

const measurementName = "LiatApp";

const timingPrefix = formatTimingPrefix();

const sentryLiatAppStartMetrics = () => {
    const liatAppStartTimeExists = getLiatAppStartTime();
    /**
     * With this logic we will check to see if we have set the liat app start metrics in the current session.
     * If we have not then proceed with getting and setting the data.
     */
    if (!liatAppStartTimeExists) {
        const liatAppStartTime = window.liatStartAppTimeInMemory; // grab it from the global state
        sessionStorage.setItem(timingPrefix + LIAT_APP_START, String(liatAppStartTime));

        const liatTimeZero = sessionStorage.getItem(`${timingPrefix}timeZero`); // liat time zero
        const liatStartDelta = liatAppStartTime - Number(liatTimeZero); // delta between index.html load and liat app start

        sessionStorage.setItem(timingPrefix + LIAT_APP_START_DELTA, String(liatStartDelta));

        const { loginLastHash, flowName, startHash, endTime } = determineLoginFlow();

        // If true, we are in the MFA Sign in login flow
        if (flowName === LoginFlowEvents.MFA_LOGIN) {
            // Measure from when the user clicked mfa sign in to when liat starts
            const timeToStartLiatFromVerifySignIn = liatAppStartTime - endTime;

            sendMetricEventMFA({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: liatAppStartTime,
                name: LoginFlowEvents.MFA_LOGIN,
                timeToLoad: timeToStartLiatFromVerifySignIn
            });
            // If true, we are in the DF login flow
        } else if (flowName === LoginFlowEvents.DF_LOGIN) {
            const timeToStartLiatFromClick = liatAppStartTime - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: liatAppStartTime,
                name: LoginFlowEvents.DF_LOGIN,
                timeToLoad: timeToStartLiatFromClick
            });
            // If true, we are in the SSO login flow
        } else if (flowName === LoginFlowEvents.SSO_LOGIN) {
            const timeToStartLiatFromSSO = liatAppStartTime - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: liatAppStartTime,
                name: LoginFlowEvents.SSO_LOGIN,
                timeToLoad: timeToStartLiatFromSSO
            });
            // Collect the registration flow data points
        } else if (loginLastHash && DiagnosticsConstants[loginLastHash]) {
            sendMetricEvent({
                measurementName,
                name: `${LIAT_APP_START}_${DiagnosticsConstants[loginLastHash]}`,
                timeToLoad: liatStartDelta,
                startTime: liatAppStartTime,
                endTime: Number(liatTimeZero),
                loginLastHash
            });
        }

        // We will keep this as a common metric independent of any login flow
        sendMetricEvent({
            measurementName,
            name: LIAT_APP_START,
            timeToLoad: liatStartDelta,
            startTime: liatAppStartTime,
            endTime: Number(liatTimeZero),
            loginLastHash
        });
    }
};

export default sentryLiatAppStartMetrics;
