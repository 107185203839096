import React, { memo } from "react";

import { useQuery } from "@tanstack/react-query";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useAtomValue } from "jotai";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { csrfAtom } from "../../atoms/atoms";
import { getAccounts } from "../../middleware/otherAssetsMiddleware";
import { QUERY_KEYS } from "../../queries/constants";
import { WantToShell } from "../loadShells/LoadShells";
import WantToList from "../wantToNavigation/WantToList";

const WantToComponent = ({ showLoader }) => {
    const translations = selectTranslations("rivdTodayView")["iWantTo"];
    const navigationMenu = useSelector((state) => state.navigationMenu);
    const csrf = useAtomValue(csrfAtom);
    const { data: hideIWantToLinks, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.GET_ACCOUNT_2],
        queryFn: () => getAccounts(csrf),
        enabled: !!csrf
    });
    const iWantToHasPriority =
        navigationMenu.dataIsLoaded && hideIWantToLinks !== undefined && !isLoading;
    const shellClass = showLoader || !iWantToHasPriority ? "iwt__load-container" : "";
    const loadedClass = showLoader || !iWantToHasPriority ? "" : "loaded";

    if (!iWantToHasPriority) {
        return (
            <div
                className={`bg-white rounded-border i-want-to-list--container ${shellClass}`}
                data-testid="want-to-component"
            >
                <WantToShell />
            </div>
        );
    }

    return (
        <div
            className={`bg-white rounded-border i-want-to-list--container ${shellClass}`}
            data-testid="want-to-component"
        >
            <div className={`use-fade-in ${loadedClass}`}>
                <div className="i-want-to-list--header">
                    <span className="i-want-to-icon"></span>
                    <h3>{translations?.labelEllipsis}</h3>
                </div>
                <WantToList />
            </div>
        </div>
    );
};

WantToComponent.propTypes = {
    showLoader: PropTypes.bool.isRequired
};

export default memo(WantToComponent);
