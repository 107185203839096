import { Location } from "history";
import { find as _find } from "lodash";
import { useRouteMatch } from "react-router-dom";

import {
    ROUTE_ACCOUNTS_ADD,
    ROUTE_EMERGENCY_FUND_ACCOUNTS,
    ROUTE_EMPLOYER_PLAN_ANALYSIS,
    ROUTE_EXCLUDE_ACCOUNTS,
    ROUTE_FP_TOPICS,
    ROUTE_GOALS,
    ROUTE_INSURANCE_WIZARD,
    ROUTE_SOCIAL_SECURITY_WIZARD,
    ROUTE_EQUITY_COMPENSATION_WIZARD,
    ROUTE_DEBT_REVIEW_WIZARD,
    ROUTE_EMERGENCY_FUND_WIZARD,
    ROUTE_SAVINGS_STRATEGY_WIZARD,
    ROUTE_REQUEST_ADVICE_WIZARD
} from "../../../routes";
import { isEmpty } from "../../utils/currencyUtils";

const MODAL_ROUTES = [
    ROUTE_ACCOUNTS_ADD,
    ROUTE_EXCLUDE_ACCOUNTS,
    ROUTE_GOALS,
    ROUTE_EMERGENCY_FUND_ACCOUNTS,
    ROUTE_FP_TOPICS,
    ROUTE_EMPLOYER_PLAN_ANALYSIS,
    ROUTE_INSURANCE_WIZARD,
    ROUTE_SOCIAL_SECURITY_WIZARD,
    ROUTE_EQUITY_COMPENSATION_WIZARD,
    ROUTE_DEBT_REVIEW_WIZARD,
    ROUTE_EMERGENCY_FUND_WIZARD,
    ROUTE_SAVINGS_STRATEGY_WIZARD,
    ROUTE_REQUEST_ADVICE_WIZARD
];

const useDisplayPcapModal = (routeLocation?: Location) => {
    const modalRouteMatch = useRouteMatch(MODAL_ROUTES);

    if (routeLocation) {
        return !!_find(
            MODAL_ROUTES,
            (route: string) => routeLocation.pathname.indexOf(route) !== -1
        );
    }

    return !isEmpty(modalRouteMatch);
};

export default useDisplayPcapModal;
