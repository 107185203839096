interface IndividualOptions {
    allowAllocationsUpdate: boolean;
    currentAllocationModel: boolean;
    disableEquityMix: boolean;
    doMoneyTypeGroupingsApply: boolean;
    doTradeRestrictionsApply: null;
    hasAllMoneyTypeGroup: boolean;
    isPortfolioManagementAllowed: boolean;
    modelName: null;
}

/**
 * Does the participant have all money type groupings?
 * @returns {boolean}
 */
const getHasAllMoneyTypeGroupings = (individualOptions: IndividualOptions): boolean => {
    return individualOptions.hasAllMoneyTypeGroup || !individualOptions.doMoneyTypeGroupingsApply;
};

export default getHasAllMoneyTypeGroupings;
