import React, { memo, useCallback } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isArrayEmpty } from "gw-shared-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { reselectAddDeferralButton } from "../../selectors/reselectSelectors";

const DEFAULT_DEFERRALS = [];

const AddDeferralButton = ({
    handleShowAddDeferralModal,
    availableDeferrals = DEFAULT_DEFERRALS
}) => {
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const translations = selectTranslations("retirementIncome")?.addContribution;
    const { canShowAddNewContributions, displayMulti } = reselectAddDeferralButton(
        primaryPlan,
        isArrayEmpty(availableDeferrals)
    );

    const handleKeyboardDownLearnMore = (event) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => handleShowAddDeferralModal(true)
        });
    };

    const handleClick = useCallback(
        () => handleShowAddDeferralModal(true),
        [handleShowAddDeferralModal]
    );

    if (!canShowAddNewContributions || !displayMulti) {
        return null;
    }

    return (
        <div
            data-testid="add-deferral-button"
            onClick={handleClick}
            className="addContributionLink"
            onKeyDown={(e) => handleKeyboardDownLearnMore(e)}
            tabIndex="0"
            role="button"
        >
            [+] {translations?.addNew}
        </div>
    );
};

AddDeferralButton.propTypes = {
    handleShowAddDeferralModal: PropTypes.func,
    availableDeferrals: PropTypes.array
};

export default memo(AddDeferralButton);
