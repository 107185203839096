import _pick from "lodash/pick";

import {
    SET_APPLICATION_SETTINGS_PROJECTION_SETTINGS,
    UPDATE_PROJECTION_SETTINGS,
    SET_SAML_DATA_LOADED
} from "../../actions/applicationSettings/applicationSettingsTypes";
import { SET_INIT_DATA } from "../../actions/initData";
import { SET_MANAGED_ACCOUNTS_PROGRAM_NAME } from "../../actions/managedAccounts/index";

const initialState = {
    dataIsLoaded: false,
    governmentBenefits: null, //UI value
    managedAccountsProgramNameData: {
        adviceUrl: null,
        balance: null,
        menuOptions: null,
        groupServiceRules: null,
        advisoryServiceProviderCode: null,
        managedAccountsProgramName: null,
        onlineAdviceProgramName: null,
        onlineGuidanceProgramName: null,
        advisoryServicesProgramName: null,
        eligibleForManagedAccounts: null,
        eligibleForAdvice: null,
        enrolledInFullManagedAccounts: null,
        enrolledInAdvice: null,
        enrolledInGuidance: null,
        privacyUrl: null,
        disclosureUrl: null,
        disclosureText: null,
        termsOfServiceUrl: null
    }, // referenced for MTR
    samlDataLoaded: false,
    migratedContext: "",
    projectedIncome: null, //UI value       TODO is this a duplicate of projectedIncome reducer?
    projectionSettings: {
        includeSocialSecurity: false,
        confidence: 90, // Default confidence is 90
        rateOfInflation: null,
        term: 12
    },
    tradeOffs: null, //UI value
    integrated: true // temporary value as this was getting set on INIT
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_INIT_DATA: {
            return {
                ...state,
                ..._pick(payload.applicationSettings, Object.keys(state)),
                dataIsLoaded: true
            };
        }

        case SET_APPLICATION_SETTINGS_PROJECTION_SETTINGS:
            return { ...state, projectionSettings: payload };
        case UPDATE_PROJECTION_SETTINGS:
            return { ...state, projectionSettings: { ...state.projectionSettings, ...payload } };

        case SET_MANAGED_ACCOUNTS_PROGRAM_NAME:
            return {
                ...state,
                managedAccountsProgramNameData: { ...payload }
            };
        case SET_SAML_DATA_LOADED:
            return { ...state, samlDataLoaded: true };
        default:
            return state;
    }
};
