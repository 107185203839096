import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import { getMADetails } from "../../services";
import { MaDetailsResponse } from "../../types/common";

const useMaDetails = (primaryPlan): UseQueryResult<MaDetailsResponse["data"]> => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_MTR_DETAILS],
        queryFn: () => getMADetails(primaryPlan),
        enabled: !!primaryPlan
    });
};

export default useMaDetails;
