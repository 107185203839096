import StringUtil from "core-ui/client/src/app/StringUtil";

import { executeGet } from "../utils/apiUtils.js";
import { getUrl, isLocalhost, hasMocks } from "../utils/urlUtils.js";

export const getNavigationMenu = async (groupId) => {
    const service = "getNavigationMenu";

    const mock =
        window.location.origin + window.location.pathname + "data/get-group-navigation-menu.json";
    const url = StringUtil.supplant(getUrl(service), { groupId });
    const updatedUrl = isLocalhost && hasMocks() ? mock : url;

    const response = await executeGet(updatedUrl, "getNavigationMenuFault");
    return response.data;
};
