import React from "react";

import { ACCESSIBILITY } from "../../../../constants/AccessibilityPaycheck";

import { Headers, MyPeers, TopPeers } from "./index";

export const TableHeaders = ({ toggleHDICModal, display, translations }) => {
    /**
     * Desktop view - uses 'desktopView'
     * Mobile view - uses 'header1', 'header2'
     * @param {} headerDesc
     */

    const renderHeader = (headerDesc) => {
        switch (headerDesc) {
            case "desktopView":
                return (
                    <tr>
                        <td />
                        <MyPeers translations={translations} />
                        <TopPeers translations={translations} />
                        <td className="integrated-hdic-peers">
                            <div
                                className="integrated-hdic-me-title"
                                id={ACCESSIBILITY.MY_GOAL["me-title"]}
                            >
                                {translations.me}
                            </div>
                        </td>
                    </tr>
                );
            case "header1":
                return (
                    <tr>
                        <Headers
                            title={translations.peers.rivdTitle}
                            message={translations.peers.popoverMessage}
                        />
                        <Headers
                            title={translations.topPeers.title}
                            message={translations.topPeers.popoverMessage}
                        />
                        <th>
                            <div
                                className="text-uppercase text-bold"
                                id={ACCESSIBILITY.MY_GOAL["me-title"]}
                            >
                                {translations.me}
                            </div>
                        </th>
                    </tr>
                );

            case "header2":
                return (
                    <tr>
                        <th className="hdic-view-options" onClick={toggleHDICModal}>
                            {translations.viewOptions}
                        </th>
                        <th className="text-uppercase text-bold">{translations.peers.rivdTitle}</th>
                        <th className="text-uppercase text-bold">{translations.topPeers.title}</th>
                        <th
                            className="text-uppercase text-bold"
                            id={ACCESSIBILITY.MY_GOAL["me-title"]}
                        >
                            {translations.me}
                        </th>
                    </tr>
                );
            default:
                break;
        }
    };

    return renderHeader(display);
};
