import React, { useState, memo } from "react";

import { useQuery } from "@tanstack/react-query";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader } from "gw-shared-components";
import { Modal } from "react-bootstrap";
import { generatePath } from "react-router";

import { dispatchAmplitude, setOpenDeminModal } from "../../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../../events/eventBusEvents";
import { useSelector, useDispatch } from "../../../hooks/use-redux-actions";
import { QUERY_KEYS } from "../../../queries/constants";
import updateDeminModalTracking from "../../../services/updateDeminModalTracking";

import DeminModalRadioButton from "./DeminModalRadioButton";

interface DeminModalProps {
    isPrivileged: boolean;
    secDeminModal: {
        groupId: string;
        individualId: string;
        planName: string;
    }[];
}

interface RadioValue {
    groupId: string;
    individualId: string;
    planName: string;
}

const DEFAULT_RADIO_VALUES = {
    groupId: "",
    individualId: "",
    planName: ""
};

const DeminModal = ({ isPrivileged, secDeminModal }: DeminModalProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [radioValue, setRadioValue] = useState<RadioValue>(DEFAULT_RADIO_VALUES);
    const dispatch = useDispatch();
    const translations = selectTranslations("deminModal");
    const deminModal = useSelector((state) => state.shared.openDeminModal);

    const { isLoading } = useQuery({
        queryKey: [QUERY_KEYS.UPDATE_DEMIN_MODAL_TRACKING],
        queryFn: () => updateDeminModalTracking(),
        enabled: secDeminModal.length > 0 && !isPrivileged
    });

    const handleClose = (isCancel: boolean) => {
        const cancelClick = isCancel
            ? EventBusEvents.DEMIN_MODAL.CANCEL_CLICK
            : EventBusEvents.DEMIN_MODAL.X_CLICK;

        const closeModalEvent = new CustomEvent("modal-close");
        window.dispatchEvent(closeModalEvent);

        dispatch(setOpenDeminModal(false));

        if (!isPrivileged) {
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: cancelClick
                    }
                })
            );
        }
    };

    const handleChange = (e) => {
        const value = e.currentTarget.value.split(",");
        setIsChecked(true);
        setRadioValue({
            individualId: value[0],
            groupId: value[1],
            planName: value[2]
        });
        if (!isPrivileged) {
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: EventBusEvents.DEMIN_MODAL.RADIO_BUTTON_CLICK
                    }
                })
            );
        }
    };

    const handleClaimAccount = (e) => {
        e.preventDefault();
        if (isChecked || !isPrivileged) {
            const url = generatePath("/participant/#/d/deminLanding/:gaId", {
                gaId: radioValue.groupId
            });

            dispatch(setOpenDeminModal(false));

            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: EventBusEvents.DEMIN_MODAL.CLAIM_ACCOUNT_CLICK
                    }
                })
            );

            window.location.href = url;
        }
    };

    if (isLoading && !isPrivileged) {
        return <Loader fullscreen={false} messages={null} showMessages={false} useSvg={false} />;
    }

    return (
        translations && (
            <Modal
                dialogClassName="demin-modal"
                show={deminModal}
                onHide={() => handleClose(false)}
            >
                <div className="demin-modal" data-testid="demin-modal">
                    <div className="row">
                        <div className="col-md-10">
                            <h2
                                className="modal-title"
                                id="modal-title"
                                data-testid="modal-title"
                                aria-label={translations["title"]}
                            >
                                {translations["title"]}
                            </h2>
                        </div>
                        <div className="col-md-2 text-right">
                            <button
                                type="button"
                                className="demin-close-icon modal-close-icon"
                                data-testid="modal-close-icon"
                                aria-label="Close dialog"
                                onClick={() => handleClose(false)}
                            >
                                <i className="em-delete-icon" />
                            </button>
                        </div>
                    </div>

                    <Modal.Header className="modal-header demin-notice" data-testid="demin-notice">
                        <i className="em-info-icon" />
                        <div>
                            <h6 className="modal-sub-title">{translations["first-notice"]} </h6>
                            <p>{translations["content"]}</p>
                        </div>
                    </Modal.Header>
                    <form
                        onSubmit={handleClaimAccount}
                        name="claimDeminAccount"
                        aria-label="Demin Accounts"
                    >
                        <Modal.Body className="modal-body" id="modal-body">
                            <DeminModalRadioButton
                                handleChange={handleChange}
                                radioValue={radioValue}
                                secDeminModal={secDeminModal}
                            />
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <div className="row">
                                <div className=" text-right">
                                    <button
                                        className="submit-btn btn cancel-btn"
                                        onClick={() => handleClose(true)}
                                        aria-label="Cancel"
                                        type="button"
                                    >
                                        {translations["cancel"]}
                                    </button>
                                    <input
                                        className={`submit-btn btn btn-primary ${
                                            isPrivileged || !isChecked ? "is-disabled" : ""
                                        }`}
                                        type="submit"
                                        value={translations["claim-account"]}
                                        aria-disabled={isPrivileged || !isChecked}
                                        role="button"
                                        name={translations["claim-account"]}
                                    />
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </div>
            </Modal>
        )
    );
};

export default memo(DeminModal);
