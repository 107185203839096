import React, { useEffect } from "react";

import PropTypes from "prop-types";

const ModalContainer = ({ show, location }) => {
    useEffect(() => {
        if (show) {
            window.dispatchEvent(
                new CustomEvent("pc_pageloadrequest", {
                    detail: { hash: window.location.hash }
                })
            );
        }
    }, [show, location]);

    const hide = show ? "" : "hide";

    return <div id="modal-container" className={hide}></div>;
};

ModalContainer.propTypes = {
    show: PropTypes.bool,
    location: PropTypes.object
};

export default ModalContainer;
