import React from "react";

import { useSelector } from "../../../../hooks/use-redux-actions";

const PremierIRA = () => {
    const insightsWidgetLoaded = useSelector(
        (state) => state.shared.loadStatus.insightsWidgetLoaded
    );
    const loadClass = /*!insightsHasPriority ||*/ !insightsWidgetLoaded ? "" : "loaded";

    return (
        <div
            id="insights-widget"
            data-testid="insights-widget"
            className={`use-fade-in rounded-border bg-white insights-widget--container is-hidden ${loadClass}`}
        />
    );
};

export default PremierIRA;
