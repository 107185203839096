export const DEFAULT_STALE_TIME = 300_000; // 5 minutes

export const QUERY_KEYS = {
    GET_ACCOUNT_2: "getAccount2",
    GET_ACCOUNTS_2_LIST: "getAccounts2",
    GET_ACCOUNTS_LITE: "getAccountsLite",
    GET_QUICK_VIEW_ACCOUNTS_DATA: "getQuickViewAccountsData",
    GET_USER_MESSAGES: "getUserMessages",
    ELIGIBLE: "eligible",
    SUMMARY: "summary",
    SHOW_LIAT: "showLIAT",
    GET_HOUSEHOLD_INIT_DATA: "getHouseholdInitData",
    GET_PERSONS: "getPersons",
    GET_PRIMARY_PLAN: "getPrimaryPlan",
    UPDATE_DEMIN_MODAL_TRACKING: "updateDeminModalTracking",
    GET_MTR_DETAILS: "getMADetails",
    GET_FUNNEL_DATA: "getFunnelData",
    GET_GROUP_TXN_ACCESS: "getGroupTxnAccess"
};
