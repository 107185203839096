import { getEnvironmentDisplayName } from "core-ui/client/react/core/utils/getEnvironmentDisplayName";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { isUndefinedOrNull, isEmptyString } from "gw-shared-components";
import { batch } from "react-redux";

import { name, version } from "../../../../../package.json";
import { SET_SAML_DATA_LOADED } from "../actions/applicationSettings/applicationSettingsTypes";
import { setDeferralSetupConfigData } from "../actions/deferrals/deferralActions";
import {
    setShowNqModal,
    setShowLockheedModal,
    setLockheedTerms,
    showLockheedEnroll
} from "../actions/enrollment/enrollmentAction";
import { setShowModal } from "../actions/goal/goalModalActionCreators";
import { SET_INIT_DATA } from "../actions/initData";
import { splitOtherAssetsByEmployer } from "../actions/otherAssets/otherAssetsActions";
import { SET_PLAN_RULES_OVERRIDE_FLAGS } from "../actions/participantDetails/participantDetailsTypes";
import { setPlansWithBalances, setNqInfo } from "../actions/paycheck/paycheckActions";
import { setManagedAccountsInPlan } from "../actions/plans/primaryPlanActions";
import {
    updateProjectedIncome,
    setHubCalculation
} from "../actions/projectedIncome/projectedIncomeActions";
import {
    setCurrentFault,
    readyToShowApp,
    showLoader,
    dispatchEventBus,
    dispatchAmplitude,
    setShowLoaderMessages,
    setSecondaryLoader
} from "../actions/shared/sharedActionCreators";
import { HASH_ROUTES } from "../constants/HashRoutes";
import InitDataFactory from "../factories/InitDataFactory";
import { canShowHDICView, canShowHealthCareView } from "../selectors/featureFlagSelectors";
import { getInitData } from "../services/initDataService";
import { fetchIsLockheed } from "../services/lockheedModalService";
import { modalStack } from "../services/modalStackService";
import InitDataValidator from "../utils/InitDataValidator";
import { findPrimaryPlan } from "../utils/planUtils";

import { loadHealthViewData } from "./healthCareMiddleware";
import { loadHowDoICompare } from "./howDoICompareMiddleware";
import { loadManagedAccountsProgramName } from "./managedAccountsProgramNameMiddleware";
import { loadNavigationMenu } from "./navigationMenuMiddleware";
import { getEligiblePlans } from "./nqEnrollment";
import { storeStandardDetails } from "./sessionStorageMiddleware";
import setManagedAccountsData from "./setManagedAccountsData";
import { setSamlToken } from "./setSamlTokenMiddleware";
import { getTxnAccessSettings } from "./sharedMiddleware";

let validationError = false;

export const initialize = () => {
    return (dispatch) => {
        dispatch(showLoader(true));
        dispatch(setSecondaryLoader(true));
        dispatch(setShowLoaderMessages(true));
        // getInitData has to happen first, then the others can be processed...
        dispatch(loadInitData())
            .then(() => {
                // Removing dispatchDependentThunks as a setSamlToken dependency
                // Allows for faster LIAT load times
                dispatch(dispatchDependentThunks(true)).catch((error) => handleError(error));
                //need to set the saml token before calling any asdb services
                dispatch(
                    setSamlToken()
                        .then(() => {
                            dispatch(dispatchSamlTokenDependencies());
                        })
                        .catch((error) => {
                            // set indicator for advisory display
                            dispatch({ type: SET_SAML_DATA_LOADED });
                            handleError(error);
                        })
                );
            })
            .catch(() => {});
    };
};

export const noLiatInitialize = () => {
    // When showLIAT = false we only need to call this function
    return (dispatch) =>
        dispatch(dispatchDependentThunks(false)).catch((error) => handleError(error));
};

const handleError = (error) => {
    return (dispatch) => {
        let code = "INIT";
        if (error.message.includes("Hub")) {
            code = "HUB";
        }
        dispatch(setCurrentFault({ code, message: error.toString() }));
    };
};

export const loadInitData = () => {
    return (dispatch) => {
        return getInitData()
            .then((response) => {
                if (response.status === "SUCCESSFUL") {
                    try {
                        InitDataValidator.validate(response.data);
                    } catch (error) {
                        validationError = true;
                        const fault = {
                            message: error.message,
                            stack: error.stack,
                            code: "INIT"
                        };
                        throw fault;
                    }
                    if (!validationError) {
                        const domainModel = InitDataFactory.fromServiceResponse(response.data);

                        // set the globalThis migrated settings here. referenced in urlUtils
                        globalThis.migratedContext =
                            domainModel.applicationSettings.migratedContext;

                        batch(() => {
                            dispatch({ type: SET_INIT_DATA, payload: domainModel });
                            dispatch(initGAEvent(domainModel.user));
                            dispatch(setSecondaryLoader(false));

                            // Add secondary loader for components such as I want to

                            const managedAccounts = InitDataFactory.createManagedAccounts(
                                response.data.investmentOverview.managedAccountsOptions
                            );
                            dispatch(setManagedAccountsInPlan(managedAccounts));
                        });
                    }
                } else {
                    batch(() => {
                        dispatch(setCurrentFault(response.error));
                    });
                    throw response.error;
                }
            })
            .catch((fault) => {
                console.log("init fault", fault.message, fault.stack);
                batch(() => {
                    dispatch(showLoader(false));
                    dispatch(setSecondaryLoader(false));
                    dispatch(setCurrentFault(fault));
                });
                throw fault;
            });
    };
};

export const dispatchDependentThunks = (showLIAT) => {
    return async (dispatch, getState) => {
        const state = getState();
        const primaryPlan = state.primaryPlan;
        const plans = state.participant.plans;

        if (!validationError) {
            // Add if showLIAT
            if (showLIAT) {
                batch(() => {
                    dispatch({ type: SET_PLAN_RULES_OVERRIDE_FLAGS });
                    dispatch(updateProjectedIncome());
                    dispatch(splitOtherAssetsByEmployer());
                    dispatch(setDeferralSetupConfigData());
                    dispatch(setPlansWithBalances());
                    dispatch(storeStandardDetails());
                    dispatch(getTxnAccessSettings());
                    dispatch(setHubCalculation(true));
                });

                dispatch(loadNavigationMenu(primaryPlan.id));
            }

            setManagedAccountsData(dispatch);

            const eligiblePlans = getEligiblePlans(plans);

            if (eligiblePlans.length > 0) {
                await fetchIsLockheed(eligiblePlans).then((response) => {
                    if (response.data.groupLockheedPlan) {
                        // save data for lockheedModal terms of service display
                        dispatch(setLockheedTerms(response.data));
                        dispatch(setNqInfo(eligiblePlans));
                        dispatch(showLockheedEnroll(true));
                        const lockHeedAction = () => dispatch(setShowLockheedModal(true));
                        modalStack.addModal("lockHeedModal", 1, lockHeedAction);
                    } else {
                        dispatch(setNqInfo(eligiblePlans));
                        const nqAction = () => dispatch(setShowNqModal(true));
                        modalStack.addModal("nqModal", 1, nqAction);
                    }
                });
            }

            // Add if showLiat
            if (showLIAT) {
                if (primaryPlan.planRules.promptForSalary) {
                    const goalAction = () => dispatch(setShowModal(true));
                    modalStack.addModal("goalModal", 1, goalAction);
                }
            }

            batch(() => {
                dispatch(readyToShowApp());
                dispatch(showLoader(false));
            });
        }
    };
};

export const dispatchSamlTokenDependencies = () => {
    return (dispatch, getState) => {
        const state = getState();
        const participant = state.participant;
        const primaryPlan = state.primaryPlan;

        dispatch({ type: SET_SAML_DATA_LOADED });

        if (!isUndefinedOrNull(participant.investorId) && !isEmptyString(participant.investorId)) {
            dispatch(loadManagedAccountsProgramName());
        }

        if (canShowHealthCareView(participant, primaryPlan)) {
            dispatch(loadHealthViewData());
        }

        if (canShowHDICView(primaryPlan)) {
            dispatch(loadHowDoICompare());
        }
    };
};

const initGAEvent = (user) => {
    return (dispatch) => {
        const env = getEnvironmentDisplayName(window.location.host);
        const primaryPlan = findPrimaryPlan(user.plans);
        const route = HASH_ROUTES[window.location.hash] || "";
        const payload = {
            route,
            indId: user.individualId,
            planId: primaryPlan.id,
            terminated: primaryPlan.terminated || false,
            age: user.age,
            gender: user.gender,
            appVersion: `${name}@${version}`,
            environment: env
        };

        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: "ApplicationEvent.init_complete_event.init",
                    ...payload
                }
            })
        );

        const userProps = {
            age: user.age,
            gender: user.gender,
            individualId: user.individualId,
            personId: user.pcapPersonId,
            planId: primaryPlan.id,
            planName: primaryPlan.name,
            retirementAge: user.retirementAge,
            terminated: primaryPlan.terminated || false,
            appVersion: `${name}@${version} `
        };

        payload.user_properties = userProps;

        dispatch(dispatchEventBus("ApplicationEvent.init_complete_event.init", {}, payload));
        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.INITIALIZE_APPLICATION,
                event_properties: {
                    selection: AMPLITUDE_EVENTS.INITIALIZE_APPLICATION,
                    ...payload
                },
                user_properties: userProps
            })
        );
    };
};
