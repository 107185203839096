import { AxiosResponse } from "axios";
import StringUtil from "core-ui/client/src/app/StringUtil";

import { MaDetailsResponse } from "../types/common";
import { executeGet } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils";

interface PrimaryPlan {
    gaId: string;
    indId: string;
}

const getMADetails = async (primaryPlan: PrimaryPlan): Promise<object> => {
    try {
        const url = StringUtil.supplant(getUrl("getMADetails"), {
            gaId: primaryPlan.gaId,
            indId: primaryPlan.indId
        });
        const response: AxiosResponse<MaDetailsResponse> = await executeGet(url, "getMADetails");
        return response.data.data;
    } catch (err: any) {
        console.error("Error with service getMADetails: ", err);
        return err;
    }
};

export default getMADetails;
