import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import Parser from "html-react-parser";
import { map as _map } from "lodash";
import PropTypes from "prop-types";

import Backdrop from "../../backdrop";

import FEForecastEvents from "./FEForecastModalEvents";
class FEForecastModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            showAssumptions: false
        };

        this.closeFEForecastModal = this.closeFEForecastModal.bind(this);
        this.toggleFEAssumptions = this.toggleFEAssumptions.bind(this);
    }

    toggleFEAssumptions = function () {
        this.setState({
            showAssumptions: !this.state.showAssumptions
        });

        this.props.eventBus.dispatch(FEForecastEvents.CLICK_ASSUMPTIONS, this);
    };

    closeFEForecastModal = function () {
        this.setState({
            showAssumptions: false
        });
        this.props.handleCloseFE();
    };

    render() {
        const feForecastTranslations = selectTranslations("financialEngineForecast");
        if (!feForecastTranslations) {
            return null;
        }

        const title = feForecastTranslations["modal-text"].title;
        const incomeMsg1 = feForecastTranslations["modal-text"]["income-msg-1"];
        const incomeMsg2 = feForecastTranslations["modal-text"]["income-msg-2"];
        const forcastMsg1 = feForecastTranslations["modal-text"]["forecasts-msg-1"];
        const forcastMsg2 = feForecastTranslations["modal-text"]["forecasts-msg-2"];
        const assumptionlink = feForecastTranslations["modal-text"]["assumptions-link"];
        const closeBtn = feForecastTranslations["modal-text"]["close-btn"];

        const median = this.props.data
            ? this.props.data.feData
                ? this.props.data.feData.median > 0
                    ? this.props.data.feData.median
                    : 0
                : 0
            : 0;

        const formattedIncome = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
        }).format(Number(median));

        const updateStyleClass = {
            display: "block",
            paddingRight: "15px",
            overflowX: "hidden",
            overflowY: "auto"
        };

        const updateDialogClass = {
            zIndex: 3
        };

        const showBackdrop = this.props.visible ? (
            <Backdrop handleBackdropClick={this.props.handleBackdropClick} />
        ) : null;

        const generateMessage = (msgArr) => {
            return _map(msgArr, (item, index) => <p key={index}>{Parser(item)}</p>);
        };
        return !this.props.visible ? null : (
            <div
                className="modal fade model-open with-panel in"
                id="fe-forecast-modal"
                role="dialog"
                aria-labelledby="feForecastModalLabel"
                style={updateStyleClass}
                data-testid="fe-forecast-modal"
            >
                {showBackdrop}

                <div className="fe-forecast-dialog" style={updateDialogClass}>
                    <div className="fe-forecast-container">
                        <div className="modal-header">
                            <h1
                                className="modal-title"
                                id="feForecastModalLabel"
                                data-testid="fe-modal-title"
                            >
                                {title}
                            </h1>
                        </div>
                        <div className="fe-forecast-body">
                            <div className="fe-forecast-content">
                                <p className="fe-forecast-income-msg">
                                    {incomeMsg1}
                                    <span
                                        className="fe-forecast-currency"
                                        data-testid="fe-modal-currency"
                                    >
                                        {formattedIncome}
                                    </span>
                                    {incomeMsg2}
                                    <span data-testid="fe-modal-retireementage">
                                        {this.props.data.feData.retirementAge}
                                    </span>
                                    .
                                </p>
                            </div>
                            <div className="fe-forecast-disclosure">
                                <small className="text-muted" data-testid="fore-cast-percentage">
                                    {forcastMsg1}
                                    {this.props.data.feData.portfolioOneYearLossPercent}
                                    {forcastMsg2}
                                </small>
                            </div>
                            <span>
                                <div className="fe-assumptions-link-caret">
                                    {this.state.showAssumptions ? (
                                        <i
                                            className="fa fa-caret-down"
                                            aria-hidden="true"
                                            onClick={this.toggleFEAssumptions}
                                        />
                                    ) : (
                                        <i
                                            className="fa fa-caret-right"
                                            aria-hidden="true"
                                            onClick={this.toggleFEAssumptions}
                                        />
                                    )}
                                </div>

                                <a
                                    className="fe-forecast-assumptions-link"
                                    onClick={this.toggleFEAssumptions}
                                    data-testid="assumption-link"
                                >
                                    {assumptionlink}
                                </a>
                            </span>
                            {this.state.showAssumptions ? (
                                <div className="fe-forecast-assumptions">
                                    {generateMessage(this.props.data.assumptions)}
                                </div>
                            ) : null}
                        </div>

                        <div className="modal-footer">
                            <div className="footer-content">
                                <div className="close-btn-container">
                                    <button
                                        id="fe-forecast-btn"
                                        className="cancel-btn"
                                        type="button"
                                        onClick={this.closeFEForecastModal}
                                    >
                                        {closeBtn}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

FEForecastModal.propTypes = {
    translations: PropTypes.object,
    eventBus: PropTypes.any,
    visible: PropTypes.bool,
    data: PropTypes.any,
    handleCloseFE: PropTypes.func,
    handleBackdropClick: PropTypes.func
};

export default FEForecastModal;
