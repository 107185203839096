import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { ObjectUtil } from "gw-shared-components";
import { batch } from "react-redux";

import { updateProjectionSettings } from "../actions/applicationSettings/applicationSettingsActions.js";
import {
    reconfigureSummarySlider,
    updateCsorPercentDeferralsAfterSalaryChange
} from "../actions/deferrals/deferralActions.js";
import { setGoalSaving } from "../actions/goal/goalModalActionCreators.js";
import { splitOtherAssetsByEmployer } from "../actions/otherAssets/otherAssetsActions.js";
import { UPDATE_ASSETS_ESTIMATED_INCOME } from "../actions/otherAssets/otherAssetsTypes.js";
import {
    setDateOfBirth,
    setRetirementAge,
    updateParticipantSalary,
    updateParticipantIncomeGoal,
    updateHasReducedSalary,
    setParticipantAge
} from "../actions/participantDetails/participantDetailsActions.js";
import { updatePrimaryPlanSalary } from "../actions/plans/primaryPlanActions.js";
import {
    updateProjectedIncome,
    setIncomeGoalValue
} from "../actions/projectedIncome/projectedIncomeActions.js";
import {
    dispatchEventBus,
    setCurrentFault,
    dispatchAmplitude
} from "../actions/shared/sharedActionCreators.js";
import {
    setSpouseFirstName,
    setSpouseSalary,
    setSpouseGender,
    setSpouseAge,
    setSpouseDateOfBirth,
    setSpouseRetirementAge,
    deleteSpouse,
    updateSpouseIncomeGoal,
    setSpouse
} from "../actions/spouse/spouseActions.js";
import Deferrals from "../business/deferralsLogic/deferrals.js";
import MatchRuleTypes from "../constants/MatchRuleTypes.js";
import ReferenceData from "../constants/ReferenceData.js";
import EventBusEvents from "../events/eventBusEvents.js";
import { canShowHealthCareView } from "../selectors/featureFlagSelectors.js";
import {
    saveUserPreferencesService,
    updateSalaryReminderService,
    saveTermConfidence
} from "../services/userPreferencesService.js";
import { getAgeFromDateOfBirth } from "../utils/dateUtils.js";
import { handleFault } from "../utils/errorUtils.js";
import { getResponseFromData, hasTermConfidenceChangesOnly } from "../utils/goalUtils.js";
import { getPAEFault, isNotEmulator } from "../utils/paeUtils.js";

import { loadCompanyMatch } from "./companyMatchMiddleware.js";
import { loadHealthViewData } from "./healthCareMiddleware.js";
import { loadHowDoICompare } from "./howDoICompareMiddleware.js";
import { updateManagedAccounts } from "./investmentMiddleware.js";
import { loadNextSteps } from "./nextStepMiddleware.js";
import { updateProjectionFactors } from "./projectionFactorsMiddleware.js";
import { loadIntegratedSocialSecurity } from "./socialSecurityMiddleware.js";
import updateUsers from "./updateUsers";

export type SaveGoalChangesParams = {
    baselineScale: number;
    changeBooleans: ChangeBooleans;
    closeModal: () => void;
    confidence: number;
    goalValue: number;
    isPAE?: boolean;
    spouseDeleted: boolean;
    spouseInfo: SpouseInfo;
    term: 1 | 12;
    userInfo: UserInfo;
};

type SpouseInfo = {
    dataSource: string;
    dateOfBirth: string;
    firstName: string;
    gender: string;
    incomeGoalValue: number;
    incomeGoalValueUnits: string;
    investorId: string;
    pcapPersonId: string;
    personId: number;
    retirementAge: number;
    salary: number;
    type: string;
};

type ChangeBooleans = {
    additional: boolean;
    age: boolean;
    confidence: boolean;
    dob: boolean;
    gender: boolean;
    incomeGoal: boolean;
    name: boolean;
    newSpouseAdded: boolean;
    retirementAge: boolean;
    salary: boolean;
    spouseAdded: boolean;
    spouseDob: boolean;
    spouseGenderChanged: boolean;
    spouseIncomeGoal: boolean;
    spouseInfoChanged: boolean;
    spouseSalary: boolean;
    termOnly: boolean;
};

type UserInfo = {
    base: number;
    dateOfBirth: string;
    incomeGoalValue: number;
    incomeGoalValueUnits: string;
    type: string;
    variable: number;
};

export const updateParticipantSalaryReminder = () => {
    return (dispatch) => {
        updateSalaryReminderService();
        dispatch(updateParticipantSalary({ salaryReminder: false }));
    };
};

export const saveGoalChanges = function ({
    term,
    goalValue,
    baselineScale,
    userInfo,
    spouseInfo,
    changeBooleans,
    spouseDeleted,
    confidence,
    closeModal,
    isPAE = false // Bypass update if PAE; don't call save Methods if the user is not a participant
}: SaveGoalChangesParams) {
    return (dispatch, getState) => {
        dispatch(setGoalSaving(true));
        let termConfidenceUpdated = false;
        let state = getState();
        const { participant, primaryPlan } = state;
        const { clientId, individualId } = participant;
        const { id: planId } = primaryPlan;
        const hasReducedSalary = participant.hasReducedSalary;
        const termConfidenceOnly = hasTermConfidenceChangesOnly(changeBooleans);
        const originalSalary = state.baseline.salary;
        const originalBase = originalSalary.base;
        const originalVar = ObjectUtil.isUndefinedOrNull(originalSalary.variable)
            ? 0
            : originalSalary.variable;

        const callUpdateProjectionSettings = (term, confidence) => {
            if (confidence) {
                // this value needs to be set to get the correct van harlow factors
                dispatch(updateProjectionSettings({ confidence }));
            }

            if (term) {
                dispatch(updateProjectionSettings({ term }));
            }
        };

        const callUpdateIncome = (term) => {
            dispatch({
                type: UPDATE_ASSETS_ESTIMATED_INCOME,
                term,
                storeState: getState()
            });

            dispatch(updateProjectedIncome());
        };

        const handleEventBus = () => {
            const payload = {
                salaryChanged: changeBooleans.salary,
                additionalCompensationChanged: changeBooleans.additional,
                incomeGoalChanged: changeBooleans.incomeGoal,
                viewChanged: changeBooleans.termOnly,
                confidenceChanged: changeBooleans.confidence,
                spouseSalaryChanged: changeBooleans.spouseSalary,
                spouseIncomeGoalChanged: changeBooleans.spouseIncomeGoal,
                spouseGenderChanged: changeBooleans.spouseGenderChanged
            };
            dispatch(dispatchEventBus(EventBusEvents.LIAT.GOAL_MODAL_SAVED, payload));
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: EventBusEvents.LIAT.GOAL_MODAL_SAVED,
                        ...payload
                    }
                })
            );
        };

        const savePreferencesResult = (result) => {
            batch(async () => {
                const userSalaryObj = {
                    base: ObjectUtil.isNull(userInfo.base)
                        ? participant.salary.salaryOnFile
                        : userInfo.base,
                    variable: userInfo.variable,
                    total: (userInfo.base || 0) + (userInfo.variable || 0)
                };

                if (userInfo) {
                    if (
                        (result.salaryUpdateRequired || result.variableUpdateRequired) &&
                        term &&
                        !ObjectUtil.isUndefinedOrNull(goalValue)
                    ) {
                        dispatch(updateParticipantSalary(userSalaryObj));
                        dispatch(updatePrimaryPlanSalary(userSalaryObj));
                        dispatch(updateProjectionSettings({ term }));
                        dispatch(setIncomeGoalValue(goalValue));
                    }

                    if (userInfo.incomeGoalValue) {
                        // this sets the percentage of salary, which is used in the hub call, to the value chosen in the combo box
                        dispatch(updateParticipantIncomeGoal({ value: userInfo.incomeGoalValue }));
                    }

                    if (userInfo.incomeGoalValueUnits) {
                        // this sets the percentage of salary, which is used in the hub call, to the value chosen in the combo box
                        dispatch(
                            updateParticipantIncomeGoal({
                                valueUnits: userInfo.incomeGoalValueUnits
                            })
                        );
                    }

                    if (changeBooleans.newSpouseAdded) {
                        const spouseConfig = {
                            ...spouseInfo,
                            incomeGoal: {
                                value: spouseInfo.incomeGoalValue,
                                valueUnits: spouseInfo.incomeGoalValueUnits
                            },
                            salary: {
                                base: spouseInfo.salary,
                                total: spouseInfo.salary
                            },
                            personId: result.spousePersonId || "",
                            investorId: result.spouseInvestorId || "",
                            adjustedLEAge:
                                result.spouseLifeExpectancy || ReferenceData.MAX_RETIREMENT_AGE
                        };

                        spouseConfig.pcapPersonId = String(result.spousePersonId) || "";

                        dispatch(setSpouse(spouseConfig));
                    } else {
                        if (spouseInfo) {
                            if (spouseInfo.incomeGoalValue) {
                                dispatch(
                                    updateSpouseIncomeGoal({ value: spouseInfo.incomeGoalValue })
                                );
                            }

                            if (spouseInfo.incomeGoalValueUnits) {
                                dispatch(
                                    updateSpouseIncomeGoal({
                                        valueUnits: spouseInfo.incomeGoalValueUnits
                                    })
                                );
                            }
                        }
                    }
                }

                if (!termConfidenceUpdated) {
                    callUpdateProjectionSettings(term, confidence);
                }

                if (result.salaryUpdateRequired) {
                    dispatch(updateParticipantSalary(userSalaryObj));
                    dispatch(updatePrimaryPlanSalary(userSalaryObj));
                    dispatch(setIncomeGoalValue(goalValue));

                    if (hasReducedSalary) {
                        dispatch(updateHasReducedSalary(false));
                    }
                }

                // delete spouse if necessary
                if (result.spouseDeleted.deleted) {
                    if (result.spouseDeleted.error) {
                        dispatch(
                            setCurrentFault({
                                code: result.spouseDeleted.errorCode,
                                message: result.spouseDeleted.error,
                                stayOnCurrentPage: true
                            })
                        );
                    } else {
                        dispatch(deleteSpouse());
                        dispatch(splitOtherAssetsByEmployer());
                    }
                }

                // if the DOB was changed we need to get new projection factors,
                // update age and retirement age (if age is after or on current retirement age)
                if (result.dateOfBirthUpdateRequired) {
                    dispatch(setDateOfBirth(userInfo.dateOfBirth));
                    const age = getAgeFromDateOfBirth(userInfo.dateOfBirth);
                    dispatch(setParticipantAge(age));
                    if (age && age >= participant.retirementAge) {
                        dispatch(setRetirementAge(age + 1));
                    }
                }

                //cases for updating projection factors.  must come after update of user age
                if (
                    changeBooleans.newSpouseAdded ||
                    result.dateOfBirthUpdateRequired ||
                    result.spousalDateOfBirthUpdateRequired ||
                    (result.spousalGenderUpdateRequired && !isPAE)
                ) {
                    await dispatch(updateProjectionFactors(false));
                }

                if (result.spousalNameUpdateRequired) {
                    dispatch(setSpouseFirstName(spouseInfo.firstName));
                }
                if (result.spousalSalaryUpdateRequired) {
                    dispatch(
                        setSpouseSalary({
                            base: spouseInfo.salary,
                            total: spouseInfo.salary
                        })
                    );
                }

                if (result.spousalGenderUpdateRequired) {
                    dispatch(setSpouseGender(spouseInfo.gender));
                }

                if (result.spousalDateOfBirthUpdateRequired) {
                    dispatch(setSpouseDateOfBirth(spouseInfo.dateOfBirth));
                    dispatch(setSpouseAge(getAgeFromDateOfBirth(spouseInfo.dateOfBirth)));
                }

                if (result.spousalRetAgeUpdateRequired) {
                    dispatch(setSpouseRetirementAge(spouseInfo.retirementAge));
                }

                // Cases in which we need to get a new government benefits map
                // To Do: update this once ss logic is in the hub, should be a smaller set
                if (
                    result.salaryUpdateRequired ||
                    result.spousalSalaryUpdateRequired ||
                    result.dateOfBirthUpdateRequired ||
                    result.spousalDateOfBirthUpdateRequired
                ) {
                    const governmentBenefitsFault = function (fault) {
                        dispatch(setCurrentFault(fault));
                    };

                    const migratedBenefitsResult = function () {
                        dispatch(updateProjectedIncome());
                    };

                    // Emulator will not update its value for Social security. The getSocialSecuriy service will not
                    // send updated values back for the emulutor since changes are not saved. When a participant does an actual change,
                    // the social security value will be updated and reflected in the display.
                    dispatch(loadIntegratedSocialSecurity()).then(
                        migratedBenefitsResult,
                        governmentBenefitsFault
                    );
                }

                if (result.salaryUpdateRequired) {
                    // we need to get new health view data since the salary changed and new government benefits
                    if (canShowHealthCareView(state.participant, state.primaryPlan)) {
                        dispatch(loadHealthViewData());
                    }
                    //if they are migrated and have a default salary and cannot get to managed accounts, update
                    const ma = state.primaryPlan.managedAccounts;
                    if (
                        ma &&
                        !(
                            ma.groupServiceRules &&
                            ma.groupServiceRules.allowFullManagedAccounts === true &&
                            ma.eligibleForManagedAccounts
                        ) &&
                        state.primaryPlan.salary.source === "DEF" &&
                        !isPAE
                    ) {
                        dispatch(
                            updateManagedAccounts(state.participant.individualId, state.primaryPlan)
                        );
                    }
                }

                //only update projections if they haven't already been updated
                if (
                    result.updateProjectedIncomeNeeded ||
                    result.percentageOfSalaryUpdateRequired ||
                    result.spousalPercentageOfSalaryUpdateRequired ||
                    !(
                        result.spouseAdded ||
                        result.salaryUpdateRequired ||
                        result.variableUpdateRequired ||
                        result.percentageOfSalaryUpdateRequired ||
                        result.dateOfBirthUpdateRequired
                    )
                ) {
                    let skipUpdate = false;
                    if (
                        result.spouseAdded &&
                        result.spousalSalaryUpdateRequired &&
                        result.spousalDateOfBirthUpdateRequired
                    ) {
                        // update for hub calc done on completion of social security logic
                        skipUpdate = true;
                    }

                    // call hub to get projected income and update the data model
                    if (!skipUpdate) {
                        dispatch(updateProjectedIncome(true));
                    }
                }

                if (
                    (result.salaryUpdateRequired || result.dateOfBirthUpdateRequired) &&
                    state.primaryPlan.planRules.hdicEnabled
                ) {
                    dispatch(loadHowDoICompare());
                }

                if (result.salaryUpdateRequired || result.variableUpdateRequired) {
                    dispatch(reconfigureSummarySlider());
                    //call new bns based on new salary / limits
                    dispatch(loadNextSteps());
                    //call new company match ---- only if not CSOR
                    const hasCsorCompanyMatch = function () {
                        return (
                            !ObjectUtil.isUndefinedOrNull(state.primaryPlan.companyMatch) &&
                            state.primaryPlan.companyMatch.ruleId ===
                                MatchRuleTypes.COMPANY_MATCH_CSOR
                        );
                    };

                    const deferrals = state.baseline.deferrals;
                    const primaryPlan = state.primaryPlan;
                    const deferralSetupConfig = state.deferralSetupConfig;
                    const deferralLogic = new Deferrals(
                        deferrals,
                        primaryPlan,
                        deferralSetupConfig
                    );
                    if (
                        !state.primaryPlan.planRules.usePayrollEmployerMatch &&
                        !hasCsorCompanyMatch()
                    ) {
                        dispatch(loadCompanyMatch(userSalaryObj.total)).then(() => {
                            dispatch(updateProjectedIncome(true));
                        });
                    }
                    //CSOR - Check for CSOR deferrals - on a salary change, we need to update CSOR percent deferrals because thier
                    // original values were calculated based on dollar amounts

                    if (deferralLogic.isTotalEstimated()) {
                        const config = {
                            originalBase: originalBase,
                            originalVar: originalVar,
                            base: userInfo.base,
                            var: userInfo.variable
                        };
                        dispatch(updateCsorPercentDeferralsAfterSalaryChange(config));
                    }
                }

                if (
                    changeBooleans.termOnly ||
                    changeBooleans.confidence ||
                    result.salaryUpdateRequired ||
                    result.spousalSalaryUpdateRequired ||
                    result.dateOfBirthUpdateRequired ||
                    result.spousalDateOfBirthUpdateRequired ||
                    result.spousalRetAgeUpdateRequired
                ) {
                    let skipUpdate = false;
                    if (
                        result.spouseAdded &&
                        result.spousalSalaryUpdateRequired &&
                        result.spousalDateOfBirthUpdateRequired
                    ) {
                        // update for hub calc done on completion of social service calls
                        skipUpdate = true;
                    }
                    if (!skipUpdate) {
                        callUpdateIncome(term);
                    }
                }

                if (isPAE) {
                    const paeFault = getPAEFault();
                    dispatch(
                        setCurrentFault({
                            ...paeFault,
                            stayOnCurrentPage: true
                        })
                    );
                }

                handleEventBus();
                dispatch(setGoalSaving(false));

                if (typeof closeModal === "function") {
                    closeModal();
                }

                // get the latest state before calling updateUsers
                state = getState();
                dispatch(updateUsers([state.participant, state.spouse], isPAE));
            });
        };
        const savePreferencesFault = function (fault) {
            batch(() => {
                if (String(fault.message).indexOf("Save Spouse") > -1) {
                    dispatch(deleteSpouse());
                }
                if (fault.request || fault.config) {
                    dispatch(handleFault(fault));
                }
                if (fault.error) {
                    const error = {
                        code: "PRF-" + fault.error.code,
                        message: fault.error.message || fault.error.userMessage
                    };
                    dispatch(setCurrentFault(error));
                } else if (fault.isPAEError) {
                    dispatch(
                        setCurrentFault({
                            ...fault,
                            stayOnCurrentPage: true
                        })
                    );
                }
                dispatch(setGoalSaving(false));

                if (typeof closeModal === "function") {
                    closeModal();
                }

                state = getState();
                dispatch(updateUsers([state.participant, state.spouse], isPAE));
            });
        };

        let proceedParticipantRequest;
        let proceedTermConfidenceRequest;
        let saveTermConfidenceConfig;
        let saveIntMigConfig;

        // For integrated ppts, term and confidence are saved separately
        if (changeBooleans.termOnly || changeBooleans.confidence) {
            const save = (config) => saveTermConfidence(config);

            if (termConfidenceOnly && isPAE) {
                saveIntMigConfig = {
                    changeBooleans,
                    confidence,
                    spouseDeleted
                };
            }

            saveTermConfidenceConfig = {
                actionCode: "UPDATE_USER_PREF",
                clientId,
                planId,
                individualId
            };

            if (changeBooleans.termOnly) {
                saveTermConfidenceConfig.goalTerm = term;
            }

            if (changeBooleans.confidence) {
                saveTermConfidenceConfig.confidence = confidence;
            }

            proceedTermConfidenceRequest = () => {
                return save(saveTermConfidenceConfig).then(() => {
                    callUpdateProjectionSettings(term, confidence);
                    callUpdateIncome(term);

                    if (isPAE) {
                        const paeFault = getPAEFault();
                        dispatch(
                            setCurrentFault({
                                ...paeFault,
                                stayOnCurrentPage: true
                            })
                        );
                    }

                    termConfidenceUpdated = true;
                }, savePreferencesFault);
            };
        }

        // If integrated and only term and confidence have changed, we don't want to call saveMigratedPreferences
        // If not integrated the save flow will proceed as normal
        if (!termConfidenceOnly) {
            saveIntMigConfig = {
                term,
                goalValue,
                baselineScale,
                userInfo,
                spouseInfo,
                changeBooleans,
                spouseDeleted,
                user: state.participant,
                primaryPlan: state.primaryPlan,
                confidence,
                spouse: state.spouse
            };

            if (state.spouse && saveIntMigConfig.spouseInfo && !spouseDeleted) {
                saveIntMigConfig.spouseInfo.investorId = state.spouse.investorId;
                saveIntMigConfig.spouseInfo.personId = state.spouse.personId;
            }

            if (spouseDeleted) {
                saveIntMigConfig.spousePersonId = state.spouse.personId;
            }

            proceedParticipantRequest = () => {
                return saveUserPreferencesService(individualId, saveIntMigConfig).then(
                    savePreferencesResult,
                    savePreferencesFault
                );
            };
        }

        return isNotEmulator()
            .then(() => {
                if (proceedParticipantRequest) {
                    proceedParticipantRequest();
                }

                if (proceedTermConfidenceRequest) {
                    proceedTermConfidenceRequest();
                }

                return Promise.resolve();
            })
            .catch((err) => {
                const response = getResponseFromData(saveIntMigConfig);
                savePreferencesResult(response);
                console.error(err);
            });
    };
};
