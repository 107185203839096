import React, { useEffect, useState } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import DateUtil from "core-ui/client/src/app/DateUtil";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
    getPlanIrsCodeForDisplay,
    getProfitSharingDescriptionsExistForPlan
} from "../nextStepHelpers";

import { CompanyMatchTitle, CompanyMatchMessage } from "./index";

const CompanyMatch = ({ expandPaycheckOpen }) => {
    const translations = selectTranslations("bestNextStep");

    const participant = useSelector((state) => state.participant);
    const plans = participant.plans;
    const nextSteps = useSelector((state) => state.nextSteps);
    const matchAndProfitSharingMessages = nextSteps.companyMatch.matchAndProfitSharingMessages;
    /**
     * Combines shouldShowMatch and shouldShowProfitSharing
     * Reads messages array returned from hub
     * Each element represents plan object with following properties
     * {planId: "", match: [], profitSharing: []}
     * @param {boolean} isProfitSharing - used to determine key name
     * @param {number} id - location in plan list
     */
    const shouldShowMatchOrProfitSharing = (isProfitSharing, planId) => {
        if (matchAndProfitSharingMessages.length === 0) return false;
        let hasDesc = false;
        const keyName = isProfitSharing ? "profitSharing" : "match";
        const messages = matchAndProfitSharingMessages;

        const messageObj = messages.filter((message) => {
            return planId === message.planId;
        })[0];

        if (messageObj && messageObj[keyName]) {
            if (messageObj[keyName].length) {
                hasDesc = true;
            }
        }

        return hasDesc;
    };

    const [displayCompanyMatch, setDisplayCompanyMatch] = useState(expandPaycheckOpen);

    useEffect(() => {
        setDisplayCompanyMatch(expandPaycheckOpen);
    }, [expandPaycheckOpen]);

    return plans.map((plan, id) => {
        const indx = matchAndProfitSharingMessages.findIndex(function (o) {
            return o.planId === plan.id;
        });
        let messageArray = [];
        if (indx === -1) {
            return;
        }
        messageArray = matchAndProfitSharingMessages[indx];

        return (
            displayCompanyMatch && (
                <div data-testid="company-match" key={plan.id}>
                    {shouldShowMatchOrProfitSharing(false, plan.id) ? (
                        <div className="company-match-content">
                            <div>
                                <CompanyMatchTitle plan={plan} />
                                <ul className="trade-offs list-unstyled company-match-messages">
                                    <CompanyMatchMessage
                                        matchList={messageArray.match}
                                        parentId={id}
                                        type="match"
                                    />
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {shouldShowMatchOrProfitSharing(true, plan.id) ? (
                        <div className="company-match-content">
                            <h3 className="company-match-message-title">
                                {`${getPlanIrsCodeForDisplay(plan.irsCode)} - ${
                                    translations.profitSharingHeader
                                }`}
                            </h3>
                            <ul
                                className="trade-offs list-unstyled company-match-messages"
                                aria-label={`${translations.contributionListAriaLabel}`}
                                role="listbox"
                            >
                                <CompanyMatchMessage
                                    matchList={messageArray.profitSharing}
                                    parentId={id}
                                    type="tradeOffPs"
                                />
                                {plan.seniorityDate && (
                                    <li
                                        className="company-match-message"
                                        aria-label={`${translations.seniorityDateLabel} ${DateUtil.getDateFormatted("M/D/YYYY", plan.seniorityDate)}`}
                                        tabIndex="0"
                                        role="option"
                                        data-testid="seniority-date-option"
                                    >
                                        <span className="company-match-text">
                                            &nbsp;
                                            {`${translations.seniorityDateLabel} ${DateUtil.getDateFormatted("M/D/YYYY", plan.seniorityDate)}`}
                                        </span>
                                    </li>
                                )}
                            </ul>

                            {!getProfitSharingDescriptionsExistForPlan(id, plans) ? (
                                <li className="company-match-message">
                                    <i className="fa fa-solid fa-circle-check gray-color-400" />
                                    <span className="company-match-text">
                                        {translations.profitSharingDefault}
                                    </span>
                                </li>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            )
        );
    });
};

CompanyMatch.propTypes = {
    expandPaycheckOpen: PropTypes.bool.isRequired
};

export { CompanyMatch };
