import React, { memo, useEffect, StrictMode } from "react";

import { withProfiler } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import qualtrics from "core-ui/client/src/app/core/qualtrics/Qualtrics";
import coreuiReactComponents from "core-ui/dist/core-ui-react-components";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import Application from "./Application";
import { headerStoreContext } from "./contexts";
import { DEFAULT_STALE_TIME } from "./queries/constants";
import { store } from "./store/store";

const { headerStore, HeaderViewProviderComponent, FooterViewProviderComponent } =
    coreuiReactComponents;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: DEFAULT_STALE_TIME
        }
    }
});
const ApplicationContainer = () => {
    useEffect(() => {
        qualtrics();
    }, []);

    return (
        <div id="main-page" className="redwood-container" style={{ minHeight: "100vh" }}>
            <StrictMode>
                <Router>
                    <CompatRouter>
                        <HeaderViewProviderComponent
                            store={headerStore}
                            externalContext={headerStoreContext}
                            queryClient={queryClient}
                        />
                        <QueryClientProvider client={queryClient}>
                            <Provider store={headerStore} context={headerStoreContext}>
                                <Provider store={store}>
                                    <Application />
                                </Provider>
                            </Provider>
                            {process.env.NODE_ENV === "development" && (
                                <ReactQueryDevtools initialIsOpen={false} />
                            )}
                        </QueryClientProvider>
                        <FooterViewProviderComponent />
                    </CompatRouter>
                </Router>
            </StrictMode>
        </div>
    );
};

export default withProfiler(memo(ApplicationContainer), { name: "ApplicationContainer" });
