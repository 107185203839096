import { equityMixChangesAllowed } from "..";

interface IndividualOptions {
    allowAllocationsUpdate: boolean;
    currentAllocationModel: boolean;
    disableEquityMix: boolean;
    doMoneyTypeGroupingsApply: boolean;
    doTradeRestrictionsApply: null;
    hasAllMoneyTypeGroup: boolean;
    isPortfolioManagementAllowed: boolean;
    modelName: null;
}
/**
 * Can submit investment mix - if this returns false, the investment mix slider is model only.
 * Look in the retirementSavingsService to change when the slider is model only, this function
 * is used to hide helpMeDoIt in the case of model only investments.
 * @returns {boolean}
 */
const canSubmitEquityMix = (individualOptions: IndividualOptions) => {
    return equityMixChangesAllowed(individualOptions);
};

export default canSubmitEquityMix;
