import React from "react";

import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

const CustomInput = ({ translations, handleCustomAmount, monthlyAmountOverride }) => {
    return (
        <div className="form-group social-security-custom-amount">
            <label
                className="input-header control-label text-tranform-lowercase"
                htmlFor="ownAmount"
            >
                {translations.details.amountLabel}
            </label>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <label className="dollar-sign-inside-input"> $</label>
                    <NumericFormat
                        autoComplete={"off"}
                        maxLength={12}
                        thousandSeparator={true}
                        decimalScale={2}
                        allowNegative={false}
                        className="form-control"
                        id="social-security-participant-own-benefit-amount-txt"
                        name="personOverrideValue"
                        value={monthlyAmountOverride}
                        onValueChange={({ formattedValue }) => {
                            handleCustomAmount(formattedValue);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
CustomInput.propTypes = {
    translations: PropTypes.object,
    handleCustomAmount: PropTypes.func,
    monthlyAmountOverride: PropTypes.number
};
export default CustomInput;
