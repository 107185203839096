import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { OTHER_ASSETS_ROUTE, SOCIAL_SECURITY_ROUTE } from "../../../routes";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";

const ConsiderationsTabView = ({
    hasOtherAssets,
    hasSocialSecurity,
    isEnrolledInFullManagedAccounts,
    isEligibleForManagedAccounts,
    isEnrolledInAdvisorManagedAccounts = false
}) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("preRetiree")?.modal?.tabs?.considerations || "";
    const { hereToHelp, otherAssets, socialSecurity } = translations;

    const isIbbotson = useSelector(
        (state) =>
            state.investments.managedAccountsOptions.advisoryServiceProviderCode === "IBBOTSON"
    );

    const hereToHelpmessage = () => {
        const message = isEnrolledInFullManagedAccounts
            ? hereToHelp.enrolledMessage
            : hereToHelp.notEnrolledMessage;
        const phoneLine = isEnrolledInFullManagedAccounts
            ? hereToHelp.enrolledPhoneNumberLine
            : hereToHelp.notEnrolledPhoneNumberLine;
        return (
            <div className="tab-message-content">
                <p>
                    <strong>{hereToHelp.header}</strong>
                </p>
                <p>{message}</p>
                <p>{phoneLine}</p>
            </div>
        );
    };

    return (
        <div>
            {hasOtherAssets && (
                <div className="tab-message-content">
                    <p>
                        <strong>{otherAssets.header}</strong>
                    </p>
                    <p
                        onClick={() => {
                            dispatch(
                                dispatchEventBus(
                                    EventBusEvents.PRERETIREE.OTHER_ASSETS_LINK_CLICK_EVENT,
                                    this
                                )
                            );
                            dispatchAmplitude({
                                eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
                                selection: EventBusEvents.PRERETIREE.OTHER_ASSETS_LINK_CLICK_EVENT
                            });
                        }}
                    >
                        {otherAssets.message1}
                        <NavLink to={OTHER_ASSETS_ROUTE}>{otherAssets.link}</NavLink>
                        {otherAssets.message2}
                    </p>
                </div>
            )}

            {hasSocialSecurity && (
                <div className="tab-message-content">
                    <p>
                        <strong>{socialSecurity.header}</strong>
                    </p>
                    <p
                        onClick={() => {
                            dispatch(
                                dispatchEventBus(
                                    EventBusEvents.PRERETIREE.SOCIAL_SECURITY_LINK_CLICK_EVENT,
                                    this
                                )
                            );
                            dispatch(
                                dispatchAmplitude({
                                    event_type: AMPLITUDE_EVENTS.SELECT_LINK,
                                    event_properties: {
                                        selection:
                                            EventBusEvents.PRERETIREE
                                                .SOCIAL_SECURITY_LINK_CLICK_EVENT
                                    }
                                })
                            );
                        }}
                    >
                        {socialSecurity.message1}
                        <NavLink to={SOCIAL_SECURITY_ROUTE}>{socialSecurity.link}</NavLink>
                        {socialSecurity.message2}
                    </p>
                </div>
            )}
            {isIbbotson &&
                (isEnrolledInFullManagedAccounts || isEligibleForManagedAccounts) &&
                !isEnrolledInAdvisorManagedAccounts &&
                hereToHelpmessage()}
        </div>
    );
};

ConsiderationsTabView.propTypes = {
    hasOtherAssets: PropTypes.bool,
    hasSocialSecurity: PropTypes.bool,
    isEnrolledInFullManagedAccounts: PropTypes.bool,
    isEligibleForManagedAccounts: PropTypes.bool,
    isEnrolledInAdvisorManagedAccounts: PropTypes.bool
};

export default ConsiderationsTabView;
