/* eslint-disable etc/no-commented-out-code */
const noop = () => {
    // no operation
};
/**
 *
 * @class common.service.ServiceRegister
 * @date 12/19/2014
 * @copyright 2015 Empower Retirement
 * @description
 *
 *      Registry of RESTful service endpoints. A hash of all the services that allows easy endpoint look-ups.
 *      Note that each endpoint has both a mock and real, live URL.
 *
 *      The URL method for each service is populated by the Service Locator during its registration phase,
 *      so the noop function is used as a placeholder. It's populated by determining if the app or individual
 *      endpoint is using mocks and then uses the service definition's `live` or `mock` property.
 *
 *      The mock URL is used if the query string is "?useMocks=true". This is really a developers tool. However,
 *      that can be overridden with the `forceMock` property within each service endpoint definition. If this
 *      is true it'll override the query string parameter and use the mock no matter what. This allows individual
 *      endpoints the option to use mock if they are experiencing troubles with the real API.
 */
const ServiceRegister = {
    authenticateGW: {
        mock: "authenticate",
        live: "rest/nonauth/authenticate",
        url: noop,
        forceMock: false
    },

    // advicePage: {
    //     mock: "mock-advice_page.json",
    //     live: "rest/retirementIncomeView/getLiatAdvice",
    //     url: noop,
    //     forceMock: false
    // },

    verifyCode: {
        mock: "verify-code.json",
        live: "rest/partialauth/mfa/verifycode",
        url: noop,
        forceMock: false
    },

    authenticatePutnam: {
        mock: "authenticate",
        live: "ws/liatService/authenticate",
        url: noop,
        forceMock: false
    },

    getInitData: {
        mock: "get-init-data.json",
        live: "rest/retirementIncomeView/getHouseholdInitData",
        url: noop,
        forceMock: false
    },

    getIntegratedInitData: {
        mock: "get-init-integrated-data.json",
        live: "rest/retirementIncomeView/getHouseholdInitData",
        url: noop,
        forceMock: false
    },

    saveHsaPreferences: {
        mock: "save-preferences.json",
        live: "rest/overrides/saveHSAInfo",
        url: noop,
        forceMock: false
    },

    savePreferences: {
        mock: "save-preferences.json",
        live: "rest/overrides/saveOrUpdate",
        url: noop,
        forceMock: false
    },

    saveOtherAssets: {
        mock: "save-other-assets.json",
        live: "rest/outsideAssets/processAssets",
        url: noop,
        forceMock: false
    },

    saveMigratedRetirementAsset: {
        mock: "save-migrated-retirement-asset.json",
        live: "/managedAccountParticipants/retirementIncomes/{investorId}{urlEnding}",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    // getSocialSecurityMap: {
    //     mock: "get-social-security-map.json",
    //     live: "rest/retirementIncomeView/projections/getSSProjections",
    //     url: noop,
    //     forceMock: false
    // },

    getSubjectToFeva: {
        mock: "get-is-subject-to-feva.json",
        live: "rest/group/isSubjectToFEVA",
        url: noop,
        forceMock: false
    },

    saveSocialSecurity: {
        mock: "save-migrated-social-security.json",
        live: "/managedAccountParticipants/socialSecurity/{investorId}/benefits",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    // getMigratedSocialSecurity: {
    //     mock: "get-migrated-social-security.json",
    //     live: "/managedAccountParticipants/socialSecurity/{investorId}",
    //     url: noop,
    //     forceMock: false,
    //     isAsdb: true
    // },

    getIntegratedSocialSecurity: {
        mock: "get-integrated-social-security.json",
        live: "/api/person/getSocialSecurityIncome",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    getFunnelAttributes: {
        mock: "get-funnel-attributes.json",
        live: "/api/profile/getFunnelAttributes",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    /** DEPRECATED FOR LIAT 2.0 */
    /*getProjectionFactors: {
        mock: "get-projection-factors.json",
        live: "rest/retirementIncomeView/projections/getVHProjections",
        maxLive: "/projections/getProjectionFactors",
        url: noop,
        forceMock: false
    },

    getHouseholdProjectionFactors: {
        mock: "get-household-projection-factors.json",
        live: "rest/retirementIncomeView/projections/getHouseholdProjectionFactors",
        maxLive: "/projections/getHouseholdProjectionFactors",
        url: noop,
        forceMock: false,
        isAsdb: true
    },*/

    getRetireeProjectionFactors: {
        mock: "get-retiree-projection-factors.json",
        live: "rest/retirementIncomeView/projections/getRetireeProjectionFactors",
        url: noop,
        forceMock: false
    },

    getHealthViewData: {
        mock: "get-health-view-combined-data.json",
        live: "rest/healthViewService/getHealthViewData",
        url: noop,
        forceMock: false
    },

    // getMigratedHealthViewData: {
    //     mock: "get-health-view-combined-data.json",
    //     live: "/healthCareCosts/getHVSData/{personId}",
    //     maxLive: "/healthcarecosts/getHVSData/{personId}", //shouldn't be needed, since isASDB is true
    //     url: noop,
    //     forceMock: false,
    //     isASDB: true
    // },

    // saveHealthViewData: {
    //     mock: "save-health-view-data.json",
    //     live: "rest/healthViewService/saveSurveyData",
    //     url: noop,
    //     forceMock: false
    // },

    saveHealthViewDataIntegrated: {
        mock: "save-health-view-data.json",
        live: "rest/healthViewService/saveSurveyData",
        url: noop,
        forceMock: false
    },

    getHealthViewPDF: {
        mock: "health-costs.pdf?retirementAge={0}&salary={1}",
        live: "rest/healthViewService/getHealthViewPDF?retirementAge={0}&salary={1}",
        //maxLive: "/healthCareCosts/getHealthViewPDF/{2}?retirementAge={0}&salary={1}", -Not currently ready
        url: noop,
        forceMock: false
    },

    getHowDoICompareData: {
        mock: "get-hdic-data.json",
        live: "rest/hdicService/getHDICData",
        url: noop,
        forceMock: false
    },

    getInvestmentServices: {
        mock: "get-investment-services.json",
        live: "rest/investments/services/retirementincome/{individualId}/{groupId}/overview",
        url: noop,
        forceMock: false
    },

    getInvestmentsCurrent: {
        mock: "get-investments-current.json",
        live: "rest/investments/{individualId}/{groupId}/current",
        url: noop,
        forceMock: false
    },

    saveDeferral: {
        mock: "save-deferral.json",
        live: "rest/deferrals/saveDeferrals/{groupId}",
        url: noop,
        forceMock: false
    },

    getDeferrals: {
        mock: "get-deferrals.json",
        live: "rest/retirementIncomeView/getDeferrals",
        url: noop,
        forceMock: false
    },
    getDeferralsByGroupID: {
        mock: "get-deferral-info.json",
        live: "rest/deferrals/getDeferrals/{groupId}",
        url: noop,
        forceMock: false
    },
    getGlobalApi: {
        // mock: "get-deferral-info.json",
        live: "/rest/globalIdService/getIndIdGaId?personId={personId}&accountId={accountId}",
        url: noop,
        forceMock: false
    },

    // @TODO REMOVE ALL REFERENCE TO BNS ONCE MIGRATED OVER
    getBestNextStep: {
        mock: "get-best-next-step.json",
        live: "rest/retirementIncomeView/getBestNextStep",
        url: noop,
        forceMock: false
    },

    getNextStep: {
        mock: "get-best-next-step.json",
        live: "rest/retirementIncomeView/getBestNextStep",
        url: noop,
        forceMock: false
    },

    // @TODO REMOVE ALL REFERENCE TO BNS ONCE MIGRATED OVER
    getMigratedBestNextStep: {
        mock: "get-migrated-best-next-step.json",
        live: "/managedAccountParticipants/bestNextStep/topic/NEXT_STEPS/channel/NEXTGENPW/subjectKey/{investorId}/subjectKeyType/EASY_IND/limit/1/createViewEvent/true",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    getMigratedNextStep: {
        mock: "get-migrated-best-next-step.json",
        live: "/managedAccountParticipants/bestNextStep/topic/NEXT_STEPS/channel/NEXTGENPW/subjectKey/{investorId}/subjectKeyType/EASY_IND/limit/1/createViewEvent/true",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    getFEData: {
        mock: "mock-fe-data.json",
        live: "rest/retirementIncomeView/getFEData",
        url: noop,
        forceMock: false
    },

    getAdvisoryMessaging: {
        mock: "get-advisory-messaging.json",
        live: "rest/advisoryServices/managedAccountParticipants/advisorymessaging/indId/{individualId}/group/{groupId}?pageCode={pageCode}&treatment={treatment}",
        url: noop,
        forceMock: false
        // isAsdb: true
    },

    getCompanyMatch: {
        mock: "get-company-match.json",
        live: "rest/companyMatchRule/getCompanyMatchRuleForSalary/{annualSalary}",
        url: noop,
        forceMock: false
    },

    getSamlAssertionForMax: {
        mock: "get-saml-assertion-for-max.json",
        live: "rest/rivLandingSamlService/getRIVLandingSamlResponse",
        url: noop,
        forceMock: false
    },

    setASDBCookie: {
        mock: "set-asdb-cookie.json",
        live: "/managedAccountParticipants/dashboard/entry",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    managedAccountsProgramName: {
        mock: "managed-accounts-program-name.json",
        live: "/managedAccountParticipants/programName/{investorId}/managedAccountsProgramName",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    saveMigratedPreferences: {
        mock: "save-preferences.json",
        live: "/managedAccountParticipants/aboutMe/person",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    // saveSpouse: {
    //     mock: "create-spouse.json",
    //     live: "/managedAccountParticipants/aboutMyFamily/{investorId}/familyDetails",
    //     url: noop,
    //     forceMock: false,
    //     isAsdb: true
    // },

    updatePersonLite: {
        mock: "update-person.json",
        live: "/api/person/updatePersonLite",
        url: noop,
        forceMock: false,
        isPcapService: true
    },

    updateCredentials: {
        mock: "post-update-credentials.json",
        live: "rest/partialauth/profile/updateCredentials",
        url: noop,
        forceMock: false
    },

    getRetriveProfileInfo: {
        mock: "get-retriveProfileInfo.json",
        live: "rest/partialauth/profile/retriveProfileInfo",
        url: noop,
        forceMock: false
    },

    createPersonLite: {
        mock: "create-person.json",
        live: "/api/person/createPersonLite",
        url: noop,
        forceMock: false,
        isPcapService: true
    },

    deletePerson: {
        mock: "delete-person.json",
        live: "/api/person/deletePerson",
        url: noop,
        forceMock: false,
        isPcapService: true
    },

    getIntegratedBenefits: {
        mock: "get-integrated-benefits.json",
        live: "/api/person/getSocialSecurityIncome",
        url: noop,
        forceMock: false,
        isPcapService: true
    },

    getFundTicker: {
        mock: "fund-ticker.json",
        live: "/managedAccountParticipants/providerFunds/{searchParam}",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    getTOS: {
        mock: "get-terms-of-service.json",
        live: "/accountAggregation/termsOfService/investorId/{investorId}",
        url: noop,
        forceMock: false,
        isAsdb: true
    },
    saveTOS: {
        mock: "save-terms-of-service.json",
        live: "/accountAggregation/termsOfService/accept",
        url: noop,
        forceMock: false,
        isAsdb: true
    },
    manageLinkedAccounts: {
        mock: "manage-linked-accounts.json",
        live: "/accountAggregation/external/manageLinkedAccounts/investorId/{investorId}",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    // deleteSpouse: {
    //     mock: "delete-spouse.json",
    //     live: "/managedAccountParticipants/aboutMyFamily/{investorId}/familyDetails",
    //     url: noop,
    //     forceMock: false,
    //     isAsdb: true
    // },

    getFundHoldings: {
        mock: "get-fund-holdings.json",
        live: "",
        url: noop,
        forceMock: true,
        isAsdb: true
    },

    getFundNames: {
        mock: "get-fund-names.json",
        live: "/managedAccountParticipants/searchProviderFunds/investorAccountId/{investorAccountId}",
        url: noop,
        forceMock: false,
        isAsdb: true
    },

    getPlanMessageList: {
        mock: "/customizations/Default/planMessaging/plan-messaging.json",
        live: "/participant-web-services/rest/nonauth/bulletins/bulletinsForGrps/function/KP",
        forceMock: false
    },

    getAccountSummary: {
        mock: "get-account-summary.json",
        live: "rest/accounts/summary",
        forceMock: false
    },

    getAccountSummaryRefresh: {
        mock: "get-account-summary.json",
        live: "rest/accounts/summary?refreshAccountsList",
        forceMock: false
    },
    isParticipantEnrollEligible: {
        mock: "is-participant-enrollment-eligible.json",
        live: "rest/nonqual/isParticipantEnrollmentEligible/{groupId}",
        forceMock: false
    },
    saveReminderUpdate: {
        mock: "save-preferences.json",
        live: "rest/overrides/logGoalModalMsgEvents",
        forceMock: false
    },
    getContributionData: {
        mock: "get-contribution-data.json",
        live: "rest/accountDashboard/getContributionData/{individualId}/{groupId}"
    },
    getNavigationMenu: {
        mock: "get-navigation-menu.json",
        live: "rest/menu/getGroupNavigationMenu/groupId/{groupId}",
        forceMock: false
    },
    getQuickViewAccountData: {
        mock: "get-quick-view-account-data.json",
        live: "rest/quickview/getQuickViewAccountsData"
    },
    getLockheedQualifiedPlanNumber: {
        mock: "get-lockheed-qualified-plan-number.json",
        live: "rest/deferrals/isGroupLockheedPlan/{groupId}",
        forceMock: false
    },
    resetPlanToAgeOverride: {
        mock: "reset-plan-to-age-override.json",
        live: "rest/retirementIncomeView/projections/resetPlanToAgeOverride",
        forceMock: false
    },
    saveMigratedPlanToAgeOverride: {
        mock: "save-migrated-plan-to-age-override.json",
        live: "rest/retirementIncomeView/projections/savePlanToAgeOverride",
        forceMock: false
    },
    saveNonMigratedPlanToAgeOverride: {
        mock: "save-non-migrated-plan-to-age-override.json",
        live: "rest/retirementIncomeView/projections/savePlanToAgeOverride",
        forceMock: false
    },
    getGroupTxnAccess: {
        mock: "get-group-txn-access.json",
        live: "rest/groupTxnAccess/{individualId}/{groupId}/txnCodes/{txnCodes}",
        forceMock: false
    },
    hasTxnAccess: {
        mock: "has-txn-access.json",
        live: "rest/hasTxnAccess/{txnCodes}",
        forceMock: false
    },
    getShowLiat: {
        mock: "",
        live: "rest/liat/eligible",
        forceMock: false
    },
    getIntegratedEligibility: {
        mock: "get-integrated-eligibility.json",
        live: "rest/home/eligibility",
        forceMock: false
    },
    postSaml2: {
        mock: "post-pc-api-saml2.json",
        live: "api/empower/sso/saml2",
        forceMock: true
    },

    getTopNavLinks: {
        mock: "get-top-nav-links.json",
        live: "rest/menu/topNavLinks",
        forceMock: false
    },
    saveEDelivery: {
        mock: "save-e-delivery.json",
        live: "rest/partialauth/ecommService/updatePPTEConsent",
        forceMock: false
    },
    getAuthorizedDevices: {
        mock: "get-authorized-devices.json",
        live: "rest/auth/authorizedDevices",
        forceMock: false
    },
    saveAuthorizedDeviceChanges: {
        mock: "save-authorized-devices.json",
        live: "rest/auth/saveAuthorizedDeviceChanges",
        forceMock: false
    },
    getAccountsLite: {
        mock: "get-accounts-lite.json",
        live: "/api/newaccount/getAccountsLite",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    getMyLifeGoals: {
        mock: "get-my-life-goals.json",
        live: "/api/invest/getMyLifeGoals",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    getAccounts2: {
        mock: "get-accounts2.json",
        live: "/api/newaccount/getAccounts2",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    getPersons: {
        mock: "get-persons.json",
        live: "/api/person/getPersons",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    updateMarketingPreferences: {
        mock: "update-marketing-preferences.json",
        live: "rest/partialauth/profile/updateMarketingPreferences",
        url: noop,
        forceMock: false,
        isPcapService: false
    },
    retrieveMarketingPreferences: {
        mock: "retrieve-marketing-preferences.json",
        live: "rest/partialauth/profile/retrieveMarketingPreferences",
        url: noop,
        forceMock: false,
        isPcapService: false
    },
    getUserMessages: {
        mock: "get-user-messages.json",
        live: "/api/message/getUserMessages",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    updateUserMessages: {
        mock: "update-user-messages.json",
        live: "/api/message/updateUserMessages",
        url: noop,
        forceMock: false,
        isPcapService: true
    },
    getPrimaryPlan: {
        mock: "get-primary-plan.json",
        live: "rest/accounts/primaryPlan",
        url: noop,
        forceMock: false,
        isPcapService: false
    },
    updateModalDeminTracking: {
        mock: "save-preferences.json",
        live: "rest/overrides/logMsgEvents",
        url: noop,
        forceMock: false,
        isPcapService: false
    },
    getMADetails: {
        mock: "",
        live: "rest/accounts/account/MAEnrollmentDetails/{indId}/{gaId}",
        url: noop,
        forceMock: false,
        isPcapService: false
    }
};

export default ServiceRegister;
