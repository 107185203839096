import EventBus from "core-ui/client/src/app/core/eventbus/EventBus";
import { ObjectUtil } from "gw-shared-components";

import {
    GET_SCREEN_SIZE,
    SET_SCREEN_SIZE,
    SET_CURRENT_FAULT,
    SHOW_LOADER,
    SHOW_LOADER_MESSAGES,
    ON_INIT_LOAD,
    READY_TO_SHOW_APP,
    SET_TXN_ACCESS,
    SET_LOAD_STATUS,
    SET_SECONDARY_LOADER,
    SET_OPEN_DEMIN_MODAL
} from "./sharedActionTypes";

export const getScreenSize = () => {
    return {
        type: GET_SCREEN_SIZE,
        payload: "@screen-lg"
    };
};

export const setScreenSize = (size) => {
    return {
        type: SET_SCREEN_SIZE,
        payload: size
    };
};

// eventBus previously expected "this" as a primary argument, and "payload" as secondary argument
// instead of passing the application/components state/props to GA, we can pass passload 2x as ...data
export const dispatchEventBus = (type, ...data) => {
    const eventBus = new EventBus(window, document);
    if (ObjectUtil.isUndefinedOrNull(type)) {
        console.warn("please check the event type");
    }

    return () => {
        eventBus.dispatch(type, ...data);
    };
};

export const dispatchAmplitude = (data) => {
    const eventBus = new EventBus(window, document);
    if (ObjectUtil.isUndefinedOrNull(data)) {
        console.warn(
            "You are passing an empty data object to amplitude. Please check the event payload"
        );
    }

    return () => eventBus.dispatchAmplitude(data);
};

export const setCurrentFault = (payload) => {
    return {
        type: SET_CURRENT_FAULT,
        payload
    };
};

export const showLoader = (loading) => {
    return {
        type: SHOW_LOADER,
        payload: loading
    };
};

export const setGetInitDataCalled = () => {
    return { type: ON_INIT_LOAD };
};

export const readyToShowApp = () => {
    return { type: READY_TO_SHOW_APP };
};

export const setShowLoaderMessages = (payload) => {
    return {
        type: SHOW_LOADER_MESSAGES,
        payload
    };
};

export const setTxnAccess = (txnAccess) => {
    return {
        type: SET_TXN_ACCESS,
        payload: txnAccess
    };
};

export const setLoadStatus = (payload) => {
    return {
        type: SET_LOAD_STATUS,
        payload
    };
};

export const setSecondaryLoader = (payload) => {
    return {
        type: SET_SECONDARY_LOADER,
        payload
    };
};

export const setOpenDeminModal = (payload) => {
    return {
        type: SET_OPEN_DEMIN_MODAL,
        payload
    };
};
