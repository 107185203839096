interface IndividualOptions {
    allowAllocationsUpdate: boolean;
    currentAllocationModel: boolean;
    disableEquityMix: boolean;
    doMoneyTypeGroupingsApply: boolean;
    doTradeRestrictionsApply: null;
    hasAllMoneyTypeGroup: boolean;
    isPortfolioManagementAllowed: boolean;
    modelName: null;
}
/**
 * Does the participant have portfolio management
 * @returns {boolean}
 */
const getIsPortfolioManagementAllowed = (individualOptions: IndividualOptions): boolean => {
    return individualOptions.isPortfolioManagementAllowed;
};
export default getIsPortfolioManagementAllowed;
