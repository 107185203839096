/* eslint-disable import/order */
window.liatStartAppTimeInMemory = new Date().getTime();

import * as Sentry from "@sentry/react";
import axios from "axios";
import { getTranslationsService } from "core-ui/client/react/core/services/translationService";
import { getEnvironmentDisplayName } from "core-ui/client/react/core/utils/getEnvironmentDisplayName";
import { setForceSentryDebug } from "core-ui/client/react/core/utils/setSentryDebug";
import { isUndefinedOrNull } from "gw-shared-components";
import { Provider } from "jotai";
import { createRoot } from "react-dom/client";

import ApplicationContainer from "./liat/ApplicationContainer";
import { isLocalhost } from "./liat/utils/urlUtils";

import { sentryLiatAppStartMetrics } from "./utils/sentryMetricsLogging";

if (!isLocalhost) {
    let sampleRate = window?.sentryConfig?.sampleRate;
    let tracesSampleRate = window?.sentryConfig?.tracesSampleRate;
    const sentryEnv = getEnvironmentDisplayName(window.location.host);
    const version = "liat-ui@" + __applicationVersion__;

    if (sentryEnv !== "Production" && window.sentryConfig.lowerEnvironments) {
        sampleRate = window?.sentryConfig?.lowerEnvironments?.sampleRate;
        tracesSampleRate = window?.sentryConfig?.lowerEnvironments?.tracesSampleRate;
    }
    // override sample rate if forceSentryDebug is set to true
    setForceSentryDebug();
    const forceSentryDebug = localStorage.getItem("forceSentryDebug");
    if (forceSentryDebug === "true") {
        sampleRate = 1;
        tracesSampleRate = 1;
    }

    const enableInp = true;

    Sentry.init({
        dsn: "https://62188f0235f74dbb8363bd028c300eff@o249255.ingest.us.sentry.io/4505080905990144",
        release: version,
        environment: sentryEnv,
        // debug: true, Turn on when debugging the sentry events
        integrations: [
            Sentry.browserTracingIntegration({
                enableInp
            }),
            Sentry.metrics.metricsAggregatorIntegration()
        ],
        sampleRate: sampleRate,
        tracesSampleRate: tracesSampleRate, // Performance sample rate
        enableTimeToFullDisplayTracing: true,

        trackComponents: ["Application", "RadialChartShadow"],

        // Callback to modify event before it is sent (i.e. data scrubbing)
        beforeSend(event, hint) {
            console.log("SENTRY Event: ", event, hint);
            return event;
        }
    });
    // set initial hash
    Sentry.setTag("location_hash", window.location.hash);

    window.addEventListener("hashchange", () => {
        Sentry.setTag("location_hash", window.location.hash);
    });
}

import "./utils/setJquery";
import "./utils/setWindowVariables";
import "./utils/setGlobalThisVariables";
import "./utils/setLogLevel";

import { setRedwoodSidebarObserver } from "./utils/setRedwoodSidebarObserver";

import "core-ui/client/src/app/core/util/googleAnalytics/bootstrapGoogleAnalytics";

import "scrollingelement";
import React from "react";

axios.defaults.withCredentials = true;

import { logger } from "@sentry/utils";

import { redwoodStore } from "./liat/store/redwoodStore";

export const pcapBundle = {
    js: "https://qastaging.pcapcloud.com/static/js/empower.bundle.20231201_174930.js",
    css: "https://qastaging.pcapcloud.com/static/css/empower/app/main.20231201_174930.css"
};

const loadReactApp = () => {
    const appContainer = document.getElementById("app-container");
    const root = createRoot(appContainer);
    sentryLiatAppStartMetrics();

    root.render(
        <Provider store={redwoodStore}>
            <ApplicationContainer />
        </Provider>
    );
};

const bootstrapApp = async () => {
    // overrides
    globalThis.redwood = isParamTrue(getUrlParam("useRedwood"));
    globalThis.useRivd = isParamTrue(getUrlParam("useReact"));
    globalThis.forceEmulator = isParamTrue(getUrlParam("forceEmulator"));

    try {
        await getTranslationsService();

        loadReactApp();
    } catch (err) {
        console.error("ERROR - getTranslationsService", err);
        handleError(err);
    }
};

function handleError(error) {
    logger.error("liat-ui bootstrap error - ", { error });
    if (import.meta.env.PROD) {
        window.location.href = "/participant/#/login";
    }
}

const isParamTrue = (val) => {
    if (isUndefinedOrNull(val)) return false;
    return val && (val === "true" || val.includes("true"));
};

/**
 * JavaScript Get URL Parameter
 *
 * @param String prop The specific URL parameter you want to retreive the value for
 * @return String|Object If prop is provided a string value is returned, otherwise an object of all properties is returned
 */
function getUrlParam(prop) {
    let val = null;
    const params = {};
    const search = decodeURIComponent(
        window.location.href.slice(window.location.href.indexOf("?") + 1)
    );
    const definitions = search.split("&");

    definitions.forEach(function (val) {
        const parts = val.split("=", 2);
        params[parts[0]] = parts[1];
    });

    val = prop && prop in params ? params[prop] : null;
    return val;
}

function addEventListeners() {
    if (!isLocalhost) {
        setRedwoodSidebarObserver();
    }
}

addEventListeners();
bootstrapApp();

export default bootstrapApp;
