import {
    ROUTE_MANAGED_ACCOUNTS_INTRO,
    ROUTE_ADVISOR,
    ROUTE_VIEW_MEETING,
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_ADVICE_NEW,
    ROUTE_MANAGED_ACCOUNTS_STARTED_NEW,
    ROUTE_MANAGED_ACCOUNTS_INTRO_NEW,
    ROUTE_MANAGED_ACCOUNTS_HOME_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_ONLINE_ADVICE_GET_STARTED,
    ROUTE_ONLINE_ADVICE_PREVIEW
} from "../../../../routes";

const SUB_PAGES = [ROUTE_MANAGED_ACCOUNTS_INTRO, ROUTE_ADVISOR, ROUTE_VIEW_MEETING];

// use this array to get a container with class = pc-module-container-empty with no css styles. The child element can add the styles to the class if required
const CLASS_WITH_NO_STYLE = [
    ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING,
    ROUTE_MANAGED_ACCOUNTS_ADVICE_NEW,
    ROUTE_MANAGED_ACCOUNTS_STARTED_NEW,
    ROUTE_MANAGED_ACCOUNTS_INTRO_NEW,
    ROUTE_MANAGED_ACCOUNTS_HOME_NEW,
    ROUTE_ONLINE_ADVICE_NEW,
    ROUTE_ONLINE_ADVICE_GET_STARTED,
    ROUTE_ONLINE_ADVICE_PREVIEW
];

const getContainerClass = (pathname) => {
    let className = "";

    if (!SUB_PAGES.some((page) => page.includes(pathname))) {
        className = "pc-module-container";
    }

    if (CLASS_WITH_NO_STYLE.includes(pathname)) {
        className = "pc-module-container-empty";
    }
    return className;
};

export default getContainerClass;
